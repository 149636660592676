import React from 'react'
import { CreateLayoutFields, FormikSetFieldValue } from '../../../../types/types'
import SwitchCheckbox from '../../../../Components/UI/SwitchCheckbox/SwitchCheckbox'

type OrientationFieldProps = {
    value: CreateLayoutFields['adaptation']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const OrientationField: React.FC<OrientationFieldProps> = React.memo(({ value, setFieldValue }) => {

    const handleChange = (value: boolean) => {
        setFieldValue('adaptation', value)
    }

    return (
        <SwitchCheckbox
            text="Необходимость адаптации"
            checked={value}
            classes={['bf-create-adaptive']}
            onChange={e => handleChange(e?.target.checked || false)}
        />
    )
})

export default OrientationField
import React from 'react'
import { JSXChildren } from '../../types/types'

type ChildProps = {
    slot: string
}

type SlotProps = {
    name: string
    children: JSXChildren
}

const Slot: React.FC<SlotProps> = ({name, children}) => {

    const isArray = (test: JSXChildren): test is JSX.Element[] => {
        return Array.isArray(test)
    }

    const isObject = (test: JSXChildren): test is JSX.Element => {
        return typeof test === 'object' && test !== null
    }

    const compareSlotProp = (child: JSX.Element) => {
        const childProps = child?.props as ChildProps
        return childProps.slot === name ? child : null
    }

    if (isArray(children)) {
        return children.find((child: JSX.Element) => compareSlotProp(child) ) || null

    } else if (isObject(children)) {
        return compareSlotProp(children)
    }

    return null
}
export default Slot

import React from 'react'
import './RadioBtn.scss'

type RadioBtnProps = {
    labelText: string
    name: string
    value: string | number
    onChange?: (value: string) => void
    classes?: string[]
    defaultChecked?: boolean
    checked?: boolean
}
const RadioBtn: React.FC<RadioBtnProps> = (props) => {
    const {
        labelText, name, value,
        classes, defaultChecked, checked,
        onChange
    } = props

    let btnClasses = ['btn-radio']

    if (classes && classes.length > 0) {
        btnClasses = btnClasses.concat(classes)
    }

    return (
        <label className={btnClasses.join(' ')}>
            <input
                type="radio"
                name={name}
                id={value + ''}
                value={value}
                defaultChecked={defaultChecked}
                checked={checked}
                onChange={() => onChange ? onChange(value + '') : null}
            />
            <span className="bf-btn bf-btn-primary">{labelText}</span>
        </label>
    )
}

export default RadioBtn
import React from 'react'
import './TextArea.scss'

type TextAreaProps = {
    title?: string
    value?: string | number
    name?: string
    id?: string
    placeholder?: string
    errorMessage?: string
    classes?: string[]
    wrapperClasses?: string[]
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    onInput?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea: React.FC<TextAreaProps> = ({ title, id, name, value, classes, wrapperClasses, placeholder, errorMessage, onChange, onInput }) => {

    let inputClasses = ['bf-input-area']
    let inputWrapperClasses = ['']

    if (classes && classes.length > 0) {
        inputClasses = inputClasses.concat(classes)
    }
    if (wrapperClasses) {
        inputWrapperClasses = inputWrapperClasses.concat(wrapperClasses)
    }
    if (!title) {
        inputClasses.push('fullSize')
    }
    if (errorMessage) {
        inputClasses.push('error')
    }

    return (
        <div className={inputWrapperClasses.join(' ')}>
            <div className={inputClasses.join(' ')}>
                {
                    title
                        ? <p className="bf-input-area__title">{title}</p>
                        : null
                }
                <textarea
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    value={value}
                    className="bf-input-area__area"
                    onChange={onChange}
                    onInput={onInput}
                />
            </div>
            {
                errorMessage
                    ? <p className="bf-input-error-message">{ errorMessage }</p>
                    : null
            }
        </div>
    )
}

export default TextArea
import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import { Select } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import { updateUrlQueries } from '../../../../utils/other'
const { Option } = Select

interface SourceFieldProps extends RouteComponentProps {
    catalogData: CatalogFieldsData
    values: CatalogFilterFields
    isLoadingSources: boolean
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
    setCatalogData: React.Dispatch<React.SetStateAction<CatalogFieldsData>>
}

const SourceField: React.FC<SourceFieldProps> = React.memo((props) => {

    const {
        catalogData, values,
        location, history,
        isLoadingSources,
        setFieldValue, setCatalogData,
    } = props

    const handleChange = (sourceId: string | number | undefined) => {
        if (sourceId) {
            const source = catalogData.sources.find(s => s.id + '' === sourceId + '')
            const isClear = +sourceId === -1
            if (isClear || source) {
                const newCatalogData = { ...catalogData }
                if (isClear) {
                    updateUrlQueries(location, history, {
                        'zone_id': null,
                        'source_id': null
                    })
                    setFieldValue('source', null)
                    setFieldValue('zones', [])
                } else if (source) {
                    updateUrlQueries(location, history, {
                        'source_id': sourceId + '',
                        'zone_id': null
                    })
                    setFieldValue('source', source)
                    setFieldValue('zones', [])
                }
                newCatalogData.zones = []
                setCatalogData(newCatalogData)
            }
        }
    }

    return (
        <InputSelect
            title="Носитель"
            placeholder="Выберите носитель"
            value={values.source?.id || null}
            disabled={!values.placement}
            loading={isLoadingSources}
            wrapClasses={['catalog-location-select']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.sources?.map(s => {
                        return (
                            <Option value={s.id + ''} key={s.id}>{s.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default withRouter(SourceField)
import React from 'react'
import { CreateLayoutFields, FormikSetFieldValue } from '../../../../types/types'
import SwitchCheckbox from '../../../../Components/UI/SwitchCheckbox/SwitchCheckbox'

type RequiredSwitchProps = {
    value: CreateLayoutFields['isRequired']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const RequiredSwitch: React.FC<RequiredSwitchProps> = React.memo(({ value, setFieldValue }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        const target = e?.target
        if (target) {
            setFieldValue('isRequired', target.checked)
            if (!target.checked) {
                setFieldValue('requiredFrom', '')
                setFieldValue('requiredTo', '')
            }
        }
    }

    return (
        <SwitchCheckbox
            text='Обязательный макет'
            checked={value}
            classes={['required-switch-create']}
            onChange={handleChange}
        />
    )
})

export default RequiredSwitch
import React from 'react'
import { customSizesCreate } from '../../../Data/createData'
import { FormikSetFieldValue, SizeInputData } from '../../../types/types'
import { customSizesByPlacementId } from '../../../utils/formUtils'
import SizesRadios from '../../UI/SizesRadios/SizesRadios'

const customSizes = customSizesCreate()

type SizePresetsFormikProps = {
    valueWidth: string
    valueHeight: string
    valueUnit: string
    placementId: number
    setFieldValue: FormikSetFieldValue<{ width: string, height: string, unit: string}>
    classes?: string[]
}

const SizePresetsFormik: React.FC<SizePresetsFormikProps> = React.memo((props) => {

    const {
        valueWidth, valueHeight,
        valueUnit, placementId,
        classes,
        setFieldValue,
    } = props

    const handleChange = (data: SizeInputData) => {
        setFieldValue('width', data.width)
        setFieldValue('height', data.height)
        setFieldValue('unit', data.unit)
    }

    return (
        <SizesRadios
            width={valueWidth}
            height={valueHeight}
            unit={valueUnit}
            classes={classes}
            sizes={customSizesByPlacementId(placementId, customSizes)}
            onChange={handleChange}
        />
    )
})

export default SizePresetsFormik
import React from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './FilesModal.scss'

type FileConfig = {
    file_link: string
    title: string
}

type FilesModalProps = {
    title: string
    isShow: boolean
    files: FileConfig[]
    description?: string
    unmountAfterClose?: boolean
    closeHandler: () => void
}

const FilesModal: React.FC<FilesModalProps> = (props) => {
    const {
        isShow, unmountAfterClose, title,
        files, description,
        closeHandler,
    } = props

    return (
        <FadeModal
            isShow={isShow}
            classes={['files-modal']}
            unmountAfterClose={unmountAfterClose}
            closeHandler={closeHandler}
        >
            <div className="files-modal__body">
                <p className="files-modal__title">{title}</p>
                {
                    description &&
                    <p className="files-modal__description">{description}</p>
                }
                {
                    files.length
                        ? <ul className="files-modal__items">
                            {
                                files.map(f => (
                                    <li key={f.file_link} className="files-modal__item">
                                        <p className="files-modal__item-name">{f.title}</p>
                                        <a
                                            href={f.file_link}
                                            className="bf-btn bf-btn-primary files-modal__item-download"
                                            download={f.title}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Скачать
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                        : <p className="files-modal__empty-text">Нет файлов</p>
                }
            </div>
        </FadeModal>
    )
}

export default FilesModal
import React from 'react'
import { CreateLayoutFields, FormikSetFieldValue } from '../../../../types/types'
import SwitchCheckbox from '../../../../Components/UI/SwitchCheckbox/SwitchCheckbox'

type DecorationSwitchProps = {
    value: CreateLayoutFields['_isDecoration']
    valuePlacement: CreateLayoutFields['placement']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const DecorationSwitch: React.FC<DecorationSwitchProps> = React.memo(({ value, valuePlacement, setFieldValue }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        const target = e?.target
        if (target) {
            setFieldValue('_isDecoration', target.checked)
            setFieldValue('layoutType', null)
        }
    }

    if (!!valuePlacement?.layout_temp) {
        return (
            <SwitchCheckbox
                text='Оформление клуба'
                checked={value}
                classes={['decoration-switch']}
                onChange={handleChange}
            />
        )
    }
    return null
})

export default DecorationSwitch
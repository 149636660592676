import React from 'react'
import NavBar from '../../NavBar/NavBar'
import './NavbarLayout.scss'

const NavbarLayout: React.FC<{}> = ({ children }) => {

    return (
        <>
            <NavBar />
            <div className="bf-navbar-top-offest" key="1"></div>
            { children}
        </>
    )
}

export default NavbarLayout
import React from 'react'
import { SpecData } from '../../types/responseTypes'
import './Specifications.scss'


type SpecificationsProps = {
    data: SpecData[]
    isShow?: boolean
    showTitle?: boolean
    classes?: string[]
}
const Specifications: React.FC<SpecificationsProps> = ({data, classes, isShow, showTitle = true}) => {

    if (!isShow) {
        return null
    }

    let specClasses = ['bf-spec']
    if (classes && classes.length > 0) {
        specClasses = specClasses.concat(classes)
    }

    return (
        <div className={specClasses.join(' ')}>
            {
                showTitle
                    ? <p className="bf-spec__title">Характеристики</p>
                    : null
            }
            <table>
                <tbody>
                    {
                        data.map((spec, i) => {
                            return (
                                <tr key={spec.value + i}>
                                    <td className="bf-spec__table-spec-title">{spec.title}</td>
                                    <td className="bf-spec__table-spec-description">{spec.value}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Specifications
import { IAuthState } from '../../types/types'
import { AUTH_SET_ERROR_EMAIL_MESSAGE, AUTH_SET_ERROR_FORM_MESSAGE, AUTH_SET_ERROR_PASSWORD_MESSAGE, AUTH_SET_LOADING_STATUS, AUTH_SET_VALID_STATUS } from '../actions/actionTypes'
import { AllAuthActions } from '../actions/AuthActions'


const authState: IAuthState = {
    isValid: true,
    isLoading: false,
    errorFormMessage: '',
    errorEmailMessage: '',
    errorPasswordMessage: ''
}

export default function authReducer(state = authState, action: AllAuthActions) {
    switch(action.type) {
        case (AUTH_SET_ERROR_EMAIL_MESSAGE): {
            return {
                ...state,
                errorEmailMessage: action.payload
            }
        }
        case (AUTH_SET_ERROR_PASSWORD_MESSAGE): {
            return {
                ...state,
                errorPasswordMessage: action.payload
            }
        }
        case (AUTH_SET_ERROR_FORM_MESSAGE): {
            return {
                ...state,
                errorFormMessage: action.payload
            }
        }
        case (AUTH_SET_VALID_STATUS): {
            return {
                ...state,
                isValid: action.payload
            }
        }
        case (AUTH_SET_LOADING_STATUS): {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        default: {
            return state
        }
    }
}
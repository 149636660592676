import React from 'react'
import { FormikSetFieldValue, SizeInputData } from '../../../types/types'
import Sizes from '../../UI/Sizes/Sizes'


type SizesFormikProps = {
    valueWidth: string
    valueHeight: string
    valueUnit: string
    setFieldValue: FormikSetFieldValue<{ width: string, height: string, unit: string }>
    classes?: string[]
}

const SizesFormik: React.FC<SizesFormikProps> = React.memo((props) => {
    const {
        valueWidth, valueHeight, valueUnit,
        classes,
        setFieldValue,
    } = props

    const handleChange = (data: SizeInputData) => {
        setFieldValue('width', data.width)
        setFieldValue('height', data.height)
        setFieldValue('unit', data.unit)
    }

    return (
        <Sizes
            width={valueWidth}
            height={valueHeight}
            unit={valueUnit}
            classes={classes}
            onChange={handleChange}
        />
    )
})

export default SizesFormik
import React from 'react'

type SubmitButtonProps = {
    disabled: boolean
    submitForm: () => Promise<any>
}

const SubmitButton: React.FC<SubmitButtonProps> = React.memo(({ disabled, submitForm }) => {

    return (
        <button
            type="submit"
            className="bf-edit-adaptive__send bf-btn bf-btn-primary"
            disabled={disabled}
            onClick={submitForm}
        >
            Редактировать
        </button>
    )
})

export default SubmitButton
import React from 'react'
import './UploadStatus.scss'
import { Progress } from 'antd'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import useUploadModal from '../../utils/hooks/useUploadModal'


type UploadStatusProps = {
    upload: ReturnType<typeof useUploadModal>
}

const spin = <LoadingOutlined className="bf-upload-modal__spin" spin />

const UploadStatus: React.FC<UploadStatusProps> = (props) => {
    const {
        status,
        title,
        description,
        totalSize,
        uploadedSize
    } = props.upload

    const totalMB = totalSize / 1048576

    const statusLoader = () => {
        if (status === 'success') return 'success'
        if (status === 'error') return 'exception'
        return 'normal'
    }
    const loadedPercent = () => Math.ceil((uploadedSize / totalSize) * 100)


    if (status === null) return <div className="bf-upload-modal"></div>
    return (
        <div className="bf-upload-modal">
            <div className="bf-upload-modal__body">
                <div className="bf-upload-modal__icon">
                    {
                        loadedPercent() === 100 && status === 'loading'
                            ? <Spin indicator={spin} />
                            : <Progress
                                type="circle"
                                percent={loadedPercent()}
                                status={statusLoader()}
                            />
                    }
                </div>

                {
                    status === 'loading'
                        ? <p className="bf-upload-modal__loading-text">Общий размер: {totalMB.toFixed(1)} Mb</p>
                        : null
                }

                <p className="bf-upload-modal__title">{title}</p>
                {
                    description
                        ? loadedPercent() === 100 && status === 'loading'
                            ? <p className="bf-upload-modal__description">Файлы отправлены. Обработка данных сервером...</p>
                            : <p className="bf-upload-modal__description">{description}</p>
                        : null
                }
            </div>
        </div>
    )
}

export default UploadStatus
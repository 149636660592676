import ContentTags from "./components/ContentTags/ContentTags";
import ContentRegulation from "./components/ContentRegulation/ContentRegulation";

interface ContentManageType {
    [key: string]: () => JSX.Element
}

export const contentManage = {
    '1': ContentTags,
    '2': ContentRegulation
} as ContentManageType
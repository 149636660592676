import React from 'react'
import LoadFileField from '../../LoadFileField/LoadFileField'

type PreviewInputFormikProps = {
    value: File | null
    onChangeValue: (file: File | null) => void
    onChangeError?: (newError: string) => void
    error?: string
    dragRect?: React.RefObject<HTMLElement>
    previewSrc?: string
}

const PreviewInputFormik: React.FC<PreviewInputFormikProps> = React.memo((props) => {
    const {
        dragRect, value, error,
        previewSrc,
        onChangeValue, onChangeError,
    } = props

    const handleChange = (file: File | null) => {
        onChangeValue(file)
        if (onChangeError) onChangeError('')
    }

    return (
        <LoadFileField
            fileContainer={value}
            validExtensions={['png', 'jpg']}
            errorMessage={error}
            dragRect={dragRect}
            previewFile={value}
            previewSrc={previewSrc}
            onChangeFile={handleChange}
        />
    )
})

export default PreviewInputFormik
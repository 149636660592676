import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import DesignCard from '../../../Components/DesignCard/DesignCard'
import { LayoutPreview } from '../../../types/responseTypes'


type DesignsFieldProps = {
    layouts: LayoutPreview[]
    isLoading: boolean
    onZoomImg?: (imgSrc: string) => void
}

const loader = <LoadingOutlined style={{ fontSize: '58px', color: ' #00C0D1' }} spin />

const DesignsField: React.FC<DesignsFieldProps> = ({ layouts, isLoading, onZoomImg }) => {

    const handleZoom = (src: string) => {
        if (onZoomImg) onZoomImg(src)
    }

    return (
        <div className="bf-designs__cards-container">
            {
                layouts.map(l => (
                    <DesignCard
                        title={l.name}
                        description={l.desc_to_preview}
                        href={`/prototype/catalog/${l.id}`}
                        imgSrc={l.preview}
                        alt={`${l.name} img`}
                        classModificator="category"
                        key={l.id}
                        onZoom={handleZoom}
                    />
                ))
            }
            {
                layouts.length === 0 && !isLoading
                    ? <div className="bf-designs__cards-empty">
                        <p>Макеты по выбранным критериям отсутствуют</p>
                    </div>
                    : null
            }
            {
                isLoading
                    ? <div className="bf-designs__cards-loader"><Spin indicator={loader} /></div>
                    : null
            }
        </div>
    )
}

export default DesignsField

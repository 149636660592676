import { RouteComponentProps } from 'react-router-dom'
import { SetValuesFormik } from '../../../types/formTypes'
import { CatalogFilterFields, CatalogFilterFieldsOpt } from '../../../types/types'
import { updateUrlQueries } from '../../../utils/other'


type ResetOptions = {
    setValues: SetValuesFormik<CatalogFilterFields>
    values: CatalogFilterFields
    newValues: CatalogFilterFieldsOpt
    location: RouteComponentProps['location'],
    history: RouteComponentProps['history'],
}

export const resetFieldAfterRequired = ({ setValues, values, newValues, location, history }: ResetOptions) => {
    const newQueryParams = {} as { [key: string]: string | null }

    setValues({
        ...values,
        ...newValues,
        club: null,
        placement: null,
        source: null,
        zones: [],
        isAdaptation: false,
        orientation: null,
    })

    if ('isRequiredFuture' in newValues) {
        newQueryParams.required_future = newValues.isRequiredFuture ? newValues.isRequiredFuture + '' : null
    } else if ('isRequiredNow' in newValues) {
        newQueryParams.required_now = newValues.isRequiredNow ? newValues.isRequiredNow + '' : null
    }

    updateUrlQueries(location, history, {
        ...newQueryParams,
        'club_id': null,
        'placement_id': null,
        'zone_id': null,
        'source_id': null,
        'is_adaptive': null,
    })
}
import React from 'react'
import './SwitchCheckbox.scss'

type SwitchCheckboxProps = {
    text: string
    name?: string
    id?: string
    checked?: boolean
    defaultChecked?: boolean
    classes?: string[]
    disabled?: boolean
    onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitchCheckbox: React.FC<SwitchCheckboxProps> = ({ text, name, id, defaultChecked, checked, classes, disabled, onChange }) => {

    let checkboxClasses = ['switch-checkbox']

    if (classes) checkboxClasses = checkboxClasses.concat(classes)

    if (disabled) checkboxClasses.push('disabled')

    return (
        <label className={checkboxClasses.join(' ')}>
            <input
                type="checkbox"
                name={name}
                id={id}
                checked={checked}
                defaultChecked={defaultChecked}
                disabled={disabled}
                onChange={onChange}
            />
            <span className="switch-checkbox__icon">{ text }</span>
        </label>
    )
}

export default SwitchCheckbox
export const tokenCookieName = 'bf-user-token-access'
export const refreshTokenCookieName = 'bf-user-token-refresh'
export const tokenType = 'Bearer'
export const cityCookieName = 'bf-city-id'
export const clubCookieName = 'bf-club-id'

export const baseUrl = 'https://prototype.bfnotion.ru/api/v1'
export const fileBaseUrl = 'https://prototype.bfnotion.ru/api/v1/get/file'

export const loginUrl = `${baseUrl}/auth/login`
export const refreshUrl = `${baseUrl}/auth/refresh`
export const logoutUrl = `${baseUrl}/auth/logout`
export const userInfoUrl = `${baseUrl}/auth/user-profile`
export const catalogsUrl = `${baseUrl}/get/catalogs`
export const getContentUrl = `${baseUrl}/get/content`
export const getTemplateUrl = `${baseUrl}/get/template`
export const getLayoutByIdUrl = `${baseUrl}/get/layout`
export const getVersionByIdUrl = `${baseUrl}/get/version`
export const getAllTags = `${baseUrl}/get/tags`
export const getAllRegulations = `${baseUrl}/get/regulations`
export const getRegulations = `${baseUrl}/get/regs`
export const getEditLayoutDataUrl = `${baseUrl}/update/layout`
export const getEditAdaptiveDataUrl = `${baseUrl}/update/adaptive`

export const deleteAdaptiveUrl = `${baseUrl}/delete/adaptive`
export const deleteLayoutUrl = `${baseUrl}/delete/layout`
export const deleteTagUrl = `${baseUrl}/delete/tag`
export const deleteRegUrl = `${baseUrl}/delete/regulation`

export const insertLayoutUrl = `${baseUrl}/insert/layout`
export const insertVersionUrl = `${baseUrl}/insert/version`
export const insertAdaptiveUrl = `${baseUrl}/insert/adaptive`
export const insertTagUrl = `${baseUrl}/insert/tag`
export const insertRegUrl = `${baseUrl}/insert/regulation`

export const yandexCheckUrl = `${baseUrl}/yandex/check`
export const yandexCodeUrl = `${baseUrl}/yandex/code`
export const yandexRefreshUrl = `${baseUrl}/yandex/refresh`

export const updateTagUrl = `${baseUrl}/update/tag`
export const updateRegUrl = `${baseUrl}/update/regulation`
import React from 'react'
import { sizeUnitsCreate } from '../../../Data/createData'
import { SizeInputData } from '../../../types/types'
import InputNumber from '../InputNumber/InputNumber'
import RadioBtn from '../RadioBtn/RadioBtn'
import './Sizes.scss'

type SizesProps = {
    width: string
    height: string
    unit: string
    errorMessage?: string
    onChange: (data: SizeInputData) => void
    title?: string
    classes?: string[]
}

const Sizes: React.FC<SizesProps> = (props) => {
    const {
        width, height, unit,
        errorMessage, title,
        classes,
        onChange,
    } = props

    const changeWidthHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const error = checkValidate('width', e.target.value)
        onChange({
            width: e.target.value,
            height,
            unit,
            errorMessage: error,
        })
    }

    const changeHeightHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const error = checkValidate('height', e.target.value)
        onChange({
            width,
            height: e.target.value,
            unit,
            errorMessage: error,
        })
    }

    const changeUnitHandler = (value: string) => {
        const error = checkValidate('unit', value)
        onChange({
            width,
            height,
            unit: value,
            errorMessage: error,
        })
    }

    const clearHandler = () => {
        onChange({
            width: '',
            height: '',
            unit: '',
            errorMessage: '',
        })
    }

    const checkValidate = (sizeName: string, val: string): string => {
        const sizes = {
            width,
            height,
            unit,
            [sizeName]: val
        }
        const isAllFilded = Object.values(sizes).every(v => v !== '')
        return isAllFilded ? '' : 'Укажите размер'
    }

    let sizesClasses = ['bf-sizes']

    if (classes) sizesClasses = sizesClasses.concat(classes)
    if (errorMessage) sizesClasses.push('error')

    return (
        <div className={sizesClasses.join(' ')}>
            { title && <p className="bf-sizes-title">{title}</p>}
            <div className="bf-sizes-inputs">
                <InputNumber
                    title="Ширина"
                    placeholder="..."
                    value={width}
                    classes={['bf-sizes-input-number']}
                    onChange={changeWidthHandler}
                />
                <InputNumber
                    title="Высота"
                    placeholder="..."
                    value={height}
                    classes={['bf-sizes-input-number']}
                    onChange={changeHeightHandler}
                />

                {
                    sizeUnitsCreate().map(s => (
                        <RadioBtn
                            labelText={s.label}
                            value={s.value}
                            checked={s.value === unit}
                            name='size-input'
                            classes={['bf-sizes-unit']}
                            key={s.value}
                            onChange={changeUnitHandler}
                        />
                    ))
                }
            </div>
            <button className="bf-btn bf-btn-primary bf-sizes-clear" onClick={clearHandler}>
                Очистить размер
            </button>
            {
                errorMessage && <p className="bf-sizes-error">{errorMessage}</p>
            }
        </div>
    )
}

export default Sizes
import React from 'react';
import NavbarLayout from "../../Components/Layout/NavbarLayout/NavbarLayout";
import {Button, Empty} from "antd";

const InvisiblePage = () => {

    return(<NavbarLayout>
        <Empty
        description={<span>Макет был выключен</span>}>
        <Button type="primary" href="/prototype/main">На главную</Button>
        </Empty>
    </NavbarLayout>)
}

export default InvisiblePage;
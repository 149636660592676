import React from 'react'
import { CatalogFilterFields } from '../../../../types/types'
import SwitchCheckbox from '../../../../Components/UI/SwitchCheckbox/SwitchCheckbox'
import { RouteComponentProps, withRouter } from 'react-router'
import { resetFieldAfterRequired } from '../../utils/utils'
import { SetValuesFormik } from '../../../../types/formTypes'

interface RequiredFutureFieldProps extends RouteComponentProps {
    values: CatalogFilterFields
    setValues: SetValuesFormik<CatalogFilterFields>
}

const RequiredFutureField: React.FC<RequiredFutureFieldProps> = React.memo((props) => {

    const {
        values, location, history,
        setValues,
    } = props

    const handleChange = (value: boolean) => {
        resetFieldAfterRequired({
            setValues,
            values,
            newValues: {
                isRequiredFuture: value,
            },
            history,
            location,
        })
    }

    return (
        <SwitchCheckbox
            text="Обязателен в будущем"
            checked={values.isRequiredFuture}
            // classes={['bf-create-adaptive']}
            classes={['bf-create-requred-future']}
            onChange={e => handleChange(e?.target.checked || false)}
        />
    )
})

export default withRouter(RequiredFutureField)
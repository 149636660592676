import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {CatalogFilterFields, FormikSetFieldValue} from "../../../../types/types";
import Input from "../../../../Components/UI/Input/Input";
import {updateUrlQueries} from "../../../../utils/other";

interface SearchInputProps extends RouteComponentProps{
    value: string,
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const SearchInput: React.FC<SearchInputProps> = React.memo((props) => {

    const {value, location, history, setFieldValue} = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if(!val){
            updateUrlQueries(location, history, {
                'search': null
            })
        }else {
            updateUrlQueries(location, history, {
                'search': val
            })
        }
        setFieldValue('search', val)
    }

    return(<div style={{marginBottom: 8}}><Input
        title="Поиск"
        placeholder="По названию или описанию макета"
        value={value}
        onChange={handleChange}
    /></div>)
});

export default withRouter(SearchInput);
import { DropboxOutlined } from '@ant-design/icons'
import React, { useRef } from 'react'
import { EditFileItem } from '../../types/responseTypes'
import { sortFilesByName } from '../../utils/files'
import { useDnd } from '../../utils/hooks/useDnd'
import { openNotification } from '../../utils/other'
import InputFile from '../UI/InputFile/InputFile'
import './MultiplyAreaFiles.scss'

type SetFileCallback = ((data: File[]) => void) | React.Dispatch<React.SetStateAction<File[]>>
type SetDefaultFileCallback = ((files: EditFileItem[]) => void) | (React.Dispatch<React.SetStateAction<EditFileItem[]>>)

type MultiplyAreaFilesProps = {
    title: string
    dragRect?: React.RefObject<HTMLElement>
    readonly fileContainer: File[]
    readonly defaultFileContainer?: EditFileItem[]
    errorMessage?: string
    validExtensions?: string[]
    setFileContainer: SetFileCallback
    setDefaultFileContainer?: SetDefaultFileCallback
}

const MultiplyAreaFiles: React.FC<MultiplyAreaFilesProps> = (props) => {

    const {
        title, fileContainer, errorMessage,
        defaultFileContainer, dragRect,
        validExtensions = [],
        setFileContainer, setDefaultFileContainer,
    } = props

    const dragArea = useRef<HTMLDivElement>(null)

    const originalChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length > 0) addFiles(files)
    }

    const addFiles = (files: FileList) => {
        const arrayFiles = Object.values(files)
        let newOriginalFiles = [...fileContainer]
        newOriginalFiles = newOriginalFiles.concat(arrayFiles)
        setFileContainer(sortFilesByName(newOriginalFiles))
    }

    useDnd<HTMLElement, HTMLDivElement>({
        dragArea,
        dragRect,
        validFormats: validExtensions,
        onChangeFiles: (files) => addFiles(files),
        onErrorValidate: (err) => {
            openNotification(err, 'error');
        }
    }, [addFiles])

    const deleteFile = (deleteFile: File) => {
        let newOriginalFiles = [...fileContainer]
        newOriginalFiles = newOriginalFiles.filter(file => file !== deleteFile)
        setFileContainer(sortFilesByName(newOriginalFiles))
    }

    const deleteDefaultFile = (file: EditFileItem) => {
        if (defaultFileContainer && setDefaultFileContainer) {
            const newFiles = defaultFileContainer?.filter(f => f.id !== file.id)
            setDefaultFileContainer(newFiles)
        }
    }

    const hasFiles = fileContainer.length || defaultFileContainer?.length

    return (
        <div className="bf-multi-wrapper">
            <div className="bf-multi" ref={dragArea}>
                <p className={errorMessage ? "bf-multi__title error" : "bf-multi__title"}>{title}</p>
                <ul className={hasFiles ? "bf-multi__file-list" : "bf-multi__file-list hidden"}>
                    {
                        defaultFileContainer?.map(f => (
                            <li key={f.id} className="bf-multi__file-item">
                                {`Исходный файл №${f.id}`}
                                <a href={f.file_path} className="bf-multi__default-show-btn" target="_blank" rel="noreferrer">
                                    Открыть
                                </a>
                                <button
                                    className="bf-multi__delete-btn bf-btn"
                                    onClick={() => deleteDefaultFile(f)}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2.92969" y="15.6572" width="18" height="2" transform="rotate(-45 2.92969 15.6572)" />
                                        <rect x="4.34375" y="2.92871" width="18" height="2" transform="rotate(45 4.34375 2.92871)" />
                                    </svg>
                                </button>
                            </li>
                        ))
                    }
                    {
                        fileContainer.map((file, i) => (
                            <li key={file.name + i} className="bf-multi__file-item">
                                {file.name}
                                <button
                                    className="bf-multi__delete-btn bf-btn"
                                    onClick={() => deleteFile(file)}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2.92969" y="15.6572" width="18" height="2" transform="rotate(-45 2.92969 15.6572)" />
                                        <rect x="4.34375" y="2.92871" width="18" height="2" transform="rotate(45 4.34375 2.92871)" />
                                    </svg>
                                </button>
                            </li>
                        ))
                    }
                    <li className="bf-multi__area-bg">
                        <DropboxOutlined className="bf-multi__area-bg-icon rotate-animate" />
                        <p className="bf-multi__area-bg-text">Добавить файл</p>
                    </li>
                </ul>
                {
                    errorMessage
                        ? <p className="bf-input-error-message">{errorMessage}</p>
                        : null
                }
                <div className="bf-multi__file-buttons">
                    <InputFile
                        textBtn="Загрузить"
                        classes={['bf-btn', 'bf-btn-primary', 'files-add-btn']}
                        multiple={true}
                        onChange={originalChangeHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default MultiplyAreaFiles

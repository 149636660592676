import { freshAccessToken } from './../../utils/cookie/cookie';
import { refreshUrl } from './../../config';
import { InferValueTypes } from './../../types/types';
import { Dispatch } from "react"
import { IAuthBody } from "../../types/types"
import { IRootState } from "../reducers/rootReducer"
import { AUTH_SET_ERROR_EMAIL_MESSAGE, AUTH_SET_ERROR_FORM_MESSAGE, AUTH_SET_ERROR_PASSWORD_MESSAGE, AUTH_SET_LOADING_STATUS, AUTH_SET_VALID_STATUS } from "./actionTypes"
import { appActions } from "./AppActions"
import { AllAppActions } from './AppActions'
import { deleteCookie, getCookie, writeUserToCookie } from '../../utils/cookie/cookie';
import axios from 'axios';
import { AuthResponse, UserInfo } from '../../types/responseTypes';
import { loginUrl, logoutUrl, refreshTokenCookieName, tokenCookieName, tokenType, userInfoUrl } from '../../config';


export const sendFormData = (email: string, password: string) => {
    return async(dispatch: Dispatch<AllAppActions | AllAuthActions>, getState: () => IRootState): Promise<void> => {
        try {
            dispatch(authActions.setAuthLoading(true))
            const body: IAuthBody = { email, password }

            const res = await axios.post(loginUrl, body)
            const userTokenData: AuthResponse = res.data
            console.log('RESPONSE auth', res)

            if (userTokenData.access_token) {
                /// проверить роль пользователя и записать ее
                writeUserToCookie(userTokenData)
                const userRes = await axios.get(userInfoUrl, {
                    headers: { 'Authorization': `${tokenType} ${userTokenData.access_token}` }
                })
                const userData = userRes.data as UserInfo
                dispatch(authActions.setAuthLoading(false))
                console.log('USER INFO RESPONSE', userRes)
                if (userRes.status === 200) {
                    dispatch(appActions.setUserInfo(userData))
                } else {
                    console.log(`Error user role, status`, userRes.status)
                    dispatch(authActions.setAuthValidStatus(false))
                    dispatch(authActions.setErrorFormMessage('Ошибка инициализации роли'))
                }
                return
            }
            dispatch(authActions.setAuthLoading(false))
            dispatch(authActions.setErrorFormMessage('Ошибка входа'))
        } catch(e) {
            console.warn(e.message);
            dispatch(authActions.setAuthLoading(false))
            dispatch(authActions.setAuthValidStatus(false))
            dispatch(authActions.setErrorFormMessage('Неверный логин или пароль'))
        }
    }
}

export const checkIsAuthUser = () => {
    return async (dispatch: Dispatch<AllAppActions | AllAuthActions>, getState: () => IRootState) => {
        try {
            const refreshToken = getCookie(refreshTokenCookieName)

            if (!refreshToken) {
                // Если нет токена в куках отправляем на авторизацию
                deleteCookie(tokenCookieName)
                deleteCookie(refreshTokenCookieName)
                dispatch(appActions.logoutUser())
                console.log('refresh logout')
                return
            }

            const res = await axios.post(refreshUrl, { refresh_token: refreshToken })
            const userTokenData = res.data as AuthResponse
            console.log('refresh response', res)

            if (userTokenData.access_token) {
                /// проверить роль пользователя и записать ее
                writeUserToCookie(userTokenData)
                const userRes = await axios.get(userInfoUrl, {
                    headers: { 'Authorization': `${tokenType} ${userTokenData.access_token}` }
                })
                const userData = userRes.data as UserInfo

                if (userRes.status === 200) {
                    dispatch(appActions.setUserInfo(userData))
                } else {
                    console.log(`Error user role, status`, userRes.status)
                    dispatch(appActions.logoutUser())
                }
                return
            }
            console.log('Refresh before delete')

            deleteCookie(tokenCookieName)
            deleteCookie(refreshTokenCookieName)
            dispatch(appActions.logoutUser())
        } catch(e) {
            console.warn('Ошибка при авторизации из cookie: ', e.message)
            deleteCookie(tokenCookieName)
            deleteCookie(refreshTokenCookieName)
            dispatch(appActions.logoutUser())
        }
    }
}

export const logoutUser = () => {
    return async (dispatch: Dispatch<AllAppActions | AllAuthActions>) => {
        try {
            const accessToken = await freshAccessToken()
            const refreshToken = getCookie(refreshTokenCookieName)
            const body = { refresh_token: refreshToken }
            const options = {
                headers: { 'Authorization': `${tokenType} ${accessToken}` }
            }

            const resp = await axios.post(logoutUrl, body , options)

            console.log('LOGOUT RESPONSE ', resp)

            deleteCookie(tokenCookieName)
            deleteCookie(refreshTokenCookieName)
            dispatch(appActions.logoutUser())
        } catch(e) {
            console.error(e.message)
        }
    }
}

export const authActions = {
    setErrorEmailMessage(payload: string) {
        return {
            type: AUTH_SET_ERROR_EMAIL_MESSAGE,
            payload
        } as const
    },
    setErrorPasswordMessage(payload: string) {
        return {
            type: AUTH_SET_ERROR_PASSWORD_MESSAGE,
            payload
        } as const
    },
    setErrorFormMessage(payload: string) {
        return {
            type: AUTH_SET_ERROR_FORM_MESSAGE,
            payload
        } as const
    },
    setAuthValidStatus(payload: boolean) {
        return {
            type: AUTH_SET_VALID_STATUS,
            payload
        } as const
    },
    setAuthLoading(payload: boolean) {
        return {
            type: AUTH_SET_LOADING_STATUS,
            payload
        } as const
    },
}

export type AllAuthActions = ReturnType<InferValueTypes<typeof authActions>>
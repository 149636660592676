import React from 'react'

type ResetButtonProps = {
    undoChanges: () => void
}

const ResetButton: React.FC<ResetButtonProps> = React.memo(({ undoChanges }) => {

    return (
        <button
            type="button"
            className="bf-edit-adaptive__clear bf-btn bf-btn-gray"
            onClick={() => undoChanges()}
        >
            Отменить изменения
        </button>
    )
})

export default ResetButton
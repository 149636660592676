import React from 'react'
import { notification } from 'antd'
import { CheckCircleTwoTone, ExclamationCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { RouteComponentProps } from 'react-router-dom'
import {ChipLayout, xxCatalog} from '../types/responseTypes'

type FieldsFormData = { [key: string]: number | number[] | string | File | File[] | null | undefined | boolean}

export const mathRandomId = (cycles: number = 5) => {
    let id = ''
    for (let i = 0; i <= cycles; i++) {
        id += Math.ceil(Math.random() * 10000000)
    }
    return id
}

export function createFormData(fields: FieldsFormData) {
    const dataForm = new FormData()
    Object.entries(fields).forEach(([name, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val: number | File) => {
                if (val instanceof File) {
                    // if field is "File[]" type
                    dataForm.append(`${name}[]`, val)
                }
                if (typeof val === 'number') {
                    // if field is "number[]" type
                    dataForm.append(`${name}[]`, val + '')
                }
            })
        } else if (value instanceof File) {
            dataForm.append(name, value)
        } else if (typeof value === 'number') {
            dataForm.append(name, JSON.stringify(value))
        } else if (typeof value === 'string') {
            dataForm.append(name, value)
        } else if (typeof value === undefined) {
            dataForm.append(name, 'undefined')
        } else if (typeof value === 'boolean'){
            dataForm.append(name, +value + '')
        }
    })
    return dataForm
}

export function throttle<T>(callback: (...args: T[]) => void, ms: number) {
    let coolDown = false
    return function wrapper(...args: T[]) {
        if (!coolDown) {
            coolDown = true
            callback(...args)
            setTimeout(() => {
                coolDown = false
            }, ms)
        }
    }
}

export const openNotification = (message: string, type: 'success' | 'error' | 'note', description?: string,) => {
    let icon = <CheckCircleTwoTone twoToneColor="#52c41a" />
    if (type === 'error') icon = <CloseCircleTwoTone twoToneColor="#e65a96" />
    if (type === 'note') icon = <ExclamationCircleTwoTone />
    notification.open({
        message,
        description,
        icon,
        placement: 'bottomRight'
    })
}

export const shortUserName = (fullName: string) => {
    return fullName.split(' ').map((word, i) => i === 0 ? word : `${word[0]}.`).join(' ')
}

export function updateUrlQueries(
    location: RouteComponentProps['location'],
    history: RouteComponentProps['history'],
    params: { [key: string]: string | null }
) {
    const queries = new URLSearchParams(location.search)
    Object.entries(params).forEach(([qiery, value]) => {
        if (value === null) {
            queries.delete(qiery)
        } else {
            queries.set(qiery, value)
        }
    })
    history.replace(`${location.pathname}?${queries.toString()}`)
}

export function buildSearchParams(params: ChipLayout, maxIdx: number){
    const queries = new URLSearchParams()
    Object.entries(params).forEach(([key, value], idx) => {
        if(idx > maxIdx) return;
        let val;
        if(Array.isArray(value)){
            val = value.map((zn) => zn.id).join(',');
        }else{
            val = value.id;
        }
        queries.set(`${key}_id`, val);
    })
    return queries.toString();
}

export function parseSpecParam(param: string) {
    if (param === 'mm') return 'MM'
    if (param === 'px') return 'PX'
    if (param === 'vertical') return 'Вертикальная'
    if (param === 'horizontal') return 'Горизонтальная'
    if (param === 'square') return 'Квадратная'
    return param
}

export function parseDate(date: Date) {
    const year = date.getFullYear() + ''
    let month = date.getMonth() + 1 + ''
    let day = date.getDate() + ''

    if (month.length === 1) month = '0' + month
    if (day.length === 1) day = '0' + day

    return `${day}.${month}.${year}`
}

export function isDraggingOverElem(dragRect: React.RefObject<HTMLElement> | undefined, e: DragEvent): boolean {
    const rect = dragRect?.current?.getBoundingClientRect()
    if (rect) {
        const isMouseOverDragRect =
            e.x > rect.left &&
            e.x < rect.right &&
            e.y > rect.top &&
            e.y < rect.bottom
        if (isMouseOverDragRect) return true
    }
    return false
}

export function scrollTopSmooth(selector?: string) {
    let nodeElement = window as Window | Element | null
    if (selector) nodeElement = document.querySelector(selector)
    if (nodeElement) nodeElement.scrollTo({ behavior: 'smooth', top: 0 })
}

export const stringWithEnters = (value: string) => {
    return value.split('\n').map((word, i) => (
        <React.Fragment key={i}>
            { word }
            <br/>
        </React.Fragment>
    ))
}

/// navbar
const sourceFromCatalogById = (sourceId: string, catalog: xxCatalog) => {
    return catalog.children?.find(source => source.id + '' === sourceId)
}

export const zonesByCatalog = (catalog: xxCatalog) => {
    const zones = [] as xxCatalog[]
    const zoneIds = {} as { [key: string]: string[] }
    const sources = catalog.children || []

    /// get zones (dont include sources)
    sources.forEach(s => {
        const zonesOfSource = s.children
        if (zonesOfSource) {
            zonesOfSource.forEach(z => {
                if (!zoneIds[z.id]) {
                    zoneIds[z.id] = z.catalog_id
                    zones.push(z)
                }
            })
        }
    })

    /// add source to zones by catalog_id
    zones.forEach(z => {
        z.children = []
        z.catalog_id.forEach((sourceId: string) => {
            const source = sourceFromCatalogById(sourceId, catalog)
            if (source && z.children) {
                z.children.push(source)
            }
        })
        z.children.sort((a, b) => a.name.localeCompare(b.name))
    })

    return zones.sort((a, b) => a.name.localeCompare(b.name))
}

export const arrayItemsId = (arr: any[]) => {
    let result = ''
    arr.forEach(elem => result += JSON.stringify(elem))
    return result
}
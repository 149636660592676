import React from 'react'
import { ModalEditLayoutFields, FormikSetFieldError, FormikSetFieldValue } from '../../../../../types/types'
import { Select } from 'antd'
import InputSelect from '../../../../../Components/UI/Select/Select'
import { defaultProject } from '../../../../../Data/createData'
import { FormikErrors } from 'formik'
const { Option } = Select

type ProjectFieldProps = {
    value: ModalEditLayoutFields['project']
    valuePlacement: ModalEditLayoutFields['placement']
    errors: FormikErrors<ModalEditLayoutFields>
    setFieldValue: FormikSetFieldValue<ModalEditLayoutFields>
    setFieldError: FormikSetFieldError
}

const ProjectField: React.FC<ProjectFieldProps> = React.memo(({ value, valuePlacement, errors, setFieldValue, setFieldError }) => {

    const handleChange = (projectId: string | number | undefined) => {
        if (projectId === '-1') {
            setFieldError('project', '')
            return setFieldValue('project', null)
        }
        if (projectId + '' === defaultProject.id + '') {
            setFieldError('project', '')
            return setFieldValue('project', defaultProject)
        }

        const newProject = valuePlacement?.projects?.items?.find(p => p.id + '' === projectId + '')
        if (newProject) {
            setFieldError('project', '')
            setFieldValue('project', newProject)
        }
    }

    return (
        <InputSelect
            title="Проект"
            placeholder="Выберите проект"
            value={value?.name || null}
            wrapClasses={['project-select', 'bf-edit-layout__project']}
            errorMessage={errors.project}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                <Option value={defaultProject.id} key={defaultProject.id}>Не проект</Option>
                {
                    valuePlacement?.projects?.items?.map(p => (
                        <Option value={p.id} key={p.id}>{p.name}</Option>
                    ))
                }
            </>
        </InputSelect>
    )
})

export default ProjectField
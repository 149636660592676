import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../../store/actions/AuthActions'
import { IRootState } from '../../../store/reducers/rootReducer'
import './AuthForm.scss'
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type AuthFormProps = {
    onSubmit: (login: string, password: string) => void
}
const AuthForm: React.FC<AuthFormProps> = ({ onSubmit }) => {

    const isValid = useSelector<IRootState>(state => state.auth.isValid) as boolean
    const errorEmailMessage = useSelector<IRootState>(state => state.auth.errorEmailMessage) as string
    const errorPasswordMessage = useSelector<IRootState>(state => state.auth.errorPasswordMessage) as string
    const errorFormMessage = useSelector<IRootState>(state => state.auth.errorFormMessage) as string
    const isLoading = useSelector<IRootState>(state => state.auth.isLoading) as boolean

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const dispatch = useDispatch()

    const changeEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const trimValue = e.target.value.trim()
        setEmail(trimValue)
        checkValidateEmail(trimValue)
    }

    const changePasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        checkValidatePassword(e.target.value)
    }

    const checkValidateEmail = (email: string): boolean => {
        const isValidEmail = email.length > 0
        clearFormMessage()
        if (!isValidEmail) {
            dispatch(authActions.setErrorEmailMessage('Обязательное поле'))
            return false
        } else if (errorEmailMessage) {
            dispatch(authActions.setErrorEmailMessage(''))
        }
        return true
    }

    const checkValidatePassword = (password: string): boolean => {
        const lengthPassword = password.length
        clearFormMessage()
        if (lengthPassword < 6) {
            dispatch(authActions.setErrorPasswordMessage('Минимальная длинна пароля: 6'))
            return false
        } else if (errorPasswordMessage) {
            dispatch(authActions.setErrorPasswordMessage(''))
        }
        return true
    }

    const clearFormMessage = () => {
        if (errorFormMessage) {
            dispatch(authActions.setErrorFormMessage(''))
        }
    }

    const checkValidForm = () => {
        const hasSomeErrorMessage = !!errorEmailMessage || !!errorFormMessage || !!errorPasswordMessage
        if (hasSomeErrorMessage && isValid) {
            dispatch(authActions.setAuthValidStatus(false))
        } else if (!hasSomeErrorMessage && !isValid) {
            dispatch(authActions.setAuthValidStatus(true))
        }
    }

    const loginSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        const isValidEmail = checkValidateEmail(email)
        const isValidPassword = checkValidatePassword(password)

        if (isValidEmail && isValidPassword) {
            onSubmit(email, password)
        } else {
            dispatch(authActions.setAuthValidStatus(false))
        }
    }

    const emailClasses = ['auth-form__form-control']
    const emailMessageClasses = ['auth-form__input-message']
    const passwordClasses = ['auth-form__form-control']
    const emailPasswordClasses = ['auth-form__input-message']
    const formMessageClasses = ['auth-form__form-message']

    if (errorEmailMessage) {
        emailClasses.push('error')
    }

    if (errorPasswordMessage) {
        passwordClasses.push('error')
    }

    if (!isValid) {
        formMessageClasses.push('active')
    }

    checkValidForm()

    return (
        <div className="auth-form__body">

            <div className="auth-form__logo">
                <img src="/assets/img/logo-white.svg" alt="logo" className="auth-form__logo-img" />
            </div>

            <p className="auth-form__title">Вход</p>
            <p className="auth-form__description">Введи данные из основной учетной записи Bright Fit</p>

            <form onSubmit={loginSubmitHandler} className="auth-form__form">

                <div className="auth-form__form-group">
                    <input
                        type="text"
                        className={emailClasses.join(' ')}
                        id="email"
                        placeholder="Имя пользователя"
                        value={email}
                        onChange={changeEmailHandler}
                    />
                    <p className={emailMessageClasses.join(' ')}>{errorEmailMessage}</p>
                </div>

                <div className="auth-form__form-group">
                    <div className="auth-form__password-input">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={passwordClasses.join(' ')}
                            id="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={changePasswordHandler}
                        />
                        {
                            showPassword
                                ? <EyeInvisibleOutlined
                                    className="auth-form__password-show-icon auth-form__password-show-icon_active"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                                : <EyeOutlined
                                    className="auth-form__password-show-icon"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                        }
                    </div>
                    <p className={emailPasswordClasses.join(' ')}>{errorPasswordMessage}</p>
                </div>

                <p className={formMessageClasses.join(' ')}>{errorFormMessage}</p>

                <button
                    type="submit"
                    className={isLoading ? "auth-form__submit-btn loading" : "auth-form__submit-btn"}
                    disabled={!isValid}
                >
                    {
                        isLoading
                            ? <Spin className="auth-form__loader-container" indicator={
                                <LoadingOutlined className="auth-form__loader" spin />
                            }/>
                            : 'Войти'
                    }
                </button>

            </form>


        </div>
    )
}

export default AuthForm
import React from 'react'
import './AuthWaiting.scss'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'


const AuthWaiting: React.FC<{}> = () => {

    const spin = <LoadingOutlined
        className='auth-waiting__spin'
        spin
    />

    return (
        <div className="auth-waiting">
            <Spin indicator={spin} />
        </div>
    )
}

export default AuthWaiting
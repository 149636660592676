import React from 'react'
import { Link } from 'react-router-dom'

type ImageBlockSmallProps = {
    title: string
    description: string
    href: string
}
const ImageBlockSmall: React.FC<ImageBlockSmallProps> = ({ title, description, href }) => {

    return (
        <div className="main-categories__third">
            <Link to={href}>
                <p className="main-categories__third-title">{title}</p>
            </Link>

            <p className="main-categories__third-description">{description}</p>

            <Link to={href} className="main-categories__third-link-img">
                <div className="circle-arrow-sm"></div>
            </Link>
        </div>
    )
}

export default ImageBlockSmall
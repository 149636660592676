import { FormikErrors } from 'formik'
import React from 'react'
import InputSelect from '../../../../../Components/UI/Select/Select'
import { ModalEditLayoutFields, FormikSetFieldError, FormikSetFieldValue } from '../../../../../types/types'
import { Select } from 'antd'
const { Option } = Select

type SourceFieldProps = {
    value: ModalEditLayoutFields['source']
    valuePlacement: ModalEditLayoutFields['placement']
    errors: FormikErrors<ModalEditLayoutFields>
    setFieldValue: FormikSetFieldValue<ModalEditLayoutFields>
    setFieldError: FormikSetFieldError
}

const SourceField: React.FC<SourceFieldProps> = React.memo(({ value, valuePlacement, errors, setFieldValue, setFieldError }) => {

    const handleChange = (sourceId: string | number | undefined) => {
        if (sourceId === '-1') {
            setFieldValue('source', null)
            setFieldValue('zones', [])
            setFieldError('source', '')
            return
        }
        const newSource = valuePlacement?.sources.find(s => s.id + '' === sourceId + '')
        if (newSource) {
            setFieldValue('source', newSource)
            setFieldError('source', '')

            const newZones = newSource.children
            if (value?.id + '' !== sourceId + '') setFieldValue('zones', [])
            if (newZones && newZones.length === 1) {
                setFieldValue('zones', [newZones[0]])
            }
        }
    }

    return (
        <InputSelect
            title="Носитель"
            placeholder="Выберите носитель"
            value={value?.id || null}
            disabled={!valuePlacement}
            errorMessage={errors.source}
            wrapClasses={['create-select', 'right-offset']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    valuePlacement?.sources?.map(z => {
                        return (
                            <Option value={z.id + ''} key={z.id}>{z.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default SourceField
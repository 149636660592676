import React, {useCallback, useEffect, useRef, useState} from "react";
import {notification, List, Input, Button, Spin} from "antd";
import {freshAccessToken} from "../../../../../../../../utils/cookie/cookie";
import {deleteRegUrl, fileBaseUrl, getAllRegulations, tokenType, updateRegUrl} from "../../../../../../../../config";
import axios from "axios";
import {
    ResponseRegAllData,
    ReturnDataRegAllSuccess,
    xxRegulation
} from "../../../../../../../../types/responseTypes";
import {FormRegUpdate, LoadingVal} from "../RegulationNew/interfaces";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {createFormData} from "../../../../../../../../utils/other";

const RegulationAll: React.FC<LoadingVal> = ({isLoading}) => {

    const ref = useRef<HTMLInputElement>(null);
    const [currentReg, setCurrentReg] = useState<xxRegulation[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadContent, setLoadContent] = useState(false);
    const [currentEditId, setCurrentEditId] = useState<number>(0);

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        if(currentEditId !== 0){
            const reg = currentReg.find((r) => r.id === currentEditId);
            if(reg) {
                setName(reg.name);
                setDescription(reg.description);
            }
        }
    }, [currentEditId]);

    useEffect(() => {
        let isUnmount = false
        async function updateData() {
            try {
                setLoadContent(true);
                const accessToken = await freshAccessToken()
                const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
                const resp = await axios.get(getAllRegulations, options)
                const respData = resp.data as ResponseRegAllData
                if(respData.success){
                    setCurrentReg(respData.data)
                }else{
                    notification.error({
                        message: 'Ошибка соединения! Пожалуйста обновите страницу и попробуйте еще раз'
                    })
                }
                setLoadContent(false);
            } catch (e) {
                console.log(e)
                setLoadContent(false);
                notification.error({
                    message: 'Ошибка соединения! '+e.message
                })
            }
        }
        if(!isLoading) {
            updateData()
        }
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    const updateReg = useCallback((id: number) => async (data: FormRegUpdate) => {
        const file = ref?.current?.files?.[0] || null;
        if(!file && !data.name && !data.description && !data.hasOwnProperty('is_visible')) return;

        const dataToUpdate = {} as FormRegUpdate;
        if(file){
            dataToUpdate.file = file;
        }
        if(data.name){
            dataToUpdate.name = data.name;
        }
        if(data.description){
            dataToUpdate.description = data.description;
        }
        if(data.hasOwnProperty('is_visible')){
            dataToUpdate.is_visible = data.is_visible;
        }

        try {
            const accessToken = await freshAccessToken()
            setLoading(true);
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }
            const dataForm = createFormData(dataToUpdate);

            const resp = await axios.post(`${updateRegUrl}/${id}`, dataForm, options)
            const data = resp.data as ReturnDataRegAllSuccess

            if (data.success) {
                setCurrentEditId(0);
                setCurrentReg(data.data);
                notification.success({
                    message: 'Успешное обновления регламента'
                })
            } else {
                notification.error({
                    message: 'Ошибка обновления! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка обновления! '+ e.message
            })
            setLoading(false)
        }
    }, [ref]);

    const showHidden = useCallback((id: number) => async (isShow: boolean) => {
        await updateReg(id)({is_visible: isShow});
    }, [updateReg]);

    const deleteReg = useCallback((id: number) => async () => {
        try {
            const accessToken = await freshAccessToken()
            setLoading(true);
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }

            const resp = await axios.post(`${deleteRegUrl}/${id}`, options)
            const data = resp.data as ReturnDataRegAllSuccess

            if (data.success) {
                setCurrentEditId(0);
                setCurrentReg(data.data);
                notification.success({
                    message: 'Успешное удаление регламента'
                })
            } else {
                notification.error({
                    message: 'Ошибка удаления! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка удаления! '+ e.message
            })
            setLoading(false)
        }
    }, [])

    return (<div style={{maxWidth: 720}}>
        {loadContent && <div>
            <Spin/> Загрузка
        </div>}
        <List itemLayout="vertical" bordered>
            {currentReg.map((reg) => {
                return (
                    <>
                        {currentEditId !== reg.id && <List.Item className={reg.is_visible === 0 ? "reg-item__item" : ""} key={reg.id} actions={[
                            <a key="reg-item__file" className="reg-new__action" href={`${fileBaseUrl}/${reg.file}`} target="_blank">Скачать файл</a>,
                            <a key="reg-item__edit" className="reg-new__action" onClick={() => setCurrentEditId(reg.id)}>Редактировать</a>,
                            reg.is_visible === 1 ?
                                <a key="reg-item__hidden" className="reg-new__action" onClick={() => showHidden(reg.id)(false)}>Скрыть</a> :
                                <a key="reg-item__hidden" className="reg-new__action" onClick={() => showHidden(reg.id)(true)}>Показать</a>,
                            <a key="reg-item__delete" className="reg-new__del" onClick={deleteReg(reg.id)}>Удалить</a>
                        ]}>
                            <List.Item.Meta title={reg.name}
                                            avatar={loading ? <Spin/> : reg.is_visible === 1 ? <EyeOutlined style={{fontSize: 20}}/>: <EyeInvisibleOutlined style={{fontSize: 20}}/>}
                                            description={reg.description}/>
                        </List.Item>
                        }
                        {
                            currentEditId === reg.id && <List.Item key={reg.id}>
                                <Input placeholder="Название регламента" className="reg-new__item"
                                       disabled={loading}
                                       onChange={(e) => setName(e.target.value)} value={name}/>
                                <Input.TextArea rows={4} placeholder="Описание регламента" className="reg-new__item"
                                                disabled={loading}
                                                onChange={(e) => setDescription(e.target.value)}
                                                value={description}/>
                                <label htmlFor="file">Заменить файл</label>
                                <input type="file" className="reg-new__item" name="file" ref={ref} disabled={loading}/>
                                <Button type="primary" loading={loading} className="reg-new__item"
                                        onClick={(e) => updateReg(reg.id)({name, description})}>Сохранить</Button>
                                <Button type="default" loading={loading} className="reg-new__item" onClick={(e) => setCurrentEditId(0)}>Отмена</Button>
                            </List.Item>
                        }
                    </>
                )
            })}
        </List>
    </div>)
}

export default RegulationAll;
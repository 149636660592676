import { FormikErrors } from 'formik'
import React from 'react'
import RadioBtn from '../../../../Components/UI/RadioBtn/RadioBtn'
import RadioBtnGroup from '../../../../Components/UI/RadioBtnGroup/RadioBtnGroup'
import { CreateLayoutFields, FormikSetValues, InitialDataCreateLayout } from '../../../../types/types'

type PlacementFieldProps = {
    initialData: InitialDataCreateLayout
    values: CreateLayoutFields
    errors: FormikErrors<CreateLayoutFields>
    setValues: FormikSetValues<CreateLayoutFields>
    setErrors: (errors: FormikErrors<CreateLayoutFields>) => void
}

const PlacementField: React.FC<PlacementFieldProps> = ({ initialData, values, errors, setValues, setErrors }) => {

    const handleChange = (placementId: string) => {
        const newPlacement = initialData.catalogs.find(c => c.id + '' === placementId)
        if (newPlacement) {

            let newValues = {
                ...values,
                placement: newPlacement,
                _isDecoration: false,
                layoutType: null,
                source: null,
                zones: [],
                orientation: '',
            } as CreateLayoutFields

            setErrors({
                ...errors,
                placement: '',
                _isDecoration: '',
                layoutType: '',
                source: '',
                zones: '',
                orientation: '',
            })
            setValues(newValues)
        }
    }

    return (
        <RadioBtnGroup
            errorMessage={errors.placement}
            classes={['bf-create__location-group']}
        >
            {
                initialData.catalogs.map(c => {
                    return (
                        <RadioBtn
                            labelText={c.name}
                            value={c.id}
                            checked={c.id === values.placement?.id}
                            name="placement"
                            key={c.id}
                            onChange={handleChange}
                        />
                    )
                })
            }
        </RadioBtnGroup>
    )
}

export default PlacementField
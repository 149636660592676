import React, { useRef, useState } from 'react'
import './DesignSlider.scss'
import SwiperCore, { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Spin } from 'antd'
import { LoadingOutlined, ZoomInOutlined } from '@ant-design/icons'
import { forceBlur } from '../../../utils/styleFunctions/styleFunctions';
import { FileInfo } from '../../../types/responseTypes';
import { fileBaseUrl } from '../../../config';


interface DesignSliderProps {
    designSwiper: SwiperCore | null
    images: FileInfo[]
    setDesignSwiper: React.Dispatch<React.SetStateAction<SwiperCore | null>>
    setActiveSlideIdx: React.Dispatch<React.SetStateAction<number>>
    onZoom?: (imgSrc: string) => void
}

SwiperCore.use([Virtual, Navigation]);

const DesignSlider: React.FC<DesignSliderProps> = (props) => {
    const {
        images,
        designSwiper,
        onZoom,
        setDesignSwiper,
        setActiveSlideIdx,
    } = props

    const [isLoading, setLoading] = useState<boolean>(true)
    const imgRef = useRef<HTMLImageElement>(null)

    const handleZoom = () => {
        if (onZoom && designSwiper) {
            const activeImg = images[designSwiper.activeIndex]
            onZoom(activeImg.file_link)
        }
    }

    const recalcSliderHeight = () => {
        if (!designSwiper) return null
        setTimeout(() => designSwiper.updateAutoHeight(), 10)
    }

    const handleFetchImg = (idx: number) => {
        if (idx === 0) setLoading(false)
        recalcSliderHeight()
    }

    const handleSwiper = (swiper: SwiperCore) => {
        console.log(swiper)
        setDesignSwiper(swiper)
    }

    const handleChangeSlide = (swiper: SwiperCore) => {
        setActiveSlideIdx(swiper.activeIndex)
    }

    return (
        <div className="design-slider">
            <Swiper
                className="design-slider__slider"
                autoHeight={true}
                spaceBetween={5}
                navigation={{
                    nextEl: '#design-slider-next',
                    prevEl: '#design-slider-prev'
                }}
                onSlideChange={handleChangeSlide}
                onSwiper={handleSwiper}
                virtual
            >
                {
                    images.map((imgData, i) => (
                        <SwiperSlide
                            key={imgData.file_link}
                            virtualIndex={i}
                        >
                            <div className="design-slider__slide">
                                {
                                    imgData.file_link &&
                                    <img
                                        className="design-slider__slide-img"
                                        src={imgData.file_link}
                                        ref={i === 0 ? imgRef : null}
                                        alt={"SLIDE"}
                                        style={{ display: isLoading ? 'none' : 'block' }}
                                        onLoad={() => handleFetchImg(i)}
                                        onError={() => handleFetchImg(i)}
                                    />
                                }
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <button
                className="design-slider__prev-slide-btn"
                id="design-slider-prev"
                onClick={forceBlur}
            >
                <img
                    src="/assets/img/rightArrow.svg"
                    alt="->"
                />
            </button>
            <button
                className="design-slider__next-slide-btn"
                id="design-slider-next"
                onClick={forceBlur}
            >
                <img src="/assets/img/rightArrow.svg" alt="->" />
            </button>
            {
                isLoading
                    ? <div className="design-slider__loader"><Spin indicator={loader} /></div>
                    : null
            }
            {
                props.onZoom &&
                <div className="design-slider__zoom" onClick={handleZoom}>
                    <ZoomInOutlined className="design-slider__zoom-icon" />
                </div>
            }
        </div>

    )
}

const loader = <LoadingOutlined style={{ fontSize: '58px', color: ' #00C0D1' }} spin />

export default DesignSlider
import React from 'react'
import { Link } from 'react-router-dom'

type ImageBlockLargeProps = {
    title: string
    btnText: string
    href: string
    imgSrc: string
    altImg?: string
}
const ImageBlockLarge: React.FC<ImageBlockLargeProps> = ({ title, btnText, href, imgSrc, altImg }) => {

    return (
        <div className="main-categories__fullsize main-categories__fullsize_club-inside">
            <div className="main-categories__bg-img">
                <img src={imgSrc} alt={altImg || title} />
            </div>
            <div className="main-categories__fullsize-footer">
                <div className="main-categories__fullsize-title-wrapper">
                    <Link to={href} className="main-categories__fullsize-title">
                        {title}
                    </Link>
                </div>

                <Link to={href} className={"main-categories__fullsize-link bf-btn"}>
                    {btnText}
                </Link>
            </div>
        </div>
    )
}

export default ImageBlockLarge
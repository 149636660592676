import React from 'react'
import './RequiredDatesField.scss'
import TextFormikInput from '../../../../formikFields/TextFormikInput/TextFormikInput'
import { FormikSetFieldValue, ModalEditLayoutFields } from '../../../../../types/types'
import { masks } from '../../../../../Data/createData'

type RequiredDatesFieldProps = {
    show: boolean
    dateFrom: string
    errorDateFrom: string
    dateTo: string
    errorDateTo: string
    setFieldValue: FormikSetFieldValue<ModalEditLayoutFields>
    setFieldError: (field: keyof ModalEditLayoutFields, value: string | undefined) => void
}

const RequiredDatesField: React.FC<RequiredDatesFieldProps> = React.memo((props) => {
    const {
        show,
        dateFrom, dateTo,
        errorDateFrom,
        errorDateTo,
        setFieldValue,
        setFieldError,
    } = props

    if (!show) return null

    return (
        <div className="bf-edit-layout-required-dates">
            <p className="bf-edit-layout-required-dates__title">Даты</p>
            <div className="bf-edit-layout-required-dates__inputs">
                <TextFormikInput
                    title="Начало"
                    value={dateFrom}
                    error={errorDateFrom}
                    placeholder="ГГГГ-ММ-ДД"
                    mask={masks.date}
                    wrapperClasses={['create-input-required-date']}
                    onChangeValue={val => setFieldValue('requiredFrom', val)}
                    onChangeError={err => setFieldError('requiredFrom', err)}
                />
                <TextFormikInput
                    title=" Конец"
                    value={dateTo}
                    error={errorDateTo}
                    placeholder="ГГГГ-ММ-ДД"
                    mask={masks.date}
                    wrapperClasses={['create-input-required-date']}
                    onChangeValue={val => setFieldValue('requiredTo', val)}
                    onChangeError={err => setFieldError('requiredTo', err)}
                />
            </div>
        </div>
    )
})

export default RequiredDatesField
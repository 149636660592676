import { freshAccessToken } from './../../utils/cookie/cookie';
import { catalogsUrl, tokenType } from './../../config';
import { Dispatch } from 'react';
import { xxCatalog, xxCatalogs, UserInfo } from './../../types/responseTypes';
import { InferValueTypes } from './../../types/types';
import {APP_SET_USER_INFO, APP_SET_CATALOGS, APP_LOGOUT_USER, APP_MENU_KEY} from "./actionTypes"
import axios from 'axios';

export function updateCatalogs() {
    return async (dispatch: Dispatch<AllAppActions>) => {
        try {
            const accessToken = await freshAccessToken()
            const options = { headers: { 'Authorization': `${tokenType} ${accessToken}`} }
            const res = await axios.get(catalogsUrl, options)
            const catalogsData = res.data as xxCatalogs

            console.log('Navbar Catalog', catalogsData);
            if (res.status === 200 && catalogsData.success) {
                dispatch(appActions.setCatalogs(catalogsData.data))
                console.log('catalog_api', catalogsData)

            } else if (res.status === 401) {
                console.log('Авторизация не подтверждена')
            } else if (res.status !== 200) {
                console.log('Ошибка получения данных')
            }
        } catch(e) {
            console.log(e)
        }
    }
}

export const appActions = {
    setUserInfo(payload: UserInfo) {
        return {
            type: APP_SET_USER_INFO,
            payload
        } as const
    },
    logoutUser() {
        return {
            type: APP_LOGOUT_USER
        } as const
    },
    setCatalogs(payload: xxCatalog[] | null) {
        return {
            type: APP_SET_CATALOGS,
            payload
        } as const
    },
    setMenuKey(payload: string){
        return{
            type: APP_MENU_KEY,
            payload
        } as const
    }
}

export type AllAppActions = ReturnType<InferValueTypes<typeof appActions>>
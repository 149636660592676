import React from 'react';
import Input from '../../UI/Input/Input';

type TextFormikInputProps = {
    title: string
    value: string
    onChangeValue: (value: string) => void
    error?: string
    placeholder?: string
    clearErrorAfterInput?: boolean
    wrapperClasses?: string[]
    mask?: (string | RegExp)[]
    onChangeError?: (newError: string) => void
}

const TextFormikInput: React.FC<TextFormikInputProps> = (props) => {

    const {
        value, error,
        wrapperClasses, clearErrorAfterInput = true,
        title, placeholder, mask,
        onChangeValue, onChangeError,
    } = props

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        onChangeValue(value)
        if (clearErrorAfterInput && onChangeError) onChangeError('')
    }

    return (
        <Input
            title={title}
            placeholder={placeholder}
            value={value}
            errorMessage={error}
            wrapperClasses={wrapperClasses}
            mask={mask}
            onChange={handleChange}
        />
    )
}

export default TextFormikInput;
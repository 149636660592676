import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import ImageBlockLarge from '../../Components/ImageLinkBlocks/ImageBlockLarge/ImageBlockLarge';
import ImageBlockMedium from '../../Components/ImageLinkBlocks/ImageBlockMedium/ImageBlockMedium';
import ImageBlockSmall from '../../Components/ImageLinkBlocks/ImageBlockSmall/ImageBlockSmall';
import NavbarLayout from '../../Components/Layout/NavbarLayout/NavbarLayout';
import { tokenType, getContentUrl } from '../../config';
import { freshAccessToken } from '../../utils/cookie/cookie';
import { CatalogContent, LayoutPreview } from '../../types/responseTypes'
import { openNotification } from '../../utils/other'
import './MainPage.scss'
import LastLayoutsBlock from './LastLayoutsBlock/LastLayoutsBlock';
import { useZoomModal } from '../../utils/hooks/useZoomModal';
import ZoomModal from '../../Components/Modals/ZoomModal/ZoomModal';

interface MatchParamsRouterProps {
    directionCategory?: string
    locationCategory?: string
    categoryName?: string
    layoutName?: string
}

interface MainPageProps extends RouteComponentProps<MatchParamsRouterProps> {}

const maxLastItems = 8

const MainPage: React.FC<MainPageProps> = () => {

    const [lastLayouts, setLastLayouts] = useState<LayoutPreview[]>([])
    const [isLoadedLayouts, setStatusLoadedLayouts] = useState<boolean>(false)

    const zoom = useZoomModal()

    useEffect(() => {
        let isUnmount: boolean = false;
        (async function() {
            try {
                const url = `${getContentUrl}?limit=${maxLastItems}&page=1`
                const accessToken = await freshAccessToken()
                const option = { headers: {'Authorization': `${tokenType} ${accessToken}`}}
                const res = await axios.get(url, option)
                const data = res.data as CatalogContent
                console.log('Main page res', res)
                if (data && !isUnmount) {
                    setLastLayouts(data.data)
                    return
                }
            } catch(e) {
                console.log(e)
                openNotification('Ошибка', 'error', `Ошибка загрузки последних макетов: ${e.message}`)
            } finally {
                setStatusLoadedLayouts(true)
            }
        })()
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => { window.scroll(0, 0) }, [])

    return (
        <NavbarLayout>
            <section className="main-categories">
                <div className="bf-container main-categories__container">
                    <ImageBlockLarge
                        title='Внутри клуба'
                        btnText='Смотреть макеты'
                        href='/prototype/catalog/?placement_id=1'
                        imgSrc='../../assets/img/min/clubInside.png'
                    />

                    <ImageBlockMedium
                        title='Вне клуба'
                        href='/prototype/catalog/?placement_id=89'
                        imgSrc='../../assets/img/min/clubOutside.png'
                    />

                    <ImageBlockMedium
                        title='Интернет'
                        href='/prototype/catalog/?placement_id=99'
                        imgSrc='../../assets/img/min/internet.png'
                    />

                    <div className="main-categories__small-cards">
                        <ImageBlockSmall
                            title="Обязательные"
                            description="Список обязательных к размещению макетов"
                            href="/prototype/catalog/?required_now=true"
                        />

                        <ImageBlockSmall
                            title="Реестр размещений"
                            description="Список мест и партнеров для размещения рекламы"
                            href="/prototype/"
                        />

                        <ImageBlockSmall
                            title="Регламенты"
                            description="Как заказывать макеты и другая важная информация"
                            href="/prototype/regulation"
                        />
                    </div>
                </div>
            </section>

            <section className="last-makets">
                <div className="bf-container">
                    <LastLayoutsBlock
                        lastLayouts={lastLayouts}
                        isLoadedLayouts={isLoadedLayouts}
                        onZoom={zoom.open}
                    />
                    <Link to="/prototype/catalog" className="last-makets__all-btn bf-btn bf-btn-primary">
                        Смотреть все макеты
                    </Link>
                </div>
            </section>

            <ZoomModal {...zoom}/>
        </NavbarLayout>
    )
}

export default MainPage
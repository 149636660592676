import { FormikErrors } from 'formik'
import React from 'react'
import { orientationsCreate } from '../../../Data/createData'
import { orientationsByPlacementId } from '../../../utils/formUtils'
import Radio from '../../UI/Radio/Radio'
import RadioGroup from '../../UI/RadioGroup/RadioGroup'

const orientations = orientationsCreate()

type OrientationsFormikProps = {
    value: string
    onChangeValue: (val: string) => void
    onChangeError?: (err: string) => void
    error?: FormikErrors<{}>
    placementId?: number
    classes?: string[]
    wrapperClasses?: string[]
    clearErrorAfterChange?: boolean
}

const OrientationsFormik: React.FC<OrientationsFormikProps> = React.memo((props) => {
    const {
        value, placementId, error,
        classes, clearErrorAfterChange = true,
        wrapperClasses,
        onChangeValue, onChangeError,
    } = props

    const handleChange = (value: string) => {
        onChangeValue(value)
        if (clearErrorAfterChange && onChangeError) onChangeError('')
    }

    const renderOrientations = placementId ? orientationsByPlacementId(placementId, orientations) : orientations

    return (
        <RadioGroup
            title="Ориентация"
            classes={wrapperClasses}
            errorMessage={error && error.toString()}
        >
            {
                renderOrientations.map(o => (
                    <li key={o._id}>
                        <Radio
                            name="orientation"
                            labelText={o.label}
                            value={o.value}
                            checked={value === o.value}
                            classes={classes}
                            onChange={() => handleChange(o.value)}
                        />
                    </li>
                ))
            }
        </RadioGroup>
    )
})

export default OrientationsFormik
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateCatalogs } from '../../store/actions/AppActions'
import { IRootState } from '../../store/reducers/rootReducer'
import { xxCatalog } from '../../types/responseTypes'
import { UserRole } from '../../types/stateTypes'
import { throttle, zonesByCatalog } from '../../utils/other'
import { isLockBody, lockScrollBody, unlockScrollBody } from '../../utils/styleFunctions/styleFunctions'
import LinkGroup from './LinkGroup/LinkGroup'
import './NavBar.scss'
import UserField from './UserField/UserField'

const allowIdsMainLinks = [1, 89, 99]
const catalogIdsMainLinks = [1, 89, 99]
const gereralDesignLink = 121
const placeRegisterLink = 122
const requiredLinkId = -54

const  NavBar: React.FC<{}> = React.memo(() => {

    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]

    const [hoveredCatalog, setHoveredCatalog] = useState<xxCatalog | null>(null)
    const [isShowDropMenu, setShowDropMenu] = useState<boolean>(true)

    const hasSecondLvl = hoveredCatalog?.children     // if hovered category has seconds level categories

    const catalogs = useSelector<IRootState>(state => state.app.catalogs) as xxCatalog[] | null
    const dispatch = useDispatch()

    const hasAccess = () => userRoles.includes('Admin') || userRoles.includes('Designer')

    const mainLinksEnterHandler = (catalog: xxCatalog) => {
        setHoveredCatalog(catalog)
        if (catalog.children) showDropMenu()
    }

    const showDropMenu = () => {
        setShowDropMenu(true)
        lockScrollBody()
    }

    const hideDropMenu = () => {
        setShowDropMenu(false)
        unlockScrollBody()
    }

    const toggleDropMenuClick = (catalog: xxCatalog) => {
        if (!isShowDropMenu) return mainLinksEnterHandler(catalog)

        if (catalog.id !== hoveredCatalog?.id) {
            mainLinksEnterHandler(catalog)
        } else {
            hideDropMenu()
        }
    }

    const mainLinksWithMenu = () => catalogs?.filter(c => catalogIdsMainLinks.includes(c.id))

    const linksLeaveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        const relatedTarget = e.relatedTarget as HTMLDivElement | Window
        const isWindow = (relatedTarget: HTMLDivElement | Window): relatedTarget is Window => {
            return relatedTarget === window
        }
        if (isWindow(relatedTarget)) {
            hideDropMenu()
        } else if (relatedTarget.closest) {
            const leaveToHeaderMenu = relatedTarget.closest('#navbar-header')
            if (!leaveToHeaderMenu) hideDropMenu()
        }
    }

    const mainLinkEnterHandler = (catalog: xxCatalog) => {
        const hasDropMenu = !(catalog.id === gereralDesignLink || catalog.id === placeRegisterLink)
        if (hasDropMenu) {
            toggleDropMenuClick(catalog)
        } else {
            hideDropMenu()
        }
    }

    const mainLinkClasses = (catalog: xxCatalog) => {
        const classes = [] as string[]
        if (hoveredCatalog?.id === catalog.id && isShowDropMenu) classes.push('active')
        if (catalog.id === requiredLinkId) classes.push('special')
        return classes.join(' ')
    }

    const renderMainLinks = () => {
        const mainLinks = catalogs?.filter(c => allowIdsMainLinks.includes(c.id))
        if (mainLinks) mainLinks.push({
            catalog_id: ["0"],
            id: requiredLinkId,
            is_system: 0,
            key: null,
            name: "Обязательные",
        } as xxCatalog)
        if (mainLinks) {
            return mainLinks.map(catalog => {
                const isCatalogMainLink = catalogIdsMainLinks.includes(+catalog.id)
                let linkPath = '/prototype'
                if (isCatalogMainLink) linkPath = `/prototype/catalog/?placement_id=${catalog.id}`
                if (catalog.id === gereralDesignLink) linkPath = '/prototype/generals'
                if (catalog.id === placeRegisterLink) linkPath = '/prototype/placeRegister'
                if (catalog.id === requiredLinkId) linkPath = '/prototype/catalog/?required_now=true'

                return (
                    <li
                        key={catalog.id}
                        className={mainLinkClasses(catalog)}
                    >
                        {/* {
                            catalog.id === gereralDesignLink || catalog.id === placeRegisterLink
                                ? <Link
                                    to={linkPath}
                                    onClick={() => toggleDropMenuClick(catalog)}
                                >
                                    {catalog.name}
                                </Link>
                                : <button
                                    onClick={() => toggleDropMenuClick(catalog)}
                                >
                                    {catalog.name}
                                </button>
                        } */}
                        <Link
                            to={linkPath}
                            onMouseEnter={() => mainLinkEnterHandler(catalog)}
                            onClick={(e) => {
                                const target = e.currentTarget
                                target?.blur();
                                toggleDropMenuClick(catalog)
                            }}
                            onFocus={() => mainLinkEnterHandler(catalog)}
                        >
                            {catalog.name}
                        </Link>
                    </li>
                )
            })
        }
        return null
    }

    const renderSublinks = (catalog: xxCatalog) => {
        if (catalog.children) {
            const sublinks: JSX.Element[] = []
            const zones = zonesByCatalog(catalog)

            if (zones.length) {
                zones?.forEach(zone => {
                    sublinks.push(
                        <LinkGroup
                            firstLvl={catalog}
                            secondLvl={zone}
                            key={zone.id}
                            onClick={hideDropMenu}
                        />
                    )
                })
            }
            return sublinks
        }
        return null
    }

    const dropMenuClasses = ['bf-navbar__drop-menu']

    if (isShowDropMenu && hasSecondLvl) {
        dropMenuClasses.push('active')
    }

    useEffect(() => {
        dispatch(updateCatalogs())

        const bodyUnlocker = throttle((e: MouseEvent) => {
            // force close opened navbar
            const target = e.target as HTMLElement
            if (target) {
                const isMouseOverNavbar = target.closest('#bf-nav-top')
                const isMouseOverFadeModal = target.closest('#bf-fade-modal')
                const isMouseOverSelect = target.closest('.ant-select-dropdown')

                if (!isMouseOverNavbar
                    && !isMouseOverFadeModal
                    && !isMouseOverSelect
                    && isLockBody()
                ) unlockScrollBody()
            }
        }, 1500)
        document.body.addEventListener('mousemove', bodyUnlocker)

        return () => document.body.removeEventListener('mousemove', bodyUnlocker)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!catalogs) return null

    return (
        <>
            <nav className="bf-navbar" id="bf-nav-top">
                <div className="bf-navbar__header">
                    <div className="bf-container">
                        <div className="bf-navbar__main-content" id="navbar-header">
                            <Link to="/prototype/main" className="bf-navbar__logo">
                                <img src="/assets/img/logo.svg" alt="logo" />
                            </Link>

                            <ul className="bf-navbar__main-links">
                                {
                                    renderMainLinks()
                                }
                            </ul>

                            <div className="bf-navbar__btn-group">
                                {
                                    hasAccess() &&
                                    <Link
                                        to="/prototype/create"
                                        className="bf-navbar__add-btn bf-btn bf-btn-primary"
                                    >
                                        +
                                    </Link>
                                }
                                <UserField />
                            </div>

                        </div>
                    </div>
                </div>

                <div
                    className={dropMenuClasses.join(' ')}
                    onMouseLeave={linksLeaveHandler}
                >
                    <div className="bf-container">
                        {
                            mainLinksWithMenu()?.map(c => {
                                const classes = ['bf-navbar__links-block']
                                const isActiveMenu = c.id === hoveredCatalog?.id
                                if (!isActiveMenu) classes.push('hidden')
                                return (
                                    <div className={classes.join(' ')} key={c.id}>
                                        {
                                            renderSublinks(c)
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </nav>
        </>
    )
})

export default NavBar
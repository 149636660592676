import React from 'react'
import './DesignSliderThumbs.scss'
import SwiperCore from 'swiper';
import { forceBlur } from '../../../utils/styleFunctions/styleFunctions';
import { FileInfo } from '../../../types/responseTypes';
import ImgWithLoader from '../../ImgWithLoader/ImgWithLoader';

interface DesignSliderThumbsProps {
    images: FileInfo[]
    activeSlideIdx: number
    designSwiper: SwiperCore | null
}

const DesignSliderThumbs: React.FC<DesignSliderThumbsProps> = (props) => {
    const {
        images,
        activeSlideIdx,
        designSwiper,
    } = props

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
        designSwiper?.slideTo(idx)
        forceBlur(e)
    }

    return (
        <div className="design-slider-thumbs">
            <div className="design-slider-thumbs__container">
                <p className="design-slider-thumbs__container-text">
                    Превью адаптации
                </p>
                {
                    images.map((imgData, idx) => {
                        const classes = idx === activeSlideIdx
                            ? "design-slider-thumbs__item active"
                            : "design-slider-thumbs__item"
                        return (
                            <button
                                className={classes}
                                key={imgData.file_link}
                                onMouseDown={e => forceBlur(e)}
                                onClick={e => handleClick(e, idx)}
                            >
                                {
                                    imgData.file_link &&
                                    <ImgWithLoader
                                        src={imgData.file_link}
                                        alt="thumb"
                                        wrapperClasses={['design-slider-thumbs__img']}
                                        spinClasses={['design-slider-thumbs__img-loader']}
                                    />
                                }
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DesignSliderThumbs
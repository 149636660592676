import React from 'react';
import TextArea from '../../UI/TextArea/TextArea';

type TextAreaFormikProps = {
    title: string
    value: string
    onChangeValue: (value: string) => void
    error?: string
    placeholder?: string
    clearErrorAfterInput?: boolean
    classes?: string[]
    wrapperClasses?: string[]
    onChangeError?: (newError: string) => void
}

const TextAreaFormik: React.FC<TextAreaFormikProps> = (props) => {

    const {
        value, error,
        wrapperClasses, clearErrorAfterInput = true,
        title, placeholder, classes,
        onChangeValue, onChangeError,
    } = props

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        onChangeValue(value)
        if (clearErrorAfterInput && onChangeError) onChangeError('')
    }

    return (
        <TextArea
            title={title}
            placeholder={placeholder}
            value={value}
            errorMessage={error}
            classes={classes}
            wrapperClasses={wrapperClasses}
            onChange={handleChange}
        />
    )
}

export default TextAreaFormik;
import { useState } from "react"

type ZoomState = {
    isShow: boolean
    imgSrc: string
}

export const useZoomModal = () => {

    const [zoom, setZoom] = useState<ZoomState>({
        isShow: false,
        imgSrc: '',
    })

    const close = () => {
        setZoom(prev => (
            {
                ...prev,
                isShow: false
            }
        ))
    }

    const open = (imgSrc: string) => {
        setZoom(prev => (
            {
                ...prev,
                isShow: true,
                imgSrc,
            }
        ))
    }

    return {
        ...zoom,
        close,
        open,
    }
}
import React from 'react'
import './InputNumber.scss'


type InputNumberProps = {
    value?: string | number
    name?: string
    id?: string
    title?: string
    placeholder?: string
    classes?: string[]
    minValue?: number
    maxValue?: number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputNumber: React.FC<InputNumberProps> = ({ title, id, name, value, classes, placeholder, minValue, maxValue, onChange, onInput }) => {

    let inputClasses = ['bf-input-number']

    if (classes && classes.length > 0) {
        inputClasses = inputClasses.concat(classes)
    }

    if (!title) {
        inputClasses.push('fullSize')
    }

    return (
        <label className={inputClasses.join(' ')}>
            {
                title
                    ? <p className="bf-input-number__title">{title}</p>
                    : null
            }
            <input
                type="number"
                placeholder={placeholder}
                id={id}
                name={name}
                value={value}
                min={minValue}
                max={maxValue}
                className="bf-input-number__input"
                onChange={onChange}
                onInput={onInput}
            />
        </label>
    )
}

export default InputNumber
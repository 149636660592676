import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Divider, Input, notification, Typography} from "antd";
import {useFormik} from "formik";
import {FormDataReg, LoadingCallback} from "./interfaces";
import {freshAccessToken} from "../../../../../../../../utils/cookie/cookie";
import {insertRegUrl, tokenType} from "../../../../../../../../config";
import {createFormData} from "../../../../../../../../utils/other";
import axios from "axios";
import {ReturnDataRegSuccess} from "../../../../../../../../types/responseTypes";

import './style.css';

const RegulationNew: React.FC<LoadingCallback> = ({setLoading}) => {

    const ref = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading, setLoading]);

    const submitReg = useCallback(async (val: FormDataReg) => {
        const file = ref?.current?.files?.[0] || null;
        if(!file && !val.name && !val.description) return;

        try {
            const accessToken = await freshAccessToken()
            setIsLoading(true);
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }
            const dataForm = createFormData({...val, file: file});

            const resp = await axios.post(insertRegUrl, dataForm, options)
            const data = resp.data as ReturnDataRegSuccess

            if (data.success) {
                resetForm()
                notification.success({
                    message: 'Успешное создание регламента'
                })
            } else {
                notification.error({
                    message: 'Ошибка создания! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка создания! '+ e.message
            })
            setIsLoading(false)
        }
    }, []);

    const {values, setFieldValue, resetForm} = useFormik<FormDataReg>({
        initialValues: {
            name: '',
            description: ''
        },
        onSubmit: submitReg
    });

    const onChangeInput = useCallback((key: string) => (e: any) => {
        setFieldValue(key, e.target?.value);
    }, [])

    return(<div className="reg-new__content" style={{maxWidth: 420}}>
        <Typography.Title level={3} className="reg-new__title">Создание нового регламента</Typography.Title>
        <Input placeholder="Название регламента" className="reg-new__item"
               disabled={isLoading}
               onChange={onChangeInput('name')} value={values.name}/>
        <Input.TextArea rows={4} placeholder="Описание регламента" className="reg-new__item"
                        disabled={isLoading}
                        onChange={onChangeInput('description')} value={values.description}/>
        <input type="file" className="reg-new__item" ref={ref} disabled={isLoading}/>
        <Button type="primary" loading={isLoading} className="reg-new__item" onClick={(e) => submitReg(values)}>Сохранить</Button>
        <Divider className="reg-new__item"/>
    </div>)
}

export default RegulationNew
import React, { useEffect, useRef, useState } from 'react'
import './NewDesignPage.scss'
import NavbarLayout from '../../Components/Layout/NavbarLayout/NavbarLayout'
import { useSelector } from 'react-redux'
import { IRootState } from '../../store/reducers/rootReducer'
import { UserRole } from '../../types/stateTypes'
import { CreateDesign } from '../../types/requestTypes'
import { CreateLayoutFields, InitialDataCreateLayout } from '../../types/types'
import NoteMessage from '../../Components/UploadStatus/UploadStatus'
import FadeModal from '../../Components/Modals/FadeModal/FadeModal'
import { createFormData, openNotification, scrollTopSmooth } from '../../utils/other'
import { CreateDesignResponse, CreateDesignTemplate } from '../../types/responseTypes'
import { freshAccessToken } from '../../utils/cookie/cookie'
import { getTemplateUrl, insertLayoutUrl, tokenType } from '../../config'
import axios from 'axios'
import ClubField from './FormFields/ClubField/ClubField'
import { defaultProject, masks, orientationsCreate } from '../../Data/createData'
import { useFormik } from 'formik'
import { ResetButton, SubmitButton } from './Controls'
import {
    PlacementField, AdaptationField,
    CityField, SourceField, ZonesField,
    ProjectField, DecorationSwitch, LayoutTypeField,
} from './FormFields'
import useScrollToTop from '../../utils/hooks/useScrollToTop'
import useUploadModal from '../../utils/hooks/useUploadModal'
import { orientationsByPlacement, validateRequiredDates } from '../../utils/formUtils'
import { RouteComponentProps } from 'react-router'
import TextFormikInput from '../../Components/formikFields/TextFormikInput/TextFormikInput'
import TextAreaFormik from '../../Components/formikFields/TextAreaFormik/TextAreaFormik'
import SizePresetsFormik from '../../Components/formikFields/SizePresetsFormik/SizePresetsFormik'
import SizesFormik from '../../Components/formikFields/SizesFormik/SizesFormik'
import OrientationsFormik from '../../Components/formikFields/OrientationsFormik/OrientationsFormik'
import PreviewInputFormik from '../../Components/formikFields/PreviewInputFormik/PreviewInputFormik'
import MultipleFilesFormik from '../../Components/formikFields/MultipleFilesFormik/MultipleFilesFormik'
import RequiredSwitch from './FormFields/RequiredSwitch/RequiredSwitch'
import RequiredDatesField from './FormFields/RequiredDatesField/RequiredDatesField'
import MultipleImgFilesFormik from '../../Components/formikFields/MultipleImgFilesFormik/MultipleFilesFormik'
import { clearReqDate } from '../../utils/parsers'
import TagsField from "./FormFields/TagsFields/TagsFields";

const orientations = orientationsCreate()

interface NewDesignPageProps extends RouteComponentProps { }

export const NewDesignPage: React.FC<NewDesignPageProps> = ({ history }) => {

    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const dragRect = useRef<HTMLDivElement>(null)
    const [initialData, setInitialData] = useState<InitialDataCreateLayout>({ catalogs: [], cities: [], clubs: [], tags: [] })

    const upload = useUploadModal()

    const isAllowedCreate = userRoles.includes('Admin') || userRoles.includes('Designer')

    const validate = (values: CreateLayoutFields) => {
        const errors = {} as { [key: string]: string }
        const isValidOrientation = ['horizontal', 'vertical', 'square'].includes(values.orientation)

        if (!values.name) errors.name = 'Укажите имя'
        if (!values.placement) errors.placement = 'Укажите размещение'
        if (!values.project) errors.project = 'Укажите проект'
        if (!values.source) errors.source = 'Выберите носитель'
        if (!isValidOrientation) errors.orientation = 'Укажите ориентацию'
        if (!values.preview) errors.preview = 'Загрузите превью'
        if (!values.original.length) errors.original = 'Загрузите один или несколько исходников'

        validateRequiredDates(errors, values.isRequired, values.requiredFrom, values.requiredTo)

        if (Object.keys(errors).length) {
            scrollTopSmooth()
            openNotification('Ошибка', 'error', 'Заполните обязательные поля')
        }
        return errors
    }

    const submitFormHandler = (clearReqDatevalues: CreateLayoutFields) => {
        const zoneIds = values.zones.map(z => z.id).join(',')
        const tagIds = values.tags.map(z => z.id).join(',')
        const designData: CreateDesign = {
            title: values.name,
            description: values.description,
            preview: values.preview as File,
            original: values.original as File[],
            is_adaptive: +values.adaptation as 0 | 1,
            orientation: values.orientation,
            placement_id: values.placement?.id || 0,
            zone_id: zoneIds,
            source_id: values.source?.id || 0,
            project_id: values.project?.id || 0,
            prints: values.prints,
            is_required: values.isRequired,
            required_from: clearReqDate(values.requiredFrom),
            required_to: clearReqDate(values.requiredTo),
            other_previews: values.otherPreviews,
        }
        if (values.width) designData.width = +values.width
        if (values.height) designData.height = +values.height
        if (values.unit) designData.unit = values.unit
        if (values.city) designData.city_id = values.city.id
        if (values.club) designData.club_id = values.club.id
        if (values.layoutType) designData.type_id = values.layoutType.id
        if(values.tags) designData.tag_id = tagIds

        console.log('CREATED', designData)
        if (isAllowedCreate) sendData(designData)
    }

    const {
        values, errors, setFieldValue,
        setValues, resetForm, submitForm,
        setFieldError, setErrors,

    } = useFormik<CreateLayoutFields>({
        initialValues: {
            name: '',
            description: '',
            placement: null,
            city: null,
            club: null,
            source: null,
            zones: [],
            tags: [],
            project: null,
            layoutType: null,
            width: '',
            height: '',
            unit: '',
            orientation: '',
            adaptation: false,
            preview: null,
            original: [],
            prints: [],
            otherPreviews: [],

            isRequired: false,
            requiredFrom: '',
            requiredTo: '',

            _isDecoration: false,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate,
        onSubmit: submitFormHandler,
    })

    /// fetch
    const fetchTemplate = async () => {
        try {
            const accessToken = await freshAccessToken()
            const options = {
                headers: { 'Authorization': `${tokenType} ${accessToken}` }
            }
            const res = await axios.get(getTemplateUrl, options)
            const template = res.data as CreateDesignTemplate

            console.log('initial data CREATE', res);

            if (res.status === 200 && template.success) {
                setInitialData({
                    cities: template.data.cities,
                    catalogs: template.data.catalogs,
                    clubs: template.data.clubs,
                    tags: template.data.tags.map((tag) => ({...tag, name: `#${tag.name}`}))
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const sendData = async (designData: CreateDesign) => {
        try {
            const accessToken = await freshAccessToken()
            upload.loading('Загрузка данных', 'Пожалуйста подождите')
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
                onUploadProgress(e: any) {
                    upload.set.totalSize(e.total)
                    upload.set.uploadedSize(e.loaded)
                }
            }
            const dataForm = createFormData(designData)

            /// Form Data Logger
            const _dataFormFields = Object.keys(designData).map(name => ([name, dataForm.getAll(name)]))
            console.log(_dataFormFields);
            ///

            const resp = await axios.post(insertLayoutUrl, dataForm, options)
            const data = resp.data as CreateDesignResponse

            if (data.success) {
                resetForm()
                setFieldValue('placement', initialData.catalogs[0])
                upload.success('Макет создан', 'Вы будете перенаправлены на созданный макет')
                setTimeout(() => history.push(`/prototype/catalog/${data.layout_id}/${data.adaptive_id}`), 2500)
            } else {
                upload.error('Ошибка создания', 'Пожалуйста обновите страницу и попробуйте еще раз')
                setTimeout(() => upload.hide(), 2500)
                setTimeout(() => upload.reset(), 3500)
            }
        } catch (e) {
            console.log(e)
            upload.error('Ошибка создания', e.message)
            setTimeout(() => upload.hide(), 4000)
            setTimeout(() => upload.reset(), 6000);
        }
    }
    ///

    const chooseDefaultFields = () => {
        /// auto choose firt placement and update depended field after load initialData
        const firstPlacement = initialData.catalogs[0]
        if (firstPlacement) {
            const firstOrientation = orientationsByPlacement(firstPlacement, orientations)[0]

            let newValues = {
                ...values,
                placement: firstPlacement,
                _isDecoration: false,
                layoutType: null,
                source: null,
                zones: [],
                project: defaultProject,
            } as CreateLayoutFields

            if (firstOrientation) {
                newValues.orientation = firstOrientation.value
            }
            setValues(newValues)
        }
    }

    useEffect(() => {
        fetchTemplate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        chooseDefaultFields()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData])

    useScrollToTop()

    return (
        <NavbarLayout>
            <section className="bf-create">
                <div className="bf-container" ref={dragRect}>
                    <form className="bf-create__content" onSubmit={e => e.preventDefault()}>

                        <div className="bf-create__params-field">
                            <p className="bf-create__title">Новый макет</p>
                            <TextFormikInput
                                title="Название"
                                value={values.name}
                                error={errors.name}
                                placeholder="Введите название"
                                wrapperClasses={['create-input']}
                                onChangeValue={val => setFieldValue('name', val)}
                                onChangeError={err => setFieldError('name', err)}
                            />

                            <TextAreaFormik
                                title="Описание"
                                value={values.description}
                                placeholder="Введите описание"
                                onChangeValue={val => setFieldValue('description', val)}
                            />
                            <div className="bf-create__position">
                                <p className="bf-create__position-title">Размещение</p>
                                <div className="bf-create__position-inputs">

                                    <PlacementField
                                        initialData={initialData}
                                        values={values}
                                        errors={errors}
                                        setValues={setValues}
                                        setErrors={setErrors}
                                    />

                                    <div className="bf-create__select-block">
                                        <CityField
                                            initialData={initialData}
                                            value={values.city}
                                            setFieldValue={setFieldValue}
                                        />
                                        <ClubField
                                            initialData={initialData}
                                            value={values.club}
                                            valueCity={values.city}
                                            setFieldValue={setFieldValue}
                                        />
                                        <SourceField
                                            value={values.source}
                                            valuePlacement={values.placement}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            setFieldError={setFieldError}
                                        />
                                        <ZonesField
                                            value={values.zones}
                                            valueSource={values.source}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                            </div>

                            <TagsField
                                value={values.tags}
                                initialData={initialData}
                                setFieldValue={setFieldValue}
                            />

                            <ProjectField
                                value={values.project}
                                valuePlacement={values.placement}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                            />

                            <DecorationSwitch
                                value={values._isDecoration}
                                valuePlacement={values.placement}
                                setFieldValue={setFieldValue}
                            />
                            <LayoutTypeField
                                value={values.layoutType}
                                valueDecoration={values._isDecoration}
                                valuePlacement={values.placement}
                                setFieldValue={setFieldValue}
                            />
                            <SizePresetsFormik
                                valueWidth={values.width}
                                valueHeight={values.height}
                                valueUnit={values.unit}
                                placementId={values.placement?.id || 0}
                                setFieldValue={setFieldValue}
                            />
                            <SizesFormik
                                valueWidth={values.width}
                                valueHeight={values.height}
                                valueUnit={values.unit}
                                setFieldValue={setFieldValue}
                            />
                            <OrientationsFormik
                                value={values.orientation}
                                placementId={values.placement?.id || 0}
                                error={errors.orientation}
                                classes={['bf-size-radio']}
                                onChangeValue={val => setFieldValue('orientation', val)}
                                onChangeError={err => setFieldError('orientation', err)}
                            />
                            <AdaptationField
                                value={values.adaptation}
                                setFieldValue={setFieldValue}
                            />

                            <RequiredSwitch
                                value={values.isRequired}
                                setFieldValue={setFieldValue}
                            />

                            <RequiredDatesField
                                show={values.isRequired}
                                dateFrom={values.requiredFrom || ''}
                                dateTo={values.requiredTo || ''}
                                errorDateTo={errors.requiredTo || ''}
                                errorDateFrom={errors.requiredFrom || ''}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                            />

                            <div className="bf-create__controls">
                                <ResetButton
                                    resetForm={resetForm}
                                />
                                <SubmitButton
                                    uploadStatus={upload.status}
                                    submitForm={submitForm}
                                />
                            </div>
                        </div>

                        <div className="bf-create__img-field">
                            <div className="bf-create__img-block">
                                <PreviewInputFormik
                                    dragRect={dragRect}
                                    value={values.preview}
                                    error={errors.preview}
                                    onChangeValue={val => setFieldValue('preview', val)}
                                    onChangeError={err => setFieldError('preview', err)}
                                />
                            </div>

                            <div className="bf-create__files">
                                <MultipleImgFilesFormik
                                    title="Дополнительные превью"
                                    value={values.otherPreviews}
                                    error={errors.otherPreviews}
                                    onChangeFiles={val => setFieldValue('otherPreviews', val)}
                                    onChangeError={err => setFieldError('otherPreviews', err)}
                                />
                                <MultipleFilesFormik
                                    title="Исходники"
                                    value={values.original}
                                    error={errors.original}
                                    onChangeFiles={val => setFieldValue('original', val)}
                                    onChangeError={err => setFieldError('original', err)}
                                />
                                <MultipleFilesFormik
                                    title="Итог на печать"
                                    value={values.prints}
                                    onChangeFiles={val => setFieldValue('prints', val)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <FadeModal
                isShow={upload.status !== null}
                closeHandler={() => { }}
                unmountAfterClose={true}
            >
                <NoteMessage upload={upload} />
            </FadeModal>
        </NavbarLayout >
    )
}
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import { updateUrlQueries } from '../../../../utils/other'
import { Select } from 'antd'
const { Option } = Select

interface PlacementFieldProps extends RouteComponentProps {
    catalogData: CatalogFieldsData
    setCatalogData: React.Dispatch<React.SetStateAction<CatalogFieldsData>>
    values: CatalogFilterFields
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const PlacementField: React.FC<PlacementFieldProps> = (props) => {

    const {
        catalogData, values,
        location, history,
        setFieldValue, setCatalogData,
    } = props

    const handleChange = (placementId: string | number | undefined) => {
        if (placementId) {
            const placement = catalogData.placements.find(p => p.id === +placementId)
            const isClear = +placementId === -1
            if (isClear || placement) {
                const newCatalogData = { ...catalogData }

                if (isClear) {
                    updateUrlQueries(location, history, {
                        'placement_id': null,
                        'zone_id': null,
                        'source_id': null
                    })
                    setFieldValue('placement', null)
                } else if (placement) {
                    updateUrlQueries(location, history, {
                        'placement_id': placementId + '',
                        'zone_id': null,
                        'source_id': null
                    })
                    setFieldValue('placement', isClear ? null : placement)
                }

                if (values.zones.length) setFieldValue('zones', [])
                if (values.source) setFieldValue('source', null)
                newCatalogData.zones = []
                newCatalogData.sources = []
                setCatalogData(newCatalogData)
            }
        }
    }

    return (
        <InputSelect
            title="Размещение"
            placeholder="Все размещения"
            value={values.placement?.name || null}
            wrapClasses={['catalog-location-select']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.placements.map(p => {
                        return (
                            <Option value={p.id + ''} key={p.id + ''}>{p.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
}

export default withRouter(PlacementField)
import React from "react";
import {Menu} from 'antd';
import {menuItems} from "./constants";
import {useDispatch, useSelector} from "react-redux";
import {appActions} from "../../../../store/actions/AppActions";
import {IRootState} from "../../../../store/reducers/rootReducer";

const MenuManage = () => {
    const dispatch = useDispatch();
    const menuKey = useSelector<IRootState>(state => state.app.menuKey) as string

    return (
        <Menu mode="inline" defaultSelectedKeys={[menuKey]} onSelect={(e) => dispatch(appActions.setMenuKey(String(e.key)))}>
            {menuItems.map((item) => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}
        </Menu>
    )
}
export default MenuManage
// app
export const APP_SET_USER_INFO = 'APP_SET_USER_INFO'
export const APP_LOGOUT_USER = 'APP_LOGOUT_USER'
export const APP_SET_CATALOGS = 'APP_SET_CATALOGS'
export const APP_MENU_KEY = 'APP_MENU_KEY'

// catalog
export const CATALOG_FETCH_CITIES = 'CATALOG_FETCH_CITIES'
export const CATALOG_FETCH_CATEGORIES = 'CATALOG_FETCH_CATEGORIES'

// create design
export const CREATE_SET_NOTE_MODAL = 'CREATE_SET_NOTE_MODAL'
export const CREATE_RESET_NOTE_MODAL = 'CREATE_RESET_NOTE_MODAL'
export const CREATE_RESET_FORM = 'CREATE_RESET_FORM'

export const CREATE_FETCH_CITIES = 'CREATE_FETCH_CITIES'
export const CREATE_FETCH_CLUBS = 'CREATE_FETCH_CLUBS'
export const CREATE_FETCH_CATALOGS = 'CREATE_FETCH_CATALOGS'

export const CREATE_SET_FIELD = 'CREATE_SET_FIELD'

export const CREATE_SET_CHIPS = 'CREATE_SET_CHIPS'

//create general design form fields
export const CREATE_GEN_DESIGN_SET_NOTE_MODAL = 'CREATE_GEN_DESIGN_SET_NOTE_MODAL'
export const CREATE_GEN_DESIGN_RESET_NOTE_MODAL = 'CREATE_GEN_DESIGN_RESET_NOTE_MODAL'
export const CREATE_GEN_DESIGN_RESET_FORM = 'CREATE_GEN_DESIGN_RESET_FORM'
export const CREATE_GEN_DESIGN_FETCH_CITIES = 'CREATE_GEN_DESIGN_FETCH_CITIES'
export const CREATE_GEN_DESIGN_FETCH_CLUBS = 'CREATE_GEN_DESIGN_FETCH_CLUBS'
export const CREATE_GEN_DESIGN_FETCH_CATALOGS = 'CREATE_GEN_DESIGN_FETCH_CATALOGS'

export const CREATE_GEN_DESIGN_SET_FIELD = 'CREATE_GEN_DESIGN_SET_FIELD'

// auth
export const AUTH_SET_VALID_STATUS = 'AUTH_SET_VALID_STATUS'
export const AUTH_SET_LOADING_STATUS = 'AUTH_SET_LOADING_STATUS'
export const AUTH_SET_ERROR_FORM_MESSAGE = 'AUTH_SET_ERROR_FORM_MESSAGE'
export const AUTH_SET_ERROR_EMAIL_MESSAGE = 'AUTH_SET_ERROR_EMAIL_MESSAGE'
export const AUTH_SET_ERROR_PASSWORD_MESSAGE = 'AUTH_SET_ERROR_PASSWORD_MESSAGE'
import React from 'react'
import { useDispatch } from 'react-redux'
import AuthForm from '../../Components/auth/AuthForm/AuthForm'
import { sendFormData } from '../../store/actions/AuthActions'
import './Auth.scss'

const Auth: React.FC<{}> = () => {

    const dispatch = useDispatch()

    const submitHandler = (email: string, password: string): void => {
        dispatch(sendFormData(email, password))
    }

    return (
        <section className="auth-form d-flex">
            <div className="container d-flex justify-content-center align-items-center">
                <AuthForm
                    onSubmit={submitHandler}
                />
            </div>
        </section>
    )
}

export default Auth


import React from 'react'
import { Link } from 'react-router-dom'
import { xxCatalog } from '../../../types/responseTypes'

type LinkGroupProps = {
    firstLvl: xxCatalog
    secondLvl: xxCatalog
    classes?: string[]
    onClick?: () => void
}

const LinkGroup: React.FC<LinkGroupProps> = ({ firstLvl, secondLvl, classes, onClick }) => {

    let linkGroupClasses = ['bf-navbar__links-group']

    if (classes) {
        linkGroupClasses = linkGroupClasses.concat(classes)
    }

    return (
        <div className={linkGroupClasses.join(' ')} >
            <p
                // to={`/prototype/catalog/?placement_id=${firstLvl.id}&zone_id=${secondLvl.id}`}
                className="bf-navbar__links-group-title"
            >
                {secondLvl.name}
            </p>
            <ul>
                {
                    secondLvl.children
                        ? secondLvl.children.map(thirdLvl =>
                            <li key={thirdLvl.id}>
                                <Link
                                    to={`/prototype/catalog/?placement_id=${firstLvl.id}&zone_id=${secondLvl.id}&source_id=${thirdLvl.id}`}
                                    onClick={onClick}
                                >
                                    {thirdLvl.name}
                                </Link>
                            </li>
                        )
                        : null
                }
            </ul>
        </div>
    )
}

export default LinkGroup
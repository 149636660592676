import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { Select } from 'antd'
import { CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import { RouteComponentProps, withRouter } from 'react-router'
import { updateUrlQueries } from '../../../../utils/other'
const { Option } = Select

interface ZonesFieldProps extends RouteComponentProps {
    catalogData: CatalogFieldsData
    values: CatalogFilterFields
    loading: boolean
    setCatalogData: React.Dispatch<React.SetStateAction<CatalogFieldsData>>
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const ZonesField: React.FC<ZonesFieldProps> = React.memo((props) => {

    const {
        catalogData, values,
        location, history,
        loading,
        setFieldValue, setCatalogData,
    } = props

    const handleChange = (zoneIds: string | number | undefined) => {
        if (typeof zoneIds === 'string') {
            const newCatalogData = { ...catalogData }
            const arrayIds = zoneIds.split(',')

            if (arrayIds.includes('-1') || zoneIds === '') {
                updateUrlQueries(location, history, { 'zone_id': null })
                setFieldValue('zones', [])
                newCatalogData.zones = []
            } else {
                const checkedZones = values.zones.filter(z => arrayIds.includes(z.id + ''))
                updateUrlQueries(location, history, { 'zone_id': zoneIds })
                setFieldValue('zones', checkedZones)
                // newCatalogData.zones = values.source?.children || []
            }
            setCatalogData(newCatalogData)
        }
    }

    return (
        <InputSelect
            title="Зоны"
            placeholder="Выберите зону"
            value={values.zones.map(z => z.id + '')}
            disabled={!values.zones}
            wrapClasses={['catalog-location-select']}
            multiple={true}
            loading={loading}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.zones?.map(z => {
                        return (
                            <Option value={z.id + ''} key={z.id}>{z.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default withRouter(ZonesField)
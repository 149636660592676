import React, { useEffect, useRef, useState } from 'react'
import './LoadFileField.scss'
import { DropboxOutlined } from '@ant-design/icons';
import { useDnd } from '../../utils/hooks/useDnd';
import { getExtensiosString } from '../../utils/parsers';

type LoadFileFieldProps = {
    validExtensions: string[]
    readonly fileContainer: File | null
    validMaxSizes?: { width: number, height: number }
    inputName?: string
    errorMessage?: string
    wrapperClasses?: string[]
    dragRect?: React.RefObject<HTMLElement>
    previewFile?: File | null
    previewSrc?: string
    onChangeFile: (file: File | null) => void
}

const LoadFileField: React.FC<LoadFileFieldProps> = (props) => {

    const {
        validExtensions, fileContainer,
        validMaxSizes, inputName = 'file',
        errorMessage, wrapperClasses,
        dragRect, previewSrc, previewFile,
        onChangeFile,
    } = props

    const btnText = errorMessage ? errorMessage : 'Загрузить превью'

    const [previewImg, setPreviewImg] = useState<HTMLImageElement | null>(null)
    const [isShowLoader, setShowLoader] = useState<boolean>(false)
    const [errorFileMessage, setErrorFileMessage] = useState<string>('')

    const dragArea = useRef<HTMLDivElement>(null)

    const fileToImage = (file: File) => {
        if (!validateFile(file)) return null

        const reader = new FileReader()
        reader.readAsDataURL(file)
        setShowLoader(true)
        reader.onloadend = () => {
            const result = reader.result as string
            if (result) {
                const img = new Image()
                img.src = result

                img.onload = () => {
                    if (validateImageSize(img)) {
                        setPreviewImg(img)
                        onChangeFile(file)
                        setShowLoader(false)
                        return
                    }
                    setErrorFileMessage(`
                                Максимальный размер файла ${validMaxSizes?.width} x ${validMaxSizes?.height}.
                                Вы загрузили файл с размером ${img.width} x ${img.height}
                            `)
                    setPreviewImg(null)
                    onChangeFile(null)
                    setShowLoader(false)
                }

                img.onerror = (e) => {
                    console.log(e)
                    setPreviewImg(null)
                    onChangeFile(null)
                    setShowLoader(false)
                    setErrorFileMessage('Ошибка загрузки файла')
                }
            }
        }
        reader.onerror = (e) => {
            console.log(e)
            setShowLoader(false)
        }
    }

    useDnd({
        dragArea,
        dragRect,
        validFormats: [],
        onChangeFiles: (files) => fileToImage(files[0]),
    }, [fileToImage])

    const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement> | File) => {
        let files: FileList | File[] | null = []
        files = e instanceof File ? [e] : e.target.files

        if (files && files.length > 0) {
            const file = files[0]
            fileToImage(file)
        }
    }

    const validateFile = (file: File): boolean => {
        const fileExtension = file.name.split('.').splice(-1).join('').toLowerCase()
        const isValidFileName = validExtensions.indexOf(fileExtension) !== -1

        if (!isValidFileName) {
            setErrorFileMessage(`
                Вы выбрали файл с расширением ${fileExtension.toUpperCase()}.
                Разрешенные форматы: ${getExtensiosString({validExtensions})}
            `)
            if (previewImg) setPreviewImg(null)
            if (fileContainer) onChangeFile(null)
            if (onChangeFile) onChangeFile(null)
            return false
        } else if (isValidFileName && errorFileMessage) {
            setErrorFileMessage('')
        }
        return true
    }

    const validateImageSize = (img: HTMLImageElement): boolean => {
        if (validMaxSizes) {
            const validMaxWidth = validMaxSizes.width >= img.width
            const validMaxHeight = validMaxSizes.height >= img.height
            return validMaxWidth && validMaxHeight
        }
        return true
    }

    // Priview src (for edit modals)
    const previewRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        let isUnmount = false
        if (previewSrc && !fileContainer) {
            // if this is edit modal and we need to show default preview (the fileContainer is empty)
            setShowLoader(true)
            const img = new Image()
            img.src = previewSrc
            setPreviewImg(img)
            if (previewRef.current) {
                previewRef.current.onload = () => {
                    if (!isUnmount) setShowLoader(false)
                }
                previewRef.current.onerror = (e) => {
                    if (!isUnmount) {
                        setShowLoader(false)
                        setErrorFileMessage('Ошибка загрузки из источника')
                    }
                }
            }
        }
        return () => { isUnmount = true }
    }, [fileContainer, previewSrc])
    //

    useEffect(() => {
        if (previewFile) fileToImage(previewFile)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const btnClasses = ['drag-area__btn-load', 'bf-btn', 'bf-btn-primary']
    if (errorMessage) btnClasses.push('error')

    let wrapClasses = ['drag-area']
    if (previewImg) wrapClasses.push('loaded')
    if (wrapperClasses) wrapClasses = wrapClasses.concat(wrapperClasses)

    const hasFile = previewImg && fileContainer
    const hasPreviewSrc = previewSrc && !errorFileMessage

    if (hasFile || hasPreviewSrc) {
        return (
            <div className={wrapClasses.join(' ')} ref={dragArea}>
                <div className="drag-area__preview">
                    <img
                        src={fileContainer ? previewImg?.src : previewSrc}
                        alt={fileContainer?.name || 'preview'}
                        ref={previewRef}
                    />
                </div>

                <div className="drag-area__footer">
                    <label className="drag-area__btn-reload bf-btn bf-btn-primary">
                        Загрузить превью
                        <input
                            type="file"
                            className="input-hidden"
                            name={inputName}
                            onChange={fileChangeHandler}
                        />
                    </label>
                </div>
                {
                    isShowLoader
                        ? <div className="drag-area__loader"> Загрузка... </div>
                        : null
                }
                <div className="drag-placeholder">
                    <DropboxOutlined className="drag-placeholder__icon rotate-animate" />
                    <p className="drag-placeholder__title">Перетащите сюда файл для загрузки</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className={wrapClasses.join(' ')} ref={dragArea}>
                {
                    errorFileMessage
                        ? <p className="drag-area__error-message">{errorFileMessage}</p>
                        : <div className="drag-area__title">
                            <p>Форматы файла: {getExtensiosString({validExtensions})}</p>
                            {
                                validMaxSizes ?
                                    <p>Не больше {validMaxSizes.width}px x {validMaxSizes.height}px </p>
                                    : null
                            }
                        </div>
                }
                <label className={btnClasses.join(' ')}>
                    {btnText}
                    <input
                        type="file"
                        className="input-hidden"
                        name={inputName}
                        onChange={fileChangeHandler}
                    />
                </label>
                <p className="drag-area__info-message">Файл можно добавить перетаскиванием</p>
                {
                    isShowLoader
                        ? <div className="drag-area__loader"> Загрузка... </div>
                        : null
                }
                <div className="drag-placeholder">
                    <DropboxOutlined className="drag-placeholder__icon rotate-animate" />
                    <p className="drag-placeholder__title">Перетащите сюда файл для загрузки</p>
                </div>
            </div>
        )
    }
}

export default LoadFileField
import { masks } from "../Data/createData"

interface GetExtensiosStringParams {
    separator?: string
    upperCase?: boolean
    validExtensions?: string[]
}

export const getExtensiosString = ({
    separator = ', ',
    upperCase = true,
    validExtensions = [],
}: GetExtensiosStringParams) => {
    return upperCase
        ? validExtensions.map(s => s.toUpperCase()).join(separator)
        : validExtensions.join(separator)
}

export const fileId = (file: File) => {
    return `${file.name}_${file.type}_${file.size}_${file.lastModified}`
}

export const fileImgPreview = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
        reader.onerror = (e) => {
            console.log(e)
            reject(e)
        }
    })
}

export const separateRequiredDate = (options: {
    value: string
    separator?: string
}) => {
    const {
        value,
        separator = '-',
    } = options

    const year = value.slice(0, 4)
    const month = value.slice(4, 6)
    const day = value.slice(6, 8)

    return [year, month, day].join(separator)
}

export const parseRequiredDate = (rowVal: string) => {
    const value = rowVal.replace(masks.onlyNumbers, '')
    const year = value.slice(0, 4)
    const month = value.slice(4, 6)
    const day = value.slice(6, 8)

    return {
        year: +year || null,
        month: +month || null,
        day: +day || null
    }
}

export const clearReqDate = (val: string | null) => {
    if (val === null) return null
    return val.replace(masks.onlyNumbers, '')
}
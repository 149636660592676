import React, {useCallback, useState} from "react";
import {ReturnUpdateTagSuccess, xxTags} from "../../../../../../../../types/responseTypes";
import TagItem from "../TagItem/TagItem";
import {freshAccessToken} from "../../../../../../../../utils/cookie/cookie";
import {deleteTagUrl, tokenType, updateTagUrl} from "../../../../../../../../config";
import {createFormData} from "../../../../../../../../utils/other";
import axios from "axios";
import {notification} from "antd";

type TagContainerProps = {
    tags: xxTags[],
    setCurrentTags: (tags: xxTags[]) => void
}

const TagContainer: React.FC<TagContainerProps> = ({tags, setCurrentTags}) => {

    const [isFocusTag, setIsFocusTag] = useState<number>(0);

    const deleteTag = useCallback((id: number) => async () => {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm('Вы уверены, что хотите удалить тег? В этом случае все связи тега с макетами будут удалены')){
            return
        }
        try {
            const accessToken = await freshAccessToken()
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }

            const resp = await axios.post(`${deleteTagUrl}/${id}`, options)
            const data = resp.data as ReturnUpdateTagSuccess

            if (data.success) {
                setIsFocusTag(0)
                setCurrentTags(data.data);
                notification.success({
                    message: 'Успешное удаление тега'
                })
            } else {
                notification.error({
                    message: 'Ошибка удаления! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка удаления! '+ e.message
            })
        }
    }, [setCurrentTags]);

    const updateTag = useCallback((id: number) => async (name: string) => {
        if(!name) return
        try {
            const accessToken = await freshAccessToken()
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }
            const dataForm = createFormData({name})

            const resp = await axios.post(`${updateTagUrl}/${id}`, dataForm, options)
            const data = resp.data as ReturnUpdateTagSuccess

            if (data.success) {
                setIsFocusTag(0)
                setCurrentTags(data.data);
                notification.success({
                    message: 'Успешное обновление тега'
                })
            } else {
                notification.error({
                    message: 'Ошибка обновления! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка обновления! '+ e.message
            })
        }
    }, [setCurrentTags]);

    return(<div style={{marginTop: 15}}>
        {tags.map((tag) => {
            return (<TagItem key={tag.id} tag={tag}
                             isEdit={isFocusTag === tag.id}
                             onDelete={deleteTag(tag.id)}
                             onSave={updateTag(tag.id)}
                             onClick={() => setIsFocusTag(tag.id)}/>)
        })}
    </div>)
}

export default TagContainer
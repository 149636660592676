import React, {useEffect, useRef, useState} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import NavbarLayout from '../../Components/Layout/NavbarLayout/NavbarLayout'
import './CatalogPage.scss'
import {CatalogContent, LayoutPreview, xxCatalog, xxCity, xxClub, xxTags} from '../../types/responseTypes'
import {freshAccessToken, getCookie} from '../../utils/cookie/cookie'
import axios, {Canceler, CancelTokenSource} from 'axios'
import {CatalogFieldsData, CatalogFilterFields} from '../../types/types'
import {openNotification, updateUrlQueries} from '../../utils/other'
import {AdaptationField, CityField, ClubField, PlacementField, SourceField, ZonesField,} from './FilterFields'
import DesignsField from './DesignsField/DesignsField'
import {cityCookieName, clubCookieName, getContentUrl, tokenType} from '../../config'
import {Spin} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import useScrollToTop from '../../utils/hooks/useScrollToTop'
import {useFormik} from 'formik'
import usePaginator from '../../utils/hooks/usePaginator'
import ZoomModal from '../../Components/Modals/ZoomModal/ZoomModal'
import {useZoomModal} from '../../utils/hooks/useZoomModal'
import RequiredNowField from './FilterFields/RequiredNowField/RequiredNowField'
import RequiredFutureField from './FilterFields/RequiredFutureField/RequiredFutureField'
import SearchInput from "./FilterFields/SearchInput/SearchInput";
import TagsField from "./FilterFields/TagsField/TagsField";

const maxItemsOnPage = 12

const CancelToken = axios.CancelToken;

interface CatalogPageProps extends RouteComponentProps<{}> { }

const CatalogPage: React.FC<CatalogPageProps> = ({ history, location }) => {

    const cardsContentainer = useRef<HTMLDivElement>(null)
    const [laytoutsPreview, setLaytoutsPreview] = useState<LayoutPreview[]>([])

    const paginator = usePaginator<LayoutPreview[]>({
        refContainer: cardsContentainer,
        items: laytoutsPreview,
        fetchMoreCallback: handlePaginatorFetch,
    })

    const zoom = useZoomModal()

    const [catalogData, setCatalogData] = useState<CatalogFieldsData>({
        cities: [],
        clubs: [],
        placements: [],
        zones: [],
        sources: [],
        tags: []
    })

    const [loadingFilters, setLoadingFilters] = useState<boolean>(false)
    const [loadingLayouts, setLoadingLayouts] = useState<boolean>(false)
    const [adapter, setAdapter] = useState<CancelTokenSource>();

    const { values, setFieldValue, setValues } = useFormik<CatalogFilterFields>({
        initialValues: {
            search: '',
            city: null,
            club: null,
            placement: null,
            source: null,
            zones: [],
            tags: [],
            isAdaptation: false,
            orientation: null,
            isRequiredNow: false,
            isRequiredFuture: false,
        },
        onSubmit() { }
    })

    const idsCheckedZones = () => values.zones.map(z => z.id + '') || []

    const setFormFromQueries = (data: CatalogContent) => {
        const _quiery = new URLSearchParams(location.search)
        const tags = data.filters.find(f => f.request_key === 'tag_id')?.items as xxTags[] | undefined
        const search = data.filters.find(f => f.request_key === 'search')?.value as string | undefined
        const citiesResp = data.filters.find(f => f.request_key === 'city_id')?.items as xxCity[] | undefined
        const clubsResp = data.filters.find(f => f.request_key === 'club_id')?.items as xxClub[] | undefined
        const placementsResp = data.filters.find(f => f.request_key === 'placement_id')?.items as xxCatalog[] | undefined
        const zonesResp = data.filters.find(f => f.request_key === 'zone_id')?.items as xxCatalog[] | undefined
        const sourcesResp = data.filters.find(f => f.request_key === 'source_id')?.items as xxCatalog[] | undefined
        const isAdaptiveResp = data.filters.find(f => f.request_key === 'is_adaptive')?.value as boolean
        const isRequiredNowResp = data.filters.find(f => f.request_key === 'required_now')?.value as boolean
        const isRequiredFutureResp = data.filters.find(f => f.request_key === 'required_future')?.value as boolean

        const newCatalogData = { ...catalogData }

        if(search){
            setFieldValue('search', search);
        }
        if (citiesResp) {
            const queryCity = citiesResp.find(c => c.id + '' === _quiery.get('city_id'))
            newCatalogData.cities = citiesResp.sort((a, b) => a.name.localeCompare(b.name))
            setFieldValue('city', queryCity || null)
        }
        if (clubsResp) {
            const queryClub = clubsResp.find(c => c.id + '' === _quiery.get('club_id'))
            newCatalogData.clubs = clubsResp.sort((a, b) => a.name.localeCompare(b.name))
            setFieldValue('club', queryClub || null)
        }
        if (placementsResp) {
            const queryPlacement = placementsResp.find(p => p.id + '' === _quiery.get('placement_id'))
            const isSamePlacement = values.placement?.id === queryPlacement?.id
            newCatalogData.placements = placementsResp.sort((a, b) => a.name.localeCompare(b.name))
            if (!isSamePlacement) setFieldValue('placement', queryPlacement || null)
        }
        if (sourcesResp) {
            const querySource = sourcesResp.find(s => s.id + '' === _quiery.get('source_id'))
            const isSameSource = values.source?.id === querySource?.id
            newCatalogData.sources = sourcesResp.sort((a, b) => a.name.localeCompare(b.name))
            if (!isSameSource) setFieldValue('source', querySource || null)
        }

        if(tags){
            newCatalogData.tags = tags;
            const idsTags = _quiery.get('tag_id')?.split(',')
            const queryCheckedTags = tags
                .filter(z => idsTags?.includes(z.id + ''))
            setFieldValue('tags', queryCheckedTags || [])
        }

        if (zonesResp) {
            const idsZones = _quiery.get('zone_id')?.split(',')
            const queryCheckedZones = zonesResp
                .filter(z => idsZones?.includes(z.id + ''))
                .sort((a, b) => a.name.localeCompare(b.name))

            const isSameCheckedSources = idsCheckedZones()
                .sort()
                .join(',') === idsZones?.sort()
                    .join(',')
            newCatalogData.zones = zonesResp
            if (!isSameCheckedSources) setFieldValue('zones', queryCheckedZones || [])
        } else if (!zonesResp && values.zones.length) {
            setFieldValue('zones', [])
        }
        setCatalogData(newCatalogData)
        setFieldValue('isAdaptation', !!isAdaptiveResp)
        setFieldValue('isRequiredNow', !!isRequiredNowResp)
        setFieldValue('isRequiredFuture', !!isRequiredFutureResp)
        setLaytoutsPreview(data.data)
        setLoadingLayouts(false)
    }

    async function fetchLayouts(page: number) {
        try {
            console.log("content", adapter)
            if(adapter){
                console.log("content is", adapter)
                adapter.cancel()
            }
            const cancel = CancelToken.source();
            setAdapter(cancel);

            const token = await freshAccessToken()
            const paginatorQueries = `page=${page}&limit=${maxItemsOnPage}`
            const queries = location.search
                ? `${location.search}&${paginatorQueries}`
                : `?${paginatorQueries}`
            const url = `${getContentUrl}${queries}`
            const options = { headers: { 'Authorization': `${tokenType} ${token}` },
                cancelToken: cancel.token }
            const res = await axios.get(url, options)
            console.log('RES CATALOG!', res)
            setAdapter(undefined)
            return res.data as CatalogContent
        } catch (e) {
            console.log(e)
            if(!axios.isCancel(e)) {
                const errorDescription = e.message.includes('429')
                    ? 'Error: 429, превышен лимит запросов, попробуйте позже'
                    : e.message
                openNotification('Ошибка запроса', 'error', errorDescription)
            }
        }
    }

    async function handlePaginatorFetch(nextPage: number) {
        try {
            const data = await fetchLayouts(nextPage)
            if (data) {
                setLaytoutsPreview([...laytoutsPreview, ...data.data])
                setLoadingLayouts(false)
                return { status: 'success' }
            }
            setLoadingLayouts(false)
            return { status: 'error' }
        } catch (e) {
            console.log('cc', e)
            if(!axios.isCancel(e)){
                setLoadingLayouts(false)
            }
            return { status: 'error' }
        }
    }

    useEffect(() => {
        let stopUpdateLayouts = false

        const _queries = new URLSearchParams(location.search)
        const query_city_id = _queries.get('city_id')
        const query_club_id = _queries.get('club_id')
        const cookie_city_id = getCookie(cityCookieName)
        const cookie_club_id = getCookie(clubCookieName)

        async function updateLayouts() {
            try {
                setLoadingFilters(true)
                setLoadingLayouts(true)
                setLaytoutsPreview([])
                paginator.setCurrentPage(1)
                const data = await fetchLayouts(1)
                if (data) {
                    const maxPage = data?.paginator?.maxPage
                    if (paginator.maxPage !== maxPage) paginator.setMaxPage(maxPage || null)
                    if (paginator.isFetching) paginator.setFetching(false)
                    setFormFromQueries(data)
                    setLoadingFilters(false)
                    setLoadingLayouts(false)
                }
            } catch (e) {
                console.log('cont', e)
                if(!axios.isCancel(e)){
                    setLoadingFilters(false)
                    setLoadingLayouts(false)
                }
            }
        }

        if (!query_city_id && !query_club_id) {
            if (cookie_city_id || cookie_club_id) {
                stopUpdateLayouts = true
                updateUrlQueries(location, history, {
                    'city_id': cookie_city_id || null,
                    'club_id': cookie_club_id || null
                })
            }
        }
        if (!stopUpdateLayouts) updateLayouts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location])

    useScrollToTop()

    return (
        <NavbarLayout>
            <section className="bf-filters">
                <div className="bf-filters__body">
                    <form className="bf-filters__form">

                        <SearchInput
                            value={values.search}
                            setFieldValue={setFieldValue}
                        />

                        <TagsField
                            catalogData={catalogData}
                            values={values}
                            setCatalogData={setCatalogData}
                            setFieldValue={setFieldValue}
                        />

                        <CityField
                            catalogData={catalogData}
                            setCatalogData={setCatalogData}
                            values={values}
                            setFieldValue={setFieldValue}
                        />

                        <ClubField
                            catalogData={catalogData}
                            values={values}
                            setFieldValue={setFieldValue}
                        />

                        <PlacementField
                            catalogData={catalogData}
                            setCatalogData={setCatalogData}
                            values={values}
                            setFieldValue={setFieldValue}
                        />

                        <SourceField
                            catalogData={catalogData}
                            values={values}
                            isLoadingSources={loadingFilters}
                            setCatalogData={setCatalogData}
                            setFieldValue={setFieldValue}
                        />

                        <ZonesField
                            catalogData={catalogData}
                            values={values}
                            loading={loadingFilters}
                            setCatalogData={setCatalogData}
                            setFieldValue={setFieldValue}
                        />

                        {/* <SwitchCheckbox
                            text={'Актуален'}
                            classes={['catalog-actual']}
                            checked={true}
                            disabled={true}
                        /> */}

                        <AdaptationField
                            values={values}
                            setFieldValue={setFieldValue}
                        />

                        <RequiredNowField
                            values={values}
                            setValues={setValues}
                        />

                        <RequiredFutureField
                            values={values}
                            setValues={setValues}
                        />
                    </form>
                </div>
            </section>

            <section className="bf-designs">
                <div className="bf-container">
                    <div ref={cardsContentainer} className="bf-designs__content">
                        <DesignsField
                            layouts={laytoutsPreview}
                            isLoading={loadingLayouts}
                            onZoomImg={zoom.open}
                        />
                        {
                            paginator.isFetching
                                ? <div className="bf-designs__paginator-loader">
                                    <Spin indicator={<LoadingOutlined spin />} />
                                    <p>Загрузка макетов</p>
                                </div>
                                : null
                        }
                        {
                            paginator.currentPage === paginator.maxPage && laytoutsPreview.length
                                ? <p className="bf-designs__ends-text">Все макеты загружены</p>
                                : null
                        }
                    </div>
                </div>
            </section>

            <ZoomModal {...zoom} />
        </NavbarLayout >
    )
}

export default CatalogPage
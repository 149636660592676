import React, { useEffect, useState } from 'react'
import NavbarLayout from '../../Components/Layout/NavbarLayout/NavbarLayout'
import SlotProvider from '../../Components/Slot/SlotProvider/SlotProvider'
import DesignCardFull from '../../Components/DesignCardFull/DesignCardFull'
import './DesignPageHistory.scss'
import { unlockScrollBody } from '../../utils/styleFunctions/styleFunctions'
import Specifications from '../../Components/Specifications/Specifications'
import { LayoutVersionsData, SpecData, Version, LastAdaptive } from '../../types/responseTypes'
import ModalLoadLayout from '../../Components/Modals/ModalLoadLayout/ModalLoadLayout'
import { RouteComponentProps } from 'react-router-dom'
import { getLayoutByIdUrl, tokenType, insertVersionUrl } from '../../config'
import { freshAccessToken } from '../../utils/cookie/cookie'
import axios from 'axios'
import { parseDate, parseSpecParam } from '../../utils/other'
import useScrollToTop from '../../utils/hooks/useScrollToTop'
import { useZoomModal } from '../../utils/hooks/useZoomModal'
import ZoomModal from '../../Components/Modals/ZoomModal/ZoomModal'
import { NavLink } from 'react-router-dom'

interface DesignPageHistoryProps extends RouteComponentProps { }

type ParamsType = {
    id: number
}

const DesignPageHistory: React.FC<DesignPageHistoryProps> = ({ history, match }) => {

    const params = match.params as ParamsType

    const [currentVersionData, setCurrentVersionData] = useState<LayoutVersionsData | null>(null)
    const [versions, setVersions] = useState<LayoutVersionsData['versions']>([])
    const [currentSpec, setCurrentSpec] = useState<SpecData[]>([])
    const [placementId, setPlacementId] = useState<number>(0)
    const lastVersion = versions[0] as Version | undefined

    const [isShowModal, setShowModal] = useState<boolean>(false)
    const zoom = useZoomModal()

    const updatePage = () => { history.replace(history.location) }

    const closeModalAdaptation = () => {
        setShowModal(false)
        unlockScrollBody()
    }

    const getTime = (timeString: string) => {
        return parseDate(new Date(timeString))
    }

    const getSpec = (adaptive: LastAdaptive) => {
        const unit = parseSpecParam(adaptive.sizes.unit)
        const spec = [
            {
                title: 'Ориентация',
                value: parseSpecParam(adaptive.orientation)
            }
        ]
        if (adaptive.sizes.width) spec.push({
            title: 'Ширина',
            value: `${adaptive.sizes.width} ${unit}`
        })
        if (adaptive.sizes.height) spec.push({
            title: 'Высота',
            value: `${adaptive.sizes.height} ${unit}`
        })
        return spec
    }

    useEffect(() => {
        let isUnmount = false
        async function fetchVersions() {
            try {
                const accessToken = await freshAccessToken()
                const url = `${getLayoutByIdUrl}/${params.id}/versions`
                const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }

                const resp = await axios.get(url, options)
                const data = resp.data.data as LayoutVersionsData

                console.log('VERSIONS resp', resp)
                if (!isUnmount) {
                    const lastAdaptive = data.versions[0].last_adaptive
                    setCurrentVersionData(data)
                    setVersions(data.versions)
                    setPlacementId(data.placement_id)
                    setCurrentSpec(getSpec(lastAdaptive))
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchVersions()
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    useScrollToTop()

    return (
        <NavbarLayout>
            <section className="bf-design-history">
                <div className="bf-container">
                    <div className="bf-design-history__content">

                        <div className="bf-design-history__current-version">
                            <DesignCardFull
                                otherPreviews={lastVersion?.last_adaptive.other_previews || []}
                                title={currentVersionData?.name || ''}
                                description={currentVersionData?.versions[0].last_adaptive.desc || ''}
                                imgSrc={lastVersion?.last_adaptive.preview || ''}
                                date={lastVersion ? getTime(lastVersion.date) : ''}
                                version={versions.length}
                                isCurrent={true}
                                onZoom={zoom.open}
                            >
                                <SlotProvider slot="info">
                                    <div className="bf-design-history__controls">
                                        <button
                                            className="bf-design-history__version-btn bf-btn bf-btn-primary"
                                            onClick={() => setShowModal(true)}
                                        >
                                            Новая версия
                                        </button>
                                    </div>
                                </SlotProvider>

                                <SlotProvider slot="specifications">
                                    <Specifications
                                        isShow={true}
                                        showTitle={true}
                                        data={currentSpec}
                                    />
                                </SlotProvider>

                            </DesignCardFull>
                        </div>

                        <div className="bf-design-history__old-versions">
                            <p className="bf-design-history__old-versions-title">Старые версии которые больше не используем</p>
                            {
                                versions.map((v, i) => {
                                    const _version = i > 0 ? null : i
                                    if (v.id + '' === lastVersion?.id + '') return null
                                    return (
                                        <NavLink to={`/prototype/catalog/${params.id}/version/${v.id}`}>
                                        <DesignCardFull
                                            otherPreviews={v?.last_adaptive.other_previews || []}
                                            key={i}
                                            title={currentVersionData?.name || ''}
                                            description={v.last_adaptive.desc || ''}
                                            imgSrc={v.last_adaptive.preview}
                                            date={getTime(v.date || '')}
                                            version={_version}
                                            classes={['bottom-offset']}
                                            onZoom={zoom.open}
                                        >
                                            <SlotProvider slot="specifications">
                                                <Specifications
                                                    isShow={true}
                                                    showTitle={true}
                                                    data={getSpec(v.last_adaptive)}
                                                />
                                            </SlotProvider>
                                        </DesignCardFull>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <ModalLoadLayout
                isShow={isShowModal}
                title='Добавить версию'
                uploadUrl={insertVersionUrl}
                layout_id={params.id}
                placement_id={placementId}
                closeHandler={closeModalAdaptation}
                afterSuccessUpload={updatePage}
            />
            <ZoomModal {...zoom} />
        </NavbarLayout>
    )
}

export default DesignPageHistory
import { useState } from 'react';
import { NoteUploadStatus } from '../../types/types';

function useUploadModal() {
    const [status, setStatus] = useState<NoteUploadStatus>(null)
    const [title, setTitle] = useState<string>('Загрузка')
    const [description, setDescription] = useState<string>('Пожалуйста подождите')
    const [totalSize, setTotalSize] = useState<number>(0)
    const [uploadedSize, setUploadedSize] = useState<number>(0)

    const handleChangeStatus = (title: string = '', description: string = '') => {
        setTitle(title)
        setDescription(description)
    }

    return {
        status,
        title,
        description,
        totalSize,
        uploadedSize,
        set: {
            status: setStatus,
            title: setTitle,
            description: setDescription,
            totalSize: setTotalSize,
            uploadedSize: setUploadedSize,
        },
        loading(title: string = '', description: string = '') {
            setStatus('loading')
            handleChangeStatus(title, description)
        },
        success(title: string = '', description: string = '') {
            setStatus('success')
            handleChangeStatus(title, description)
        },
        error(title: string = '', description: string = '') {
            setStatus('error')
            handleChangeStatus(title, description)
        },
        reset() {
            setStatus(null)
            setTitle('')
            setDescription('')
            setTotalSize(0)
            setUploadedSize(0)
        },
        hide: function () {
            setStatus(null)
        },
    }
}

export default useUploadModal
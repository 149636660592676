import { FormikErrors } from 'formik'
import React from 'react'
import RadioBtn from '../../../../../Components/UI/RadioBtn/RadioBtn'
import RadioBtnGroup from '../../../../../Components/UI/RadioBtnGroup/RadioBtnGroup'
import { customSizesCreate, orientationsCreate } from '../../../../../Data/createData'
import { ModalEditLayoutFields, FormikSetValues, InitialDataCreateLayout } from '../../../../../types/types'
import { customSizesByPlacement, orientationsByPlacement } from '../../../../../utils/formUtils'

type PlacementFieldProps = {
    initialData: InitialDataCreateLayout
    values: ModalEditLayoutFields
    errors: FormikErrors<ModalEditLayoutFields>
    setValues: FormikSetValues<ModalEditLayoutFields>
    setErrors: (errors: FormikErrors<ModalEditLayoutFields>) => void
}

const orientations = orientationsCreate()
const customSizes = customSizesCreate()

const PlacementField: React.FC<PlacementFieldProps> = ({ initialData, values, errors, setValues, setErrors }) => {

    const handleChange = (placementId: string) => {
        const newPlacement = initialData.catalogs.find(c => c.id + '' === placementId)
        if (newPlacement) {
            const firstSize = customSizesByPlacement(newPlacement, customSizes)[0]
            const firstOrientation = orientationsByPlacement(newPlacement, orientations)[0]

            let newValues = {
                ...values,
                placement: newPlacement,
                _isDecoration: false,
                layoutType: null,
                source: null,
                zones: [],
            } as ModalEditLayoutFields

            if (firstSize) {
                newValues = {
                    ...newValues,
                    width: firstSize.width,
                    height: firstSize.height,
                    unit: firstSize.unit
                }
            }

            if (firstOrientation) {
                newValues.orientation = firstOrientation.value
            }
            setErrors({
                ...errors,
                placement: '',
                _isDecoration: '',
                layoutType: '',
                source: '',
                zones: '',
                width: '',
                height: '',
                unit: '',
                orientation: ''
            })
            setValues(newValues)
        }
    }

    return (
        <RadioBtnGroup
            errorMessage={errors.placement}
            classes={['bf-edit-layout__placement']}
            errorClasses={['bf-edit-layout__placement-error']}
        >
            {
                initialData.catalogs.map(c => {
                    return (
                        <RadioBtn
                            labelText={c.name}
                            value={c.id}
                            checked={c.id === values.placement?.id}
                            name="placement"
                            key={c.id}
                            onChange={handleChange}
                        />
                    )
                })
            }
        </RadioBtnGroup>
    )
}

export default PlacementField
import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CreateLayoutFields, FormikSetFieldValue, InitialDataCreateLayout } from '../../../../types/types'
import { Select } from 'antd'
const { Option } = Select

type TagsFieldProps = {
    initialData: InitialDataCreateLayout
    value: CreateLayoutFields['tags']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const TagsField: React.FC<TagsFieldProps> = React.memo(({ initialData, value, setFieldValue }) => {


    const handleChange = (tagId: string | number | undefined) => {
        if (typeof tagId === 'string') {
            console.log(tagId);
            const arrayIds = tagId.split(',')
            if (arrayIds.includes('-1')) return setFieldValue('tags', [])
            const tags = initialData.tags.filter((tag) => arrayIds.includes(tag.id+''))
            setFieldValue('tags', tags)
        }
    }

    return (
        <InputSelect
            title="Теги"
            placeholder="Выберите теги"
            value={value.map(z => z.id + '')}
            wrapClasses={['tags-select']}
            multiple={true}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    initialData?.tags?.map(c => {
                        return (
                            <Option value={c.id + ''} key={c.id + ''}>{c.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default TagsField
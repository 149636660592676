import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CreateLayoutFields, FormikSetFieldValue, InitialDataCreateLayout } from '../../../../types/types'
import { Select } from 'antd'
const { Option } = Select

type ClubFieldProps = {
    initialData: InitialDataCreateLayout
    value: CreateLayoutFields['club']
    valueCity: CreateLayoutFields['city']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const ClubField: React.FC<ClubFieldProps> = React.memo(({ initialData, value, valueCity, setFieldValue }) => {

    const citiesByClub = () => initialData.clubs.filter(c => c.city_id === valueCity?.id)

    const handleChange = (clubId: string | number | undefined) => {
        if (clubId === '-1') return setFieldValue('club', null)

        const newClub = citiesByClub().find(c => c.id + '' === clubId + '')
        if (newClub) setFieldValue('club', newClub)
    }

    return (
        <InputSelect
            title="Клуб"
            placeholder="Все клубы"
            value={value?.name || null}
            wrapClasses={['create-select', 'right-offset']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    citiesByClub().map(club => {
                        return (
                            <Option value={club.id} key={club.id}>{club.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default ClubField
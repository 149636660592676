import {APP_LOGOUT_USER, APP_MENU_KEY} from './../actions/actionTypes';
import { InitialAppState } from "../../types/stateTypes"
import { APP_SET_CATALOGS, APP_SET_USER_INFO } from "../actions/actionTypes"
import { AllAppActions } from "../actions/AppActions"

const initialAppState: InitialAppState = {
    user: {
        isAuth: null,
        sub: '',
        email_verified: false,
        roles: [],
        name: '',
        preferred_username: '',
        given_name: '',
        family_name: '',
        email: '',
    },
    menuKey: '1',
    catalogs: null
}

const appReducer = (state = initialAppState, action: AllAppActions): InitialAppState => {
    switch(action.type) {
        case(APP_SET_USER_INFO): {
            return {
                ...initialAppState,
                user: {
                    ...state.user,
                    isAuth: true,
                    ...action.payload
                }
            }
        }
        case(APP_LOGOUT_USER): {
            return {
                ...state,
                user: {
                    ...initialAppState.user,
                    isAuth: false
                }
            }
        }
        case(APP_MENU_KEY): {
            return {
                ...state,
                menuKey: action.payload
            }
        }
        case(APP_SET_CATALOGS): {
            return {
                ...state,
                catalogs: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default appReducer

import React from 'react'
import { CreateLayoutFields, FormikResetForm } from '../../../../../types/types'

type ClearButtonProps = {
    resetForm: FormikResetForm<CreateLayoutFields>
}

const ClearButton: React.FC<ClearButtonProps> = React.memo(({ resetForm }) => {

    return (
        <button
            type="button"
            className="bf-btn bf-btn-gray"
            onClick={() => resetForm()}
        >
            Очистить форму
        </button>
    )
})

export default ClearButton
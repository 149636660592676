import React, { useState } from 'react'
import { JSXChildren } from '../../types/types'
import Slot from '../Slot/Slot'
import Version from '../Version/Version'
import './DesignCardFull.scss'
import { stringWithEnters } from '../../utils/other'
import DesignSlider from '../Sliders/DesignSlider/DesignSlider'
import SwiperCore from 'swiper';
import DesignSliderThumbs from '../Sliders/DesignSliderThumbs/DesignSliderThumbs'
import { FileInfo } from '../../types/responseTypes'

type DesignCardFullProps = {
    title: string
    imgSrc: string
    otherPreviews?: FileInfo[]
    description?: string
    date?: string
    classes?: string[]
    version?: number | null
    isCurrent?: boolean
    children?: JSXChildren
    showTitleSpec?: boolean
    showImgLoader?: boolean
    onZoom?: (imgSrc: string) => void
}

const DesignCardFull: React.FC<DesignCardFullProps> = (props) => {

    const {
        otherPreviews = [],
    } = props

    const [designSwiper, setDesignSwiper] = useState<SwiperCore | null>(null)
    const [activeSlideIdx, setActiveSlideIdx] = useState<number>(0)

    const previews: FileInfo[] = [
        {file_link: props.imgSrc, title: 'main preview'},
        ...otherPreviews,
    ]

    let cardClasses = ['version-card']

    if (props.classes) {
        cardClasses = cardClasses.concat(props.classes)
    }

    return (
        <div className={cardClasses.join(' ')}>
            <div className="version-card__img-block">

                <DesignSlider
                    designSwiper={designSwiper}
                    setDesignSwiper={setDesignSwiper}
                    images={previews}
                    onZoom={props.onZoom}
                    setActiveSlideIdx={setActiveSlideIdx}
                />
                <Slot children={props.children} name="belowImg" />
            </div>

            <div className="version-card__info">
                <p className="version-card__title">{props.title}</p>
                {
                    props.description &&
                    <p className="version-card__description">
                        {stringWithEnters(props.description)}
                    </p>
                }

                <Slot children={props.children} name="beforeSpecifications" />
                <Slot children={props.children} name="specifications" />

                <DesignSliderThumbs
                    images={previews}
                    activeSlideIdx={activeSlideIdx}
                    designSwiper={designSwiper}
                />

                <Version
                    version={props.version}
                    date={props.date}
                    isCurrent={props.isCurrent}
                />

                <Slot children={props.children} name="info" />
            </div>
        </div>
    )
}

export default DesignCardFull
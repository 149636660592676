import React from 'react'

type SubmitButtonProps = {
    uploadStatus: string | null
    submitForm: () => Promise<any>
}

const SubmitButton: React.FC<SubmitButtonProps> = React.memo(({ uploadStatus, submitForm }) => {

    return (
        <button
            type="submit"
            className="bf-btn bf-btn-primary bf-create__send-btn"
            disabled={uploadStatus === 'loading'}
            onClick={() => submitForm()}
        >
            Создать
        </button>
    )
})

export default SubmitButton
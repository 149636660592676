import React, { useEffect, useRef, useState } from 'react'
import { EditAdaptiveInfo, EditFileItem } from '../../../types/responseTypes'
import { UserRole } from '../../../types/stateTypes'
import UploadStatus from '../../UploadStatus/UploadStatus'
import FadeModal from '../FadeModal/FadeModal'
import './EditAdaptiveModal.scss'
import { getEditAdaptiveDataUrl, tokenType } from '../../../config'
import { freshAccessToken } from '../../../utils/cookie/cookie'
import axios from 'axios'
import { EditAdaptiveRequest } from '../../../types/requestTypes'
import { createFormData, openNotification, scrollTopSmooth } from '../../../utils/other'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../store/reducers/rootReducer'
import useUploadModal from '../../../utils/hooks/useUploadModal'
import { useFormik } from 'formik'
import { ModalAdaptiveFields } from '../../../types/types'
import { ResetButton, SubmitButton } from './Controls'
import TextAreaFormik from '../../formikFields/TextAreaFormik/TextAreaFormik'
import SizePresetsFormik from '../../formikFields/SizePresetsFormik/SizePresetsFormik'
import SizesFormik from '../../formikFields/SizesFormik/SizesFormik'
import OrientationsFormik from '../../formikFields/OrientationsFormik/OrientationsFormik'
import PreviewInputFormik from '../../formikFields/PreviewInputFormik/PreviewInputFormik'
import MultipleFilesFormik from '../../formikFields/MultipleFilesFormik/MultipleFilesFormik'
import MultipleImgFilesFormik from '../../formikFields/MultipleImgFilesFormik/MultipleFilesFormik'


type DefaultInfo = {
    descr: string
    previewSrc: string
    originalSrc: EditFileItem[]
    otherPreviews: EditFileItem[]
    printsSrc: EditFileItem[]
    width: string
    height: string
    unit: string
    orientation: string
}

type EditAdaptiveModalProps = {
    titleModal: string
    isShow: boolean
    adaptation_id: number
    placement_id: number
    closeHandler: () => void
    afterSuccessUpload?: () => void
}

const defaultData: DefaultInfo = {
    descr: '',
    previewSrc: '',
    originalSrc: [],
    printsSrc: [],
    width: '',
    height: '',
    unit: '',
    orientation: '',
    otherPreviews: [],
}


const EditAdaptiveModal: React.FC<EditAdaptiveModalProps> = React.memo((props) => {
    const {
        titleModal, isShow, adaptation_id,
        placement_id,
        afterSuccessUpload, closeHandler,
    } = props

    const dragRect = useRef<HTMLFormElement>(null)
    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const hasAccess = userRoles.includes('Admin') || userRoles.includes('Designer')

    const [defaultInfo, setDefaultInfo] = useState<DefaultInfo>(defaultData)

    const upload = useUploadModal()

    const validate = (values: ModalAdaptiveFields) => {
        const errors = {} as { [key: string]: string }
        const isValidOrientation = ['horizontal', 'vertical', 'square'].includes(values.orientation)

        if (!values.original.length && !values._changedOriginalSrc.length) {
            errors.original = 'Загрузите один или несколько файлов'
        }
        if (!values.preview && !defaultInfo.previewSrc) errors.preview = 'Загрузите превью'
        if (!isValidOrientation) errors.orientation = 'Укажите ориентацию'

        if (Object.keys(errors).length) {
            scrollTopSmooth('#edit-adaptive-modal')
            openNotification('Ошибка', 'error', 'Заполните обязательные поля')
        }
        return errors
    }

    const submitFormHandler = (values: ModalAdaptiveFields) => {
        const data: EditAdaptiveRequest = {}
        if (values.preview) data.preview = values.preview
        if (defaultInfo.width !== values.width) data.width = values.width
        if (defaultInfo.height !== values.height) data.height = values.height
        if (defaultInfo.unit !== values.unit) data.unit = values.unit
        if (defaultInfo.orientation !== values.orientation) data.orientation = values.orientation
        if (defaultInfo.descr !== values.description) data.description = values.description

        if (values.original.length) data.original = values.original
        if (values.otherPreviews.length) data.other_previews = values.otherPreviews
        if (values.prints.length) data.prints = values.prints
        data.originals_id = values._changedOriginalSrc.map(c => c.id)
        data.prints_id = values._changedPrintsSrc.map(c => c.id)
        data.other_previews_id = values._changedOtherPreviews.map(c => c.id)

        console.log('send data', data)
        const dataForm = createFormData(data)
        if (hasAccess) sendEdit(dataForm)
    }

    const {
        values, errors,
        setFieldError, setFieldValue, setValues,
        submitForm, setErrors
    } = useFormik<ModalAdaptiveFields>({
        initialValues: {
            description: '',
            width: '0',
            height: '0',
            unit: 'mm',
            orientation: '',
            preview: null,
            original: [],
            prints: [],
            otherPreviews: [],

            _changedOriginalSrc: [],
            _changedPrintsSrc: [],
            _changedOtherPreviews: [],
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate,
        onSubmit: submitFormHandler,
    })

    const hasBeenChangedAdaptive = () => {
        const isChangedDefaultOriginals = values._changedOriginalSrc.length !== defaultInfo.originalSrc.length
        const isChangedDefaultPrints = values._changedPrintsSrc.length !== defaultInfo.printsSrc.length
        const isChangedDefaultOtherPreviews = values._changedOtherPreviews.length !== defaultInfo.otherPreviews.length
        return !!values.preview
            || values.original.length
            || values.prints.length
            || values.otherPreviews.length
            || isChangedDefaultOriginals
            || isChangedDefaultPrints
            || isChangedDefaultOtherPreviews
            || defaultInfo.descr !== values.description
            || defaultInfo.width !== values.width
            || defaultInfo.height !== values.height
            || defaultInfo.unit !== values.unit
            || defaultInfo.orientation !== values.orientation
    }

    const undoChanges = () => {
        setValues({
            ...values,
            description: defaultInfo.descr,
            original: [],
            preview: null,
            prints: [],
            width: defaultInfo.width,
            height: defaultInfo.height,
            unit: defaultInfo.unit,
            orientation: defaultInfo.orientation,

            _changedPrintsSrc: defaultInfo?.printsSrc,
            _changedOriginalSrc: defaultInfo?.originalSrc,
            _changedOtherPreviews: defaultInfo?.otherPreviews,
        })
        setErrors({
            ...errors,
            original: '',
            orientation: '',
            preview: '',
            otherPreviews: '',
        })
    }

    const resetUploadModal = () => {
        closeHandler()
        setTimeout(() => upload.reset(), 500)
    }

    const fetchAdaptiveInfo = async () => {
        if (!adaptation_id) return console.log('adaptation_id is undefined')
        try {
            const accessToken = await freshAccessToken()
            const option = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
            const res = await axios.get(`${getEditAdaptiveDataUrl}/${adaptation_id}`, option)
            const data = res.data as EditAdaptiveInfo
            console.log('GET ADAPTIVE', data)

            if (data) {
                const defaultData: DefaultInfo = {
                    descr: data.description,
                    previewSrc: data.preview,
                    originalSrc: data.original,
                    printsSrc: data.prints,
                    width: data.width || '',
                    height: data.height || '',
                    unit: data.unit || '',
                    otherPreviews: data.other_previews,
                    orientation: data.orientation,
                }
                setValues({
                    ...values,
                    orientation: data.orientation,
                    description: data.description,
                    _changedOriginalSrc: data.original,
                    _changedPrintsSrc: data.prints,
                })
                setDefaultInfo(defaultData)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const sendEdit = async (data: FormData) => {
        try {
            upload.loading('Загрузка', 'Выполняется отправка файлов')
            const accessToken = await freshAccessToken()
            const option = {
                headers: { 'Authorization': `${tokenType} ${accessToken}` },
                onUploadProgress(e: any) {
                    upload.set.totalSize(e.total)
                    upload.set.uploadedSize(e.loaded)
                }
            }
            const res = await axios.post(`${getEditAdaptiveDataUrl}/${adaptation_id}`, data, option)
            console.log('EDIT ADAPTIVE', res)
            if (res.data.success) {
                upload.success('Готово', 'Адаптация успешно отредактирована')
                await fetchAdaptiveInfo()
                if (afterSuccessUpload) afterSuccessUpload()
            } else {
                upload.error('Ошибка', 'Что-то пошло не так')
            }
        } catch (e) {
            console.log(e)
            upload.error('Возникла ошибка', e.message)
        }
        setTimeout(() => resetUploadModal(), 2000)
    }

    useEffect(() => {
        fetchAdaptiveInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adaptation_id])

    useEffect(() => {
        //refresh after send edit (loading default data)
        undoChanges()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultInfo])

    if (!hasAccess) return null
    return (
        <FadeModal
            isShow={isShow}
            closeHandler={closeHandler}
            classes={['bf-edit-adaptive']}
            timeout={400}
        >
            {
                upload.status === null
                    ? <div className="bf-container bf-edit-adaptive__container">
                        <form
                            ref={dragRect}
                            className="bf-edit-adaptive__body"
                            id="edit-adaptive-modal"
                            onSubmit={e => e.preventDefault()}
                        >
                            <p className="bf-edit-adaptive__title">{titleModal}</p>

                            <div className="bf-edit-adaptive__inputs-field">
                                <TextAreaFormik
                                    title="Описание"
                                    value={values.description}
                                    onChangeValue={val => setFieldValue('description', val)}
                                    placeholder="Введите описание"
                                    wrapperClasses={['bf-edit-adaptive__description']}
                                />

                                <SizePresetsFormik
                                    valueWidth={values.width}
                                    valueHeight={values.height}
                                    valueUnit={values.unit}
                                    placementId={placement_id}
                                    setFieldValue={setFieldValue}
                                />

                                <SizesFormik
                                    valueWidth={values.width}
                                    valueHeight={values.height}
                                    valueUnit={values.unit}
                                    setFieldValue={setFieldValue}
                                />

                                <OrientationsFormik
                                    value={values.orientation}
                                    placementId={placement_id}
                                    error={errors.orientation}
                                    wrapperClasses={['orientation-top-offset']}
                                    classes={['bf-size-radio']}
                                    onChangeValue={val => setFieldValue('orientation', val)}
                                    onChangeError={err => setFieldError('orientation', err)}
                                />
                            </div>

                            <div className="bf-edit-adaptive__files-field">
                                <PreviewInputFormik
                                    dragRect={dragRect}
                                    value={values.preview}
                                    error={errors.preview}
                                    previewSrc={defaultInfo.previewSrc}
                                    onChangeValue={val => setFieldValue('preview', val)}
                                    onChangeError={err => setFieldError('preview', err)}
                                />

                                <MultipleImgFilesFormik
                                    title="Дополнительные превью"
                                    value={values.otherPreviews}
                                    error={errors.otherPreviews}
                                    defaultFileContainer={values._changedOtherPreviews}
                                    onChangeDefault={files => setFieldValue('_changedOtherPreviews', files)}
                                    onChangeFiles={val => setFieldValue('otherPreviews', val)}
                                    onChangeError={err => setFieldError('otherPreviews', err)}
                                />

                                <MultipleFilesFormik
                                    defaultFileContainer={values._changedOriginalSrc}
                                    title="Исходники"
                                    value={values.original}
                                    error={errors.original}
                                    onChangeFiles={val => setFieldValue('original', val)}
                                    onChangeError={err => setFieldError('original', err)}
                                    onChangeDefault={files => setFieldValue('_changedOriginalSrc', files)}
                                />

                                <MultipleFilesFormik
                                    defaultFileContainer={values._changedPrintsSrc}
                                    title="Итог на печать"
                                    value={values.prints}
                                    onChangeFiles={val => setFieldValue('prints', val)}
                                    onChangeDefault={files => setFieldValue('_changedPrintsSrc', files)}
                                />
                            </div>

                            <div className="bf-edit-adaptive__controls">
                                <SubmitButton
                                    disabled={!hasBeenChangedAdaptive()}
                                    submitForm={submitForm}
                                />
                                <ResetButton undoChanges={undoChanges} />
                            </div>
                        </form>
                    </div>
                    : <UploadStatus upload={upload} />
            }
        </FadeModal>
    )
})

export default EditAdaptiveModal
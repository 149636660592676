import { useCallback, useEffect, useState } from "react"

type usePaginatorParams<Items> = {
    refContainer: React.RefObject<HTMLElement>
    items: Items
    fetchMoreCallback: (currentPage: number) => Promise<{ status: string }>
    pixelsAfterLoadMore?: number
}

function usePaginator <Items extends Array<any>>(params: usePaginatorParams<Items>) {
    const {
        refContainer,
        items,
        fetchMoreCallback,
        pixelsAfterLoadMore = 200,
    } = params

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [maxPage, setMaxPage] = useState<number | null>(null)
    const [isLoadingMore, setLoadingMore] = useState<boolean>(false)

    const fetchMore = async (nextPage: number) => {
        try {
            setLoadingMore(true)
            const { status } = await fetchMoreCallback(nextPage)
            if (status === 'success') {
                setCurrentPage(nextPage)
            }
            setLoadingMore(false)
        } catch (e) {
            console.log(e)
            setMaxPage(null)
            setLoadingMore(false)
        }
    }

    const scrollHandler = useCallback(() => {
        const bottomElemToTopScreen = refContainer.current?.getBoundingClientRect().bottom
        if (bottomElemToTopScreen) {
            const untillEnd = bottomElemToTopScreen - window.innerHeight
            const isCorrectRequestPage = maxPage && maxPage > currentPage
            const isReachedBottom = pixelsAfterLoadMore >= untillEnd
            if (!isLoadingMore && isCorrectRequestPage && isReachedBottom && items.length) {
                fetchMore(currentPage + 1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingMore, maxPage, currentPage, items])

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => { window.removeEventListener('scroll', scrollHandler) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingMore, maxPage, currentPage, items])

    return {
        isFetching: isLoadingMore,
        currentPage: currentPage,
        maxPage,
        setCurrentPage,
        setMaxPage,
        setFetching: setLoadingMore,
        forceFetchMore: fetchMore,
    }
}

export default usePaginator
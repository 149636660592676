import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, notification, Space, Spin, Tag, Typography} from 'antd';
import {CheckOutlined} from "@ant-design/icons";
import {useFormik} from "formik";
import {TagForm} from "./interfaces";
import {freshAccessToken} from "../../../../../../utils/cookie/cookie";
import {getAllTags, insertTagUrl, tokenType} from "../../../../../../config";
import {createFormData} from "../../../../../../utils/other";
import axios from "axios";
import {
    ResponseTagAllData,
    ReturnDataSuccess, xxTags
} from "../../../../../../types/responseTypes";
import TagContainer from "./components/TagContainer/TagContainer";

const {Text} = Typography

const ContentTags = () => {
    const [currentTags, setCurrentTags] = useState<xxTags[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadContent, setLoadContent] = useState(false);

    const submitTags = useCallback(async (value: TagForm) => {
        if(!value.name) return
        try {
            const accessToken = await freshAccessToken()
            setIsLoading(true);
            const options = {
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`
                },
            }
            const dataForm = createFormData(value)

            const resp = await axios.post(insertTagUrl, dataForm, options)
            const data = resp.data as ReturnDataSuccess

            if (data.success) {
                resetForm()
                setCurrentTags([data.tag, ...currentTags]);
                notification.success({
                    message: 'Успешное создание тега'
                })
            } else {
                notification.error({
                    message: 'Ошибка создания! Пожалуйста обновите страницу и попробуйте еще раз'
                })
            }
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            notification.error({
                message: 'Ошибка создания! '+ e.message
            })
            setIsLoading(false)
        }
    }, [setCurrentTags, currentTags]);

    const {values, setFieldValue, resetForm} = useFormik<TagForm>({
        initialValues: {
            name: ''
        },
        onSubmit: submitTags
    });

    const onChange = useCallback((e) => {
        setFieldValue('name', e.target.value);
    }, [setFieldValue])

    const sendPressTag = useCallback(async (e) => {
        if(e.code === 'Enter' || e.code === 'Space'){
            await submitTags(values);
        }
    }, [values, submitTags]);

    const sendButtonHandle = useCallback(async () => {
        await submitTags(values)
    }, [values, submitTags]);

    useEffect(() => {
        let isUnmount = false
        async function updateData() {
            try {
                setLoadContent(true);
                const accessToken = await freshAccessToken()
                const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
                const resp = await axios.get(getAllTags, options)
                const respData = resp.data as ResponseTagAllData
                if(respData.success){
                    setCurrentTags(respData.data)
                }else{
                    notification.error({
                        message: 'Ошибка соединения! Пожалуйста обновите страницу и попробуйте еще раз'
                    })
                }
                setLoadContent(false);
            } catch (e) {
                console.log(e)
                setLoadContent(false);
                notification.error({
                    message: 'Ошибка соединения! '+e.message
                })
            }
        }
        updateData()
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return(<div>
        <Input.Group compact>
            <Input addonBefore="#"
                   placeholder="Название_Тега"
                   value={values.name}
                   style={{ width: '300px' }}
                   onChange={onChange}
                   disabled={isLoading}
                   onKeyPress={sendPressTag}/>
            <Button type="primary" loading={isLoading} icon={<CheckOutlined />} className="align-button-icon" onClick={sendButtonHandle}/>
        </Input.Group>
        <div style={{maxWidth: 300, lineHeight: '12px'}}>
        <Text type="secondary">Создание тега производится по нажатию синей кнопки, а также кнопок Enter и Пробел.
           Для создания тега из нескольких слов, разделяете название через "_". Например: Два_Слова</Text>
        </div>
        {loadContent && <div>
            <Spin/> Загрузка
        </div>}
        <div>
            <TagContainer tags={currentTags} setCurrentTags={setCurrentTags}/>
        </div>
    </div>)
}

export default ContentTags
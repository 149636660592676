import React, { Dispatch } from 'react'
import { Menu, Dropdown } from 'antd';
import { shortUserName } from '../../../utils/other';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../store/reducers/rootReducer';
import { logoutUser } from '../../../store/actions/AuthActions';
import { UserInfo } from '../../../types/responseTypes';
import { Link } from 'react-router-dom';
import {UserRole} from "../../../types/stateTypes";

type UserFieldProps = {}

const UserField: React.FC<UserFieldProps> = () => {

    const userInfo = useSelector<IRootState>(state => state.app.user) as UserInfo
    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const dispatch = useDispatch()

    return (
        <Dropdown overlay={getLink(dispatch, userRoles.includes('Admin'), userRoles.includes('Designer'))} placement="bottomRight" arrow>
            <button className="bf-navbar__cart-btn bf-btn bf-btn-gray">{shortUserName(userInfo.name)}</button>
        </Dropdown>
    )
}

const getLink = (dispatch: Dispatch<any>, isAdmin: boolean, isDesigner: boolean) => {

    const leaveHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        dispatch(logoutUser())
    }
    return (
        <Menu>
            {isAdmin &&
                <>
                    <Menu.Item>
                        <Link to="/prototype/oauth">OAuth Yandex</Link>
                    </Menu.Item>
                </>
            }
            {(isAdmin || isDesigner) &&
            (
                <Menu.Item>
                    <Link to="/prototype/manage">Управление</Link>
                </Menu.Item>
            )}
            <Menu.Item>
                <Link to="#" onClick={leaveHandler}>Выйти</Link>
            </Menu.Item>
        </Menu>
    )
}

export default UserField

import React from 'react'
import './Version.scss'

type VersionProps = {
    date?: string
    version?: number | null
    isCurrent?: boolean
}
const Version: React.FC<VersionProps> = ({ version, date, isCurrent }) => {

    return (
        <div className="design-version">

            {
                version === undefined || version === null ? null : isCurrent
                    ? <p className="design-version__current">Текущая версия №{ version }</p>
                    : <p className="design-version__current disabled">№{ version }</p>
            }
            {
                date
                    ? <p className="design-version__current-date">от { date }</p>
                    : null
            }
        </div>
    )
}

export default Version
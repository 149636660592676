import React, {useState} from "react";
import RegulationNew from "./components/RegulationNew/RegulationNew";
import RegulationAll from "./components/RegulationAll/RegulationAll";

const ContentRegulation = () => {

    const [loading, setLoading] = useState<boolean>(false);

    return(<div>
        <RegulationNew setLoading={setLoading}/>
        <RegulationAll isLoading={loading}/>
    </div>)
}

export default ContentRegulation
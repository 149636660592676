import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CreateLayoutFields, FormikSetFieldValue, InitialDataCreateLayout } from '../../../../types/types'
import { Select } from 'antd'
const { Option } = Select

type CityFieldProps = {
    initialData: InitialDataCreateLayout
    value: CreateLayoutFields['city']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const CityField: React.FC<CityFieldProps> = React.memo(({ initialData, value, setFieldValue }) => {

    const handleChange = (cityId: string | number | undefined) => {
        if (cityId === '-1') {
            setFieldValue('city', null)
            setFieldValue('club', null)
        }

        const newCity = initialData.cities.find(c => c.id + '' === cityId + '')
        if (newCity && newCity.id !== value?.id) {
            setFieldValue('city', newCity)
            setFieldValue('club', null)
        }
    }

    return (
        <InputSelect
            title="Город"
            placeholder="Все города"
            value={value?.name || null}
            wrapClasses={['create-select', 'right-offset']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    initialData.cities.map(c => {
                        return (
                            <Option value={c.id + ''} key={c.id + ''}>{c.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default CityField
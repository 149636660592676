import { DropboxOutlined, LoadingOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { fileBaseUrl } from '../../config'
import { EditFileItem } from '../../types/responseTypes'
import { sortFilesByName } from '../../utils/files'
import { useDnd } from '../../utils/hooks/useDnd'
import { arrayItemsId, openNotification } from '../../utils/other'
import { fileId, fileImgPreview, getExtensiosString } from '../../utils/parsers'
import InputFile from '../UI/InputFile/InputFile'
import './MultiplyAreaFilesImg.scss'

type SetFileCallback = ((data: File[]) => void) | React.Dispatch<React.SetStateAction<File[]>>
type SetDefaultFileCallback = ((files: EditFileItem[]) => void) | (React.Dispatch<React.SetStateAction<EditFileItem[]>>)
type ImgPreviewItem = {
    fileId: string,
    imgSrc: string
}

type MultiplyAreaFilesImgProps = {
    title: string
    dragRect?: React.RefObject<HTMLElement>
    validExtensions: string[]
    readonly fileContainer: File[]
    readonly defaultFileContainer?: EditFileItem[]
    errorMessage?: string
    setFileContainer: SetFileCallback
    setDefaultFileContainer?: SetDefaultFileCallback
}

const MultiplyAreaFilesImg: React.FC<MultiplyAreaFilesImgProps> = (props) => {

    const {
        title, fileContainer, errorMessage,
        defaultFileContainer, dragRect, validExtensions,
        setFileContainer, setDefaultFileContainer,
    } = props

    const [previews, setPreviews] = useState<ImgPreviewItem[]>([])

    const dragArea = useRef<HTMLDivElement>(null)

    const originalChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (validateFiles(files)) {
            addFiles(files)
        }
    }

    const addFiles = (files: FileList) => {
        const arrayFiles = Object.values(files)
        let newOriginalFiles = [...fileContainer]
        newOriginalFiles = newOriginalFiles.concat(arrayFiles)
        setFileContainer(sortFilesByName(newOriginalFiles))
    }

    const validateFiles = (files: FileList | undefined | null): files is FileList => {
        let isValid = true
        if (!files) return false;

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (!(file instanceof File)) {
                openNotification('Загружаемый объект не является файлом', 'error')
                isValid = false
                break
            }
            const fileExtension = file.name.split('.').splice(-1).join('').toLowerCase()
            const isValidFileName = validExtensions.indexOf(fileExtension) !== -1

            if (!isValidFileName) {
                openNotification('Неверный формат', 'error', `
                Вы выбрали файл с расширением ${fileExtension.toUpperCase()}.
                Разрешенные форматы: ${getExtensiosString({ validExtensions })}
                `)
                isValid = false
            }
        }
        return isValid
    }

    useDnd<HTMLElement, HTMLDivElement>({
        dragArea,
        dragRect,
        validFormats: ['png', 'jpg', 'jpeg'],
        onChangeFiles: (files) => addFiles(files),
        onErrorValidate: (err) => {
            openNotification(err, 'error');
        },
        validate: validateFiles,
    }, [addFiles])

    const deleteFile = (deleteFile: File) => {
        let newOriginalFiles = [...fileContainer]
        newOriginalFiles = newOriginalFiles.filter(file => file !== deleteFile)
        setFileContainer(sortFilesByName(newOriginalFiles))
    }

    const deleteDefaultFile = (file: EditFileItem) => {
        if (defaultFileContainer && setDefaultFileContainer) {
            const newFiles = defaultFileContainer?.filter(f => f.id !== file.id)
            setDefaultFileContainer(newFiles)
        }
    }

    const hasFiles = fileContainer.length || defaultFileContainer?.length

    useEffect(() => {
        (async function () {
            const newPreviews = [...previews]
            await Promise.all(fileContainer.map(async (file) => {
                const id = fileId(file)
                let preview = previews.find(p => p.fileId === id)
                if (!preview) {
                    preview = { fileId: id, imgSrc: '' }
                    preview.imgSrc = await fileImgPreview(file)
                    newPreviews.push(preview)
                }
            }))
            setPreviews(newPreviews)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrayItemsId(fileContainer)])

    return (
        <div className="bf-multi-img-wrapper">
            <div className="bf-multi-img" ref={dragArea}>
                <p className={errorMessage ? "bf-multi-img__title error" : "bf-multi-img__title"}>{title}</p>
                <ul className={hasFiles ? "bf-multi-img__file-list" : "bf-multi-img__file-list hidden"}>
                    {
                        defaultFileContainer?.map((f, i) => {
                            return (
                                <li
                                    key={f.id}
                                    className="bf-multi-img__file-item"
                                >
                                    <button
                                        className="bf-multi-img__delete-btn bf-btn"
                                        onClick={() => deleteDefaultFile(f)}
                                    >
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1.75732" y="9.3941" width="10.8" height="1.2" transform="rotate(-45 1.75732 9.3941)" />
                                            <rect x="2.60645" y="1.75729" width="10.8" height="1.2" transform="rotate(45 2.60645 1.75729)" />
                                        </svg>
                                    </button>
                                    <img
                                        src={`${fileBaseUrl}/${f.file_path}`}
                                        alt="file"
                                        className="bf-multi-img__file-item-img"
                                    />

                                </li>
                            )
                        })
                    }

                    {
                        fileContainer.map((file, i) => {
                            const preview = previews.find(p => p.fileId === fileId(file))

                            return (
                                <li
                                    key={file.name + i}
                                    className="bf-multi-img__file-item"
                                >
                                    <button
                                        className="bf-multi-img__delete-btn bf-btn"
                                        onClick={() => deleteFile(file)}
                                    >
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1.75732" y="9.3941" width="10.8" height="1.2" transform="rotate(-45 1.75732 9.3941)" />
                                            <rect x="2.60645" y="1.75729" width="10.8" height="1.2" transform="rotate(45 2.60645 1.75729)" />
                                        </svg>
                                    </button>
                                    {
                                        preview
                                            ? <img
                                                src={`${preview.imgSrc}`}
                                                alt="file"
                                                className="bf-multi-img__file-item-img"
                                            />
                                            : <div className="bf-multi-img__file-item-img-placeholder">
                                                <LoadingOutlined
                                                    className='bf-multi-img__file-item-img-spin'
                                                    spin
                                                />
                                            </div>
                                    }
                                </li>
                            )
                        })
                    }

                    <li className="bf-multi-img__area-bg">
                        <DropboxOutlined className="bf-multi-img__area-bg-icon rotate-animate" />
                        <p className="bf-multi-img__area-bg-text">Добавить файл</p>
                    </li>
                </ul>
                {
                    errorMessage
                        ? <p className="bf-input-error-message">{errorMessage}</p>
                        : null
                }
                <div className="bf-multi-img__file-buttons">
                    <InputFile
                        textBtn="Загрузить"
                        classes={['bf-btn', 'bf-btn-primary', 'files-add-btn']}
                        multiple={true}
                        onChange={originalChangeHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default MultiplyAreaFilesImg

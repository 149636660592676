import React from 'react'

type SlotProviderProps = {
    slot: string
}
const SlotProvider: React.FC<SlotProviderProps> = ({children}) => {
    return (
        <>
            { children }
        </>
    )
}

export default SlotProvider
import { SizeUnit } from '../types/types';
import { CustomSize, Orientation, xxCatalog } from './../types/responseTypes';

export const defaultProject: xxCatalog = {
    id: 0,
    is_system: 1,
    key: "not-project",
    name: "Не проект",
    catalog_id: ['-1'],
    sort: -1,
}

export const initialSizes = { width: '0', height: '0', unit: '' }

export const customSizesCreate = (): CustomSize[] => {
    return [
        {
            catalogId: [1],
            label: 'A2 (420x594 мм)',
            width: '420',
            height: '594',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [1],
            label: 'A3 (297x420 мм)',
            width: '297',
            height: '420',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [1],
            label: 'A4 (210x297 мм)',
            width: '210',
            height: '297',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [1],
            label: 'A5 (148x210 мм)',
            width: '148',
            height: '210',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [1],
            label: 'A6 (105x148 мм)',
            width: '105',
            height: '148',
            unit: 'mm',
            _id: Math.random(),
        },

        {
            catalogId: [89],
            label: '6000x3000 мм',
            width: '6000',
            height: '3000',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [89],
            label: '12000x4000 мм',
            width: '12000',
            height: '4000',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [89],
            label: '5000x1100 мм',
            width: '5000',
            height: '1100',
            unit: 'mm',
            _id: Math.random(),
        },
        {
            catalogId: [89],
            label: '7000x3000 мм',
            width: '7000',
            height: '3000',
            unit: 'mm',
            _id: Math.random(),
        },

        {
            catalogId: [99],
            label: '1240x400 px',
            width: '1240',
            height: '400',
            unit: 'px',
            _id: Math.random(),
        }
    ]
}

export const sizeUnitsCreate = (): SizeUnit[] => {
    return [
        {
            label: 'ММ',
            value: 'mm',
            _id: Math.random()
        },
        {
            label: 'PX',
            value: 'px',
            _id: Math.random()
        },
    ]
}

export const orientationsCreate = (): Orientation[] => {
    return [
        {
            catalogId: [1, 89, 99],
            label: 'Вертикальная',
            value: 'vertical',
            _id: Math.random()
        },
        {
            catalogId: [1, 89, 99],
            label: 'Горизонтальная',
            value: 'horizontal',
            _id: Math.random()
        },
        {
            catalogId: [1, 89, 99],
            label: 'Квадратная',
            value: 'square',
            _id: Math.random()
        }
    ]
}

export const masks = {
    date: [/\d/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/],
    onlyNumbers: /[^\d\+]/g,
}
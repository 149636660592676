import React, { useEffect, useRef, useState } from 'react'
import { CreateDesignTemplate, EditLayoutInfo } from '../../../types/responseTypes'
import UploadStatus from '../../UploadStatus/UploadStatus'
import FadeModal from '../FadeModal/FadeModal'
import { InitialDataCreateLayout, ModalEditLayoutFields } from '../../../types/types'
import './EditLayoutModal.scss'
import { getEditLayoutDataUrl, getTemplateUrl, tokenType } from '../../../config'
import { freshAccessToken } from '../../../utils/cookie/cookie'
import axios from 'axios'
import { ResetButton, SubmitButton, ClearButton } from './Controls'
import {
    PlacementField, AdaptationField,
    CityField, ClubField, SourceField, ZonesField,
    ProjectField, DecorationSwitch, LayoutTypeField,
} from './FormFields'
import { defaultProject, masks } from '../../../Data/createData'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../store/reducers/rootReducer'
import { SpecificationDesign, UserRole } from '../../../types/stateTypes'
import useUploadModal from '../../../utils/hooks/useUploadModal'
import { useFormik } from 'formik'
import { createFormData, openNotification, scrollTopSmooth } from '../../../utils/other'
import { EditLayoutRequest } from '../../../types/requestTypes'
import TextFormikInput from '../../formikFields/TextFormikInput/TextFormikInput'
import RequiredSwitch from './FormFields/RequiredSwitch/RequiredSwitch'
import RequiredDatesField from './FormFields/RequiredDatesField/RequiredDatesField'
import { validateRequiredDates } from '../../../utils/formUtils'
import { separateRequiredDate, clearReqDate } from '../../../utils/parsers'
import TagsField from "../../../Pages/NewDesignPage/FormFields/TagsFields/TagsFields";

type EditLayoutModalProps = {
    titleModal: string
    isShow: boolean
    layout_id: number
    specifications: SpecificationDesign[]
    closeHandler: () => void
    afterSuccessUpload?: () => void
    setSpecifications: React.Dispatch<React.SetStateAction<SpecificationDesign[]>>
}

const EditLayoutModal: React.FC<EditLayoutModalProps> = React.memo((props) => {
    const {
        titleModal, isShow, layout_id,
        specifications,
        closeHandler, afterSuccessUpload,
        setSpecifications,
    } = props

    const upload = useUploadModal()

    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const dragRect = useRef<HTMLFormElement>(null)
    const hasAccess = userRoles.includes('Admin') || userRoles.includes('Designer')

    const [templateData, setTemplateData] = useState<InitialDataCreateLayout>({
        cities: [],
        clubs: [],
        catalogs: [],
        tags: []
    })

    const [defaultData, setDefaultData] = useState<EditLayoutInfo | null>(null)

    const validate = (values: ModalEditLayoutFields) => {
        const errors = {} as { [key: string]: string }
        if (!values.name) errors.name = 'Укажите имя'
        if (!values.placement) errors.placement = 'Укажите размещение'
        if (!values.project) errors.project = 'Укажите проект'
        if (!values.source) errors.source = 'Выберите носитель'

        validateRequiredDates(errors, values.isRequired, values.requiredFrom, values.requiredTo)

        if (Object.keys(errors).length) {
            scrollTopSmooth('#edit-layout-modal')
            openNotification('Ошибка', 'error', 'Заполните обязательные поля')
        }
        return errors
    }

    const submitHandler = (values: ModalEditLayoutFields) => {
        if (defaultData) {
            const data = {} as EditLayoutRequest
            const zonesId = zoneIdsToString(values.zones)

            if (defaultData.city_id !== (values.city?.id || 0)) data.city_id = values.city?.id || 0
            if (defaultData.club_id !== (values.club?.id || 0)) data.club_id = values.club?.id || 0
            if (!!defaultData.is_adaptive !== values.adaptation) data.is_adaptive = Number(values.adaptation) as 0 | 1
            if (!!defaultData.is_required !== values.isRequired) data.is_required = Number(values.isRequired) as 0 | 1

            if (defaultData.placement_id !== values.placement?.id) data.placement_id = values.placement?.id
            if (defaultData.project_id !== values.project?.id) data.project_id = values.project?.id
            if (defaultData.title !== values.name) data.title = values.name
            if (defaultData.type_id !== (values.layoutType?.id || 0)) data.type_id = values.layoutType?.id || 0

            if (clearReqDate(defaultData.required_from) !== clearReqDate(values.requiredFrom)) data.required_from = clearReqDate(values.requiredFrom)
            if (clearReqDate(defaultData.required_to) !== clearReqDate(values.requiredTo)) data.required_to = clearReqDate(values.requiredTo)

            if(values.tags.length > 0){
                data.tag_id = values.tags.map((tag) => tag.id).join(',')
            }

            data.zone_id = zonesId
            data.source_id = values.source?.id || 0

            console.log('edit layout', data)
            const dataForm = createFormData(data)
            if (hasAccess) sendData(dataForm)
        }
    }

    const {
        values, errors, setFieldValue,
        setValues, resetForm, submitForm,
        setFieldError, setErrors,

    } = useFormik<ModalEditLayoutFields>({
        initialValues: {
            name: '',
            placement: null,
            city: null,
            club: null,
            source: null,
            zones: [],
            tags: [],
            project: null,
            layoutType: null,
            width: '',
            height: '',
            unit: '',
            orientation: '',
            adaptation: false,

            isRequired: false,
            requiredFrom: '',
            requiredTo: '',

            _isDecoration: false,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate,
        onSubmit: submitHandler,
    })

    // computed
    const zonesIds = (sourceIds: string) => sourceIds.split(',').map(id => +id)
    const zoneIdsToString = (zones: ModalEditLayoutFields['zones']) => {
        if (zones) {
            return zones.map(z => z.id).sort((a, b) => b - a).join(',')
        }
        return values.zones.map(z => z.id).sort((a, b) => b - a).join(',')
    }
    const hasAnyError = () => !!(errors.name || errors.placement || errors.source || errors.project)
    //

    const resetUploadModal = () => {
        closeHandler()
        setTimeout(upload.reset, 500)
    }

    const undoChanges = () => {
        if (defaultData) {
            const citiyById = templateData.cities.find(c => c.id === defaultData.city_id)
            const clubById = templateData.clubs.find(c => c.id === defaultData.club_id)
            const placementById = templateData.catalogs.find(p => p.id === defaultData.placement_id)
            const sourceById = placementById?.sources?.find(s => s.id === defaultData.source_id)
            const zoneById = sourceById?.children?.filter(z => zonesIds(defaultData.zone_id).includes(z.id))
            const projectById = placementById?.projects.items?.find(p => p.id === defaultData.project_id)
            const types = placementById?.layout_temp?.items && placementById?.layout_temp?.items[0]
            const typeById = types?.children?.find(t => t.id === defaultData.type_id)

            setValues({
                ...values,
                name: defaultData.title,
                city: citiyById || null,
                club: clubById || null,
                placement: placementById || null,
                source: sourceById || null,
                zones: zoneById || [],
                project: projectById || defaultProject,
                layoutType: typeById || null,
                adaptation: !!defaultData.is_adaptive,
                isRequired: !!defaultData.is_required,
                requiredFrom: defaultData.required_from || '',
                requiredTo: defaultData.required_to || '',
                tags: defaultData.tag,
                _isDecoration: !!typeById,
            })
            setErrors({
                name: '',
                placement: '',
                source: '',
                project: '',
                requiredFrom: '',
                requiredTo: '',
            })
        }
    }

    const updateSpecs = (layout: EditLayoutInfo, templateData: InitialDataCreateLayout) => {
        const newSpecs = [] as SpecificationDesign[]

        const city = templateData.cities.find(c => c.id === layout.city_id) || null
        const club = templateData.clubs.find(c => c.id === layout.club_id) || null

        if (city) {
            newSpecs.push({
                title: 'Город',
                value: city.name,
            })
        }

        if (club) {
            newSpecs.push({
                title: 'Клуб',
                value: club.name,
            })
        }

        newSpecs.push({
            title: 'Требуется адаптация',
            value: layout.is_adaptive ? 'Да' : 'Нет',
        })

        if(layout.tag.length > 0){
            newSpecs.push({
                title: 'Теги',
                value: layout.tag.map((tag) => `#${tag.name}`).join(', '),
            })
        }

        if (layout.is_required) {
            newSpecs.push({
                title: 'Обязателен с',
                value: separateRequiredDate({ value: layout.required_from || '' }),
            })
            newSpecs.push({
                title: 'Обязателен по',
                value: separateRequiredDate({ value: layout.required_to || '' }),
            })
        }

        setSpecifications(newSpecs)
    }

    const editLayoutInfo = async () => {
        try {
            const accessToken = await freshAccessToken()
            const option = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
            const res = await axios.get(`${getEditLayoutDataUrl}/${layout_id}`, option)
            console.log('GET LAYOUT', res)
            const data = res.data as EditLayoutInfo
            if (data.title || data.placement_id) {
                await fetchTemplate()
                setDefaultData(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const fetchTemplate = async () => {
        try {
            const accessToken = await freshAccessToken()
            const option = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
            const res = await axios.get(getTemplateUrl, option)
            const data = res.data as CreateDesignTemplate
            if (data.success) {
                setTemplateData({
                    cities: data.data.cities,
                    clubs: data.data.clubs,
                    catalogs: data.data.catalogs,
                    tags: data.data.tags.map((tag) => ({...tag, name: `#${tag.name}`}))
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const sendData = async (data: FormData) => {
        try {
            upload.loading('Загрузка', 'Выполняется отправка файлов')
            const accessToken = await freshAccessToken()
            const option = {
                headers: { 'Authorization': `${tokenType} ${accessToken}` },
                onUploadProgress(e: any) {
                    upload.set.totalSize(e.total)
                    upload.set.uploadedSize(e.loaded)
                }
            }
            const res = await axios.post(`${getEditLayoutDataUrl}/${layout_id}`, data, option)
            console.log('EDIT Layout', res)
            if (res.data.success) {
                upload.success('Готово', 'Файлы успешно отправлены')
                await editLayoutInfo()
                if (afterSuccessUpload) afterSuccessUpload()
            } else {
                upload.error('Ошибка', 'Что-то пошло не так')
            }
        } catch (e) {
            console.log(e)
            upload.error('Возникла ошибка', e.message)
        }
        setTimeout(() => resetUploadModal(), 2000)
    }

    useEffect(() => {
        if (defaultData) {
            // if updated default values (after edit layout)
            undoChanges()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultData])

    useEffect(() => {
        editLayoutInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout_id])

    useEffect(() => {
        if (defaultData) {
            updateSpecs(defaultData, templateData)
        }
    }, [defaultData, templateData])

    if (!hasAccess) return null
    return (
        <FadeModal
            isShow={isShow}
            closeHandler={closeHandler}
            classes={['bf-edit-layout']}
            timeout={400}
        >
            {
                upload.status === null
                    ? <div className="bf-container bf-edit-layout__container">
                        <form
                            className="bf-edit-layout__body"
                            id="edit-layout-modal"
                            onSubmit={e => e.preventDefault()}
                            ref={dragRect}
                        >
                            <p className="bf-edit-layout__title">{titleModal}</p>

                            <div className="bf-edit-layout__inputs-field">
                                <TextFormikInput
                                    title="Название"
                                    placeholder="Введите название"
                                    value={values.name}
                                    error={errors.name}
                                    wrapperClasses={['create-input']}
                                    onChangeValue={val => setFieldValue('name', val)}
                                    onChangeError={err => setFieldError('name', err)}
                                />

                                <div className="bf-edit-layout__location">
                                    <p className="bf-edit-layout__subtitle">Размещение</p>
                                    <PlacementField
                                        initialData={templateData}
                                        values={values}
                                        errors={errors}
                                        setValues={setValues}
                                        setErrors={setErrors}
                                    />
                                    <CityField
                                        initialData={templateData}
                                        value={values.city}
                                        setFieldValue={setFieldValue}
                                    />
                                    <ClubField
                                        initialData={templateData}
                                        value={values.club}
                                        valueCity={values.city}
                                        setFieldValue={setFieldValue}
                                    />

                                    <SourceField
                                        value={values.source}
                                        valuePlacement={values.placement}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        setFieldError={setFieldError}
                                    />
                                    <ZonesField
                                        value={values.zones}
                                        valueSource={values.source}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>

                                <div className="bf-edit-layout__decoration">
                                    <DecorationSwitch
                                        value={values._isDecoration}
                                        valuePlacement={values.placement}
                                        setFieldValue={setFieldValue}
                                    />
                                    <LayoutTypeField
                                        value={values.layoutType}
                                        valueDecoration={values._isDecoration}
                                        valuePlacement={values.placement}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <div className="bf-edit-layout__project">
                                    <TagsField
                                        value={values.tags}
                                        initialData={templateData}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>

                                <ProjectField
                                    value={values.project}
                                    valuePlacement={values.placement}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                />

                                <AdaptationField
                                    value={values.adaptation}
                                    setFieldValue={setFieldValue}
                                />

                                <RequiredSwitch
                                    value={values.isRequired}
                                    setFieldValue={setFieldValue}
                                />

                                <RequiredDatesField
                                    show={values.isRequired}
                                    dateFrom={values.requiredFrom || ''}
                                    errorDateFrom={errors.requiredFrom || ''}
                                    dateTo={values.requiredTo || ''}
                                    errorDateTo={errors.requiredTo || ''}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                />
                            </div>

                            <div className="bf-edit-layout__controls">
                                <ClearButton resetForm={resetForm} />
                                <ResetButton undoChanges={undoChanges} />
                                <SubmitButton
                                    disabled={hasAnyError()}
                                    submitForm={submitForm}
                                />
                            </div>
                        </form>
                    </div>
                    : <UploadStatus upload={upload} />
            }
        </FadeModal>
    )
})

export default EditLayoutModal
import React from 'react'
import { FormikResetForm, ModalLoadLayoutFields } from '../../../../../types/types'

type ResetButtonProps = {
    resetForm: FormikResetForm<ModalLoadLayoutFields>
}

const ResetButton: React.FC<ResetButtonProps> = React.memo(({ resetForm }) => {

    return (
        <button
            type="button"
            className="bf-load-modal__clear bf-btn bf-btn-gray"
            onClick={() => resetForm()}
        >
            Очистить
        </button>
    )
})

export default ResetButton
import React from 'react'
import './Select.scss'
import { Select } from 'antd'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const { Option } = Select

type InputSelectProps = {
    title: string
    placeholder: string
    value?: string | string[] | number | null
    defaultValue?: string | null
    children?: JSX.Element | JSX.Element[]
    disabled?: boolean
    isShow?: boolean
    classes?: string[]
    wrapClasses?: string[]
    errorMessage?: string
    multiple?: boolean
    loading?: boolean
    onChange?: (value?: string | number | undefined, options?: OptionValue) => void
    onFocus?: (e?: React.FocusEvent<HTMLElement>) => void
    onBlur?: (e?: React.FocusEvent<HTMLElement>) => void
    onSearch?: (value?: string) => void
}

type OptionValue = {
    children: null | string
    key: null | string
    value: null | string
}

const InputSelect: React.FC<InputSelectProps> = (props) => {
    let {
        title, value, defaultValue,
        placeholder, classes, wrapClasses,
        errorMessage, children, disabled,
        isShow = true, multiple = false,
        loading,
        onChange, onFocus, onBlur,
        onSearch
    } = props


    if (typeof value === 'number') value = value + ''

    let selectClasses = ['']
    let wrapperClasses = multiple ? ['bf-select-multiple'] : ['bf-select']

    if (classes) {
        selectClasses = selectClasses.concat(classes)
    }
    if (wrapClasses) {
        wrapperClasses = wrapperClasses.concat(wrapClasses)
    }
    if (disabled) {
        wrapperClasses.push('disabled')
    }
    if (errorMessage) {
        wrapperClasses.push('error')
    }
    if (!isShow) return null

    return (
        <div className={wrapperClasses.join(' ')}>
            <div className={multiple ? 'bf-select-multiple__container' : 'bf-select-container'}>
                <p className={multiple ? 'bf-select-multiple__title' : 'bf-select__title'}>{title}</p>
                <Select
                    showSearch
                    mode={multiple ? 'multiple' : undefined}
                    placeholder={placeholder}
                    defaultValue={defaultValue as string}
                    value={value as string}
                    className={selectClasses.join(' ')}
                    optionFilterProp="children"
                    disabled={disabled || false}
                    filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value, option) => {
                        if (onChange)
                            onChange('' + value as string, option as OptionValue)
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                >
                    {
                        loading
                            ? <Option key="loader-data" value={'0'} className="select-loader-data" disabled>
                                <Spin indicator={<LoadingOutlined spin />} />
                            </Option>
                            : children
                    }
                </Select>
            </div>
            {
                errorMessage
                    ? <p className="bf-input-error-message">{errorMessage}</p>
                    : null
            }
        </div>
    )
}

export default InputSelect

// import { Select } from 'antd'
// const { Option } = Select
// {/* <Option value="jack">Jack</Option> */}
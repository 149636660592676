import React from 'react'
import './InputFile.scss'


type InputFileProps = {
    textBtn: string
    isShow?: boolean
    name?: string
    id?: string
    multiple?: boolean
    classes?: string[]
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const InputFile: React.FC<InputFileProps> = ({textBtn, name, isShow = true, id, multiple, classes, onChange}) => {

    let fileClasses = ['bf-input-file']

    if (classes && classes.length > 0) {
        fileClasses = fileClasses.concat(classes)
    }

    if (!isShow) return null

    return (
        <label className={fileClasses.join(' ')}>
            <input
                type="file"
                name={name}
                id={id}
                multiple={multiple}
                onChange={onChange}
            />
            <span>{textBtn}</span>
        </label>
    )
}

export default InputFile
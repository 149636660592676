import React, {useEffect, useState} from "react";
import NavbarLayout from "../../Components/Layout/NavbarLayout/NavbarLayout";
import './OAuthYandex.css';
import {tokenType, yandexCheckUrl, yandexCodeUrl, yandexRefreshUrl} from "../../config";
import {freshAccessToken} from "../../utils/cookie/cookie";
import axios from "axios";
import {Button} from "antd";
import TextFormikInput from "../../Components/formikFields/TextFormikInput/TextFormikInput";

const OAuthYandex: React.FC<any> = () => {
    const yaLink = 'https://oauth.yandex.ru/authorize?response_type=code&client_id=c816b3467e28435191361b4b305b4f7d&device_id=d3vback@yandex.ru&device_name=proto&login_hint=d3vback@yandex.ru&force_confirm=yes';
    const [isOk, setIsOk] = useState<string | null>(null);
    const [code, setCode] = useState<string>('');
    const [expires, setExpires] = useState<Date>();
    const [error, setError] = useState<string | null>(null);
    const [load, setLoad] = useState(false);

    const checkAuthYandex = async () => {
        setLoad(true);
        setIsOk(null);
        const accessToken = await freshAccessToken()
        const option = { headers: {'Authorization': `${tokenType} ${accessToken}`}}
        axios.get(yandexCheckUrl, option).then((res) => {
            if(res.data.success){
                setExpires(new Date(parseInt(res.data.expires ) * 1000))
                setIsOk('Done');
            }
            setLoad(false);
        }).catch((er) => {
            console.log(er);
            setIsOk('Fail');
            setLoad(false);
        })
    }

    const monthFormat = (month: number) => {
        switch (month){
            case 0: return 'января';
            case 1: return 'февраля';
            case 2: return 'марта';
            case 3: return 'апреля';
            case 4: return 'мая';
            case 5: return 'июня';
            case 6: return 'июля';
            case 7: return 'августа';
            case 8: return 'сентября';
            case 9: return 'октября';
            case 10: return 'ноября';
            case 11: return 'декабря';
        }
    }

    const dateToFormat = (date: Date) => {
        return `${date.getDate()} ${monthFormat(date.getMonth())} ${date.getFullYear()}`;
    }

    const clickCodeSend = async () => {
        setLoad(true);
        const accessToken = await freshAccessToken()
        const option = { headers: {'Authorization': `${tokenType} ${accessToken}`}}
        axios.post(yandexCodeUrl, {
            code: code
        }, option).then((res) => {
            if(res.data.success){
                checkAuthYandex();
            }
            setLoad(false);
        }).catch((er) => {
            console.log(er);
            if(er.response.data){
                setError(er.response.data.error_message);
            }
            setLoad(false);
        })
    }

    const onRefreshToken = async () => {
        setLoad(true);
        const accessToken = await freshAccessToken()
        const option = { headers: {'Authorization': `${tokenType} ${accessToken}`}}
        axios.post(yandexRefreshUrl, {}, option).then((res) => {
            if(res.data.success){
                checkAuthYandex();
            }
            setLoad(false);
        }).catch((er) => {
            console.log(er);
            if(er.response.data){
                setError(er.response.data.error_message);
            }
            setLoad(false);
        })
    }

    useEffect(() => {
        checkAuthYandex();
    }, []);

    return (
        <NavbarLayout>
            <section className="main-categories">
                <div className="bf-container main-categories__container">
                    <div style={{marginTop: 25}}>
                    <div className="oauth-yandex__status">
                        Текущий статус токена: <span className={isOk === 'Done' ? 'oauth-yandex__status-ok' : isOk === 'Fail' ? 'oauth-yandex__status-fail' : ''}>
                            {isOk === 'Done' ? 'Актуальный' : isOk === 'Fail' ? 'Неактуальный' : 'Проверка...'}
                        </span>
                    </div>
                    { isOk === 'Done' && <div className="oauth-yandex__status-time">
                        {expires && 'Токен действителем до: '+dateToFormat(expires)}
                    </div>}
                        { !load &&
                            <Button onClick={checkAuthYandex}>Проверить снова</Button>
                        }
                        {
                            isOk === 'Done' && !load && <div style={{marginTop: 25}}>
                                <Button onClick={onRefreshToken}>
                                    Refresh current token
                                </Button>
                            </div>
                        }
                    {
                        isOk === 'Fail' &&
                            <div className="oauth-yandex__code">
                                <div className="oauth-yandex__code-form">
                                    <div className="oauth-yandex__code-container">
                                        <div style={{marginBottom: 10}}>Чтобы продолжить работу, нужно обновить токен</div>
                                        <div className="oauth-yandex__code-link" style={{marginBottom: 10}}>
                                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                            <a href={yaLink} target="_blank">Для получения кода подтверждения перейди сюда</a>
                                        </div>
                                        <TextFormikInput
                                            title="Код подтверждения"
                                            value={code}
                                            placeholder="Введите сюда код"
                                            wrapperClasses={['create-input']}
                                            onChangeValue={val => setCode(val)}
                                        />
                                    </div>
                                    { !load &&
                                    <div>
                                        <Button onClick={clickCodeSend}
                                        >Отправить</Button>
                                    </div>}
                                </div>
                            </div>
                    }
                        {error && <div className={"oauth-yandex__status-fail"}>{error}</div>}
                        {load && <div>Ожидание...</div>}
                        <div style={{marginTop: 10, color: "gray", fontSize: '0.7rem'}}>
                            <span>Refresh current token есть смысл вызывать, если до конца действия токена осталось менее 9 месяцев</span><br/>
                            <span>Токен обновляется автоматически раз в 4 месяца</span><br/>
                            <span>Токен может отвалиться в случаях: <a href="https://yandex.ru/dev/id/doc/dg/oauth/reference/token-invalidate.html">Описаны здесь</a></span>
                        </div>
                    </div>
                </div>
            </section>
        </NavbarLayout>
    );
}

export default OAuthYandex;
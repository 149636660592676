import React from "react";
import {Layout} from 'antd';
import NavbarLayout from "../../Components/Layout/NavbarLayout/NavbarLayout";
import MenuManage from "./components/Menu/Menu";
import ContentManage from "./components/Content/Content";

const ManagePage = () => {

    return (<NavbarLayout>
        <Layout>
            <Layout.Sider theme="light" style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 64,
                bottom: 0,
            }}><MenuManage/></Layout.Sider>
            <Layout.Content style={{marginLeft: 200, padding: 24, background: '#fff'}}>
                <ContentManage/>
            </Layout.Content>
        </Layout>
    </NavbarLayout>)
}

export default ManagePage
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ImgWithLoader from '../ImgWithLoader/ImgWithLoader'
import './DesignCard.scss'
import { ZoomInOutlined } from '@ant-design/icons'

type DesignCardProps = {
    title: string
    description: string
    href: string
    imgSrc: string
    alt?: string
    classModificator?: string
    activeClassName?: string
    onClick?: () => void
    onZoom?: (imgSrc: string) => void
}

const limitSizeDescroption = 50

const DesignCard: React.FC<DesignCardProps> = (props) => {
    const {
        title, description, href,
        imgSrc, alt, classModificator,
        activeClassName,
        onClick, onZoom
    } = props

    const [showText, setShowText] = useState<boolean>(false)

    const compressText = () => {
        if (showText || description.length <= limitSizeDescroption) {
            return description
        }
        return description.slice(0, limitSizeDescroption + 1) + '...'
    }
    const handleClickDescription = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setShowText(!showText)
    }

    const handleZoom = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (onZoom) onZoom(imgSrc)
    }

    const cardClasses = () => {
        const classes = ['design-card']
        if (classModificator) {
            const baseClass = 'design-card_'
            classes.push(baseClass + classModificator)
        }
        return classes.join(' ')
    }

    const descriptionClasses = () => {
        const classes = ['design-card__categories']
        if (description.length > limitSizeDescroption) classes.push('compress')
        return classes.join(' ')
    }

    return (
        <NavLink
            to={href}
            className={cardClasses()}
            activeClassName={activeClassName}
            onClick={onClick}
        >
            <div className="design-card__img">
                <ImgWithLoader
                    src={imgSrc}
                    alt={alt || 'designImg'}
                />
                {
                    onZoom &&
                    <div className="design-card__zoom" onClick={handleZoom}>
                        <ZoomInOutlined className="design-card__zoom-icon" />
                    </div>
                }
            </div>
            <p className="design-card__title">{title}</p>
            <p
                className={descriptionClasses()}
                onClick={handleClickDescription}
            >
                { compressText() }
            </p>
        </NavLink>
    )
}

export default DesignCard
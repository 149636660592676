import React from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './DeleteAdaptiveModal.scss'
import { Spin } from 'antd';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useUploadModal from '../../../utils/hooks/useUploadModal';


type DeleteAdaptiveModalProps = {
    isShow: boolean
    upload: ReturnType<typeof useUploadModal>
    onClose?: () => void
}

const DeleteAdaptiveModal: React.FC<DeleteAdaptiveModalProps> = (props) => {
    const {
        isShow, upload, onClose,
    } = props

    const handleClose = () => {
        if (onClose) onClose()
    }

    return (
        <FadeModal
            isShow={isShow}
            closeHandler={handleClose}
            timeout={400}
        >
            <div className="bf-delete-modal">
                <div className="bf-delete-modal__body">

                    {
                        upload.status === 'loading' &&
                        <Spin indicator={
                            <LoadingOutlined spin className="bf-delete-modal__loader" />
                        } />

                    }
                    {
                        upload.status === 'success' &&
                        <CheckOutlined className="bf-delete-modal__icon-success" />
                    }
                    {
                        upload.status === 'error' &&
                        <CloseOutlined className="bf-delete-modal__icon-error" />
                    }

                    <p className="bf-delete-modal__title">
                        {upload.title}
                    </p>
                    <p className="bf-delete-modal__description">
                        {upload.description}
                    </p>
                </div>
            </div>
        </FadeModal>
    )
}

export default DeleteAdaptiveModal
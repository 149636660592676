import React, { useRef, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './ImgWithLoader.scss'


type ImgWithLoaderProps = {
    src: string
    alt?: string
    loaderColor?: string
    loaderSize?: string
    wrapperClasses?: string[]
    imgClasses?: string[]
    spinClasses?: string[]
    onLoad?: () => void
}

const ImgWithLoader: React.FC<ImgWithLoaderProps> = (props) => {
    const {
        src, alt, wrapperClasses,
        imgClasses, spinClasses, loaderColor,
        loaderSize, onLoad,
    } = props


    const imgRef = useRef<HTMLImageElement>(null)
    const [isLoading, setLoading] = useState<boolean>(true)

    const imgLoadHandler = () => {
        setLoading(false)
        if (onLoad) onLoad()
    }

    let _wrapClasses = ['img-with-loader']
    if (wrapperClasses) _wrapClasses = _wrapClasses.concat(wrapperClasses)

    let _imageClasses = ['img-with-loader__img']
    if (imgClasses) _imageClasses = _imageClasses.concat(imgClasses)

    let _loaderClasses = ['img-with-loader__spin']
    if (spinClasses) _loaderClasses = _loaderClasses.concat(spinClasses)

    const spin = <LoadingOutlined
        className={_loaderClasses.join(' ')}
        style={{
            fontSize: loaderSize ? loaderSize : '40px',
            color: loaderColor ? loaderColor : '#00C0D1',
        }}
        spin
    />

    return (
        <div className={_wrapClasses.join(' ')}>
            <img
                src={src}
                alt={alt}
                ref={imgRef}
                className={_imageClasses.join(' ')}
                style={{ display: isLoading ? 'none' : 'block' }}
                onLoad={imgLoadHandler}
            />
            {
                isLoading
                    ? <Spin indicator={spin} />
                    : null
            }
        </div>
    )
}

export default ImgWithLoader

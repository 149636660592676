import React from 'react'
import './SizesRadios.scss'
import Radio from '../Radio/Radio'
import { CustomSize } from '../../../types/responseTypes'
import { SizeInputData } from '../../../types/types'
import RadioGroup from '../RadioGroup/RadioGroup'

type SizeRadiosProps = {
    width: string
    height: string
    unit: string
    sizes: CustomSize[]
    errorMessage?: string
    classes?: string[]
    onChange: (data: SizeInputData) => void
}

const SizesRadios: React.FC<SizeRadiosProps> = (props) => {

    const {
        width, height, unit,
        sizes, errorMessage, classes,
        onChange,
    } = props

    const changeHandler = (size: CustomSize) => {
        onChange({
            width: size.width,
            height: size.height,
            unit: size.unit,
            errorMessage: '',
        })
    }

    return (
        <RadioGroup
            title="Размер"
            classes={classes}
            errorMessage={errorMessage}
        >
            {
                sizes.map(o => {
                    const isChecked = o.width === width && o.height === height && o.unit === unit

                    const value = `${o.width}x${o.height}_${o.unit}`
                    return (
                        <li key={o._id}>
                            <Radio
                                name="bf-sizes-radio"
                                labelText={o.label}
                                value={value}
                                checked={isChecked}
                                classes={['bf-size-radio']}
                                onChange={() => changeHandler(o)}
                            />
                        </li>
                    )
                })
            }
        </RadioGroup>
    )
}

export default SizesRadios
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue} from "../../../../types/types";
import InputSelect from "../../../../Components/UI/Select/Select";
import {updateUrlQueries} from "../../../../utils/other";
import { Select } from 'antd'

const { Option } = Select

interface TagsProps extends RouteComponentProps{
    catalogData: CatalogFieldsData
    values: CatalogFilterFields
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
    setCatalogData: React.Dispatch<React.SetStateAction<CatalogFieldsData>>
}

const TagsField: React.FC<TagsProps> = React.memo((props) => {

    const {
        catalogData, values,
        location, history,
        setFieldValue, setCatalogData
    } = props

    const handleChange = (zoneIds: string | number | undefined) => {
        if (typeof zoneIds === 'string') {
            const newCatalogData = { ...catalogData }
            const arrayIds = zoneIds.split(',')

            if (arrayIds.includes('-1') || zoneIds === '') {
                updateUrlQueries(location, history, { 'tag_id': null })
                setFieldValue('tags', [])
                newCatalogData.tags = []
            } else {
                const checkedZones = values.tags.filter(z => arrayIds.includes(z.id + ''))
                updateUrlQueries(location, history, { 'tag_id': zoneIds })
                setFieldValue('tags', checkedZones)
                // newCatalogData.zones = values.source?.children || []
            }
            setCatalogData(newCatalogData)
        }
    }

    return (
        <InputSelect
            title="Теги"
            placeholder="Все теги"
            value={values.tags.map(z => z.id + '')}
            wrapClasses={['catalog-location-select']}
            multiple={true}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.tags?.map(c => {
                        return (
                            <Option value={c.id + ''} key={c.id + ''}>#{c.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default withRouter(TagsField)
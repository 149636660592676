export function lockScrollBody() {
    document.body.classList.add('lock')
}

export function unlockScrollBody() {
    document.body.classList.remove('lock')
}

export function isLockBody() {
    return document.body.classList.contains('lock')
}

export function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const forceBlur = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget
    target?.blur();
    setTimeout(() => target?.blur(), 1)
}
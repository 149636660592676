import React, {useCallback, useEffect, useRef, useState} from "react";
import {xxTags} from "../../../../../../../../types/responseTypes";
import {Button, Input} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import './style.css';

type TagItemProp = {
    tag: xxTags,
    isEdit: boolean,
    onDelete?: () => void,
    onClick?: () => void
    onSave?: (name: string) => void
}

const TagItem: React.FC<TagItemProp> = ({tag, isEdit, onDelete, onClick, onSave}) => {

    const [name, setName] = useState('');
    const [isDisableInput, setIsDisableInput] = useState(false);

    const pressKey = useCallback((e) => {
        console.log(e);
        if(e.code === 'Enter' || e.code === 'Space'){
            if(name) setIsDisableInput(true)
            onSave?.(name)
            e.preventDefault()
        }
    }, [name]);

    useEffect(() => {
        if(isEdit){
            setName(tag.name)
        }
        setIsDisableInput(false)
    }, [isEdit, tag]);

    return (<div className="tag-item__cont">
        <div className={"tag-item__main" + (isEdit ? ' tag-item__invisible' : '')}>
            <span onClick={onClick}># {tag.name}</span>
            <Button icon={<DeleteOutlined />} type="text" onClick={onDelete}/>
        </div>
        <div className={"tag-item__edit" +(!isEdit ? ' tag-item__invisible' : '')}>
            <Input disabled={isDisableInput} size="small" value={name} onKeyPress={pressKey} onChange={(e) => setName(e.target.value)}/>
        </div>
    </div>)
}

export default TagItem
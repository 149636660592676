import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthWaiting from '../../Components/auth/AuthWaiting/AuthWaiting'
import Auth from '../../Pages/Auth/Auth'
import CatalogPage from '../../Pages/CatalogPage/CatalogPage'
import DesignPage from '../../Pages/DesignPage/DesignPage'
import DesignPageHistory from '../../Pages/DesignPageHistory/DesignPageHistory'
import MainPage from '../../Pages/MainPage/MainPage'
import { NewDesignPage } from '../../Pages/NewDesignPage/NewDesignPage'
import GeneralsListPage from '../../Pages/GeneralsListPage/GeneralsListPage'
import { checkIsAuthUser } from '../../store/actions/AuthActions'
import { IRootState } from '../../store/reducers/rootReducer'
import { UserRole } from '../../types/stateTypes'
import PlaceCatalogPage from '../../Pages/PlaceCatalogPage/PlaceCatalogPage'
import GeneralCreatePage from '../../Pages/GeneralCreatePage/GeneralCreatePage'
import GeneralPage from '../../Pages/GeneralPage/GeneralPage'
import DesignGeneralPage from '../../Pages/DesignGeneralPage/DesignGeneralPage'
import PlaceRegisterPage from '../../Pages/PlaceRegisterPage/PlaceRegisterPage'
import PlaceRegisterCreate from '../../Pages/PlaceRegisterCreate/PlaceRegisterCreate'
import OAuthYandex from "../../Pages/OAuthYandex/OAuthYandex";
import ManagePage from "../../Pages/ManagePage/ManagePage";
import RegulationPage from "../../Pages/RegulationPage/RegulationPage";
import InvisiblePage from "../../Pages/InvisiblePage/InvisiblePage";

const MainRouter: React.FC<{}> = () => {
    const isAuth = useSelector<IRootState>(state => state.app.user.isAuth) as boolean
    const userRoles = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const dispatch = useDispatch()

    const hasRules = () => userRoles.includes('Admin') || userRoles.includes('Designer')

    useEffect(() => {
        if (!isAuth) dispatch(checkIsAuthUser())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isAuth && userRoles && userRoles.length > 0) {
        return (
            <Switch>
                <Route path="/prototype/main" exact component={MainPage} />
                <Route path="/prototype/notfound" exact component={InvisiblePage}/>
                <Route path="/prototype/catalog" exact component={CatalogPage} />
                <Route path="/prototype/catalog/:id" exact component={DesignPage} />
                <Route path="/prototype/catalog/:id/version/:versionId" exact component={DesignPage} />
                <Route path="/prototype/catalog/:id/version/:versionId/:adaptationId" exact component={DesignPage} />
                <Route path="/prototype/catalog/:id/:adaptationId" exact component={DesignPage} />
                <Route path="/prototype/history/:id" exact component={DesignPageHistory} />
                <Route path="/prototype/regulation" exact component={RegulationPage}/>
                {
                    userRoles.includes('Admin') &&
                        <Route path="/prototype/oauth" exact component={OAuthYandex}/>
                }
                { (userRoles.includes('Admin') || userRoles.includes('Designer')) &&
                    <Route path="/prototype/manage" exact component={ManagePage}/>
                }
                {
                    hasRules()
                        ? <Route path="/prototype/create" exact component={NewDesignPage} />
                        : null
                }

                {/* <Route path="/prototype/generals" exact component={GeneralsListPage} />
                <Route path="/prototype/generals/gen/:gereralId" exact component={GeneralPage} />
                <Route path="/prototype/generals/layout/:gereralId" exact component={DesignGeneralPage} />
                <Route path="/prototype/generals/create" exact component={GeneralCreatePage} />

                <Route path="/prototype/placeRegister" exact component={PlaceCatalogPage} />
                <Route path="/prototype/placeRegister/item/:id" exact component={PlaceRegisterPage} />
                <Route path="/prototype/placeRegister/create" exact component={PlaceRegisterCreate} /> */}
                <Redirect from="/" to="/prototype/main" />
            </Switch>
        )
    }

    if (isAuth === null) {
        return <AuthWaiting />
    }

    return (
        <Switch>
            <Route path="/auth" component={Auth} />
            <Redirect to="/auth" />
        </Switch>
    )
}

export default MainRouter
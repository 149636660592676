import { FormikErrors } from 'formik'
import React from 'react'
import { EditFileItem } from '../../../types/responseTypes'
import MultiplyAreaFiles from '../../MultiplyAreaFiles/MultiplyAreaFiles'
import MultiplyAreaFilesImg from '../../MultiplyAreaFilesImg/MultiplyAreaFilesImg'

type MultipleImgFilesFormikProps = {
    title: string
    value: File[]
    dragRect?: React.RefObject<HTMLElement>
    error?: FormikErrors<{}>
    clearErrorAfterChange?: boolean
    defaultFileContainer?: EditFileItem[]
    onChangeFiles: (files: File[]) => void
    onChangeError?: (error: string) => void
    onChangeDefault?: (files: EditFileItem[]) => void
}

const MultipleImgFilesFormik: React.FC<MultipleImgFilesFormikProps> = React.memo((props) => {

    const {
        value, error, title,
        clearErrorAfterChange = true,
        defaultFileContainer, dragRect,
        onChangeFiles, onChangeError,
        onChangeDefault,
    } = props

    const handleChange = (files: File[]) => {
        onChangeFiles(files)
        if (clearErrorAfterChange && onChangeError) onChangeError('')
    }

    const handleChangeDefault = (files: EditFileItem[]) => {
        if (onChangeDefault) onChangeDefault(files)
        if (clearErrorAfterChange && onChangeError) onChangeError('')
    }

    return (
        <MultiplyAreaFilesImg
            title={title}
            fileContainer={value}
            dragRect={dragRect}
            validExtensions={['png', 'jpg', 'jpeg']}
            defaultFileContainer={defaultFileContainer}
            errorMessage={error && error.toString()}
            setDefaultFileContainer={handleChangeDefault}
            setFileContainer={handleChange}
        />
    )
})

export default MultipleImgFilesFormik
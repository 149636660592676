import React, { useEffect } from 'react'
import { isDraggingOverElem } from '../other'

export interface DNDParams<DR, DA,> {
    dragArea: React.RefObject<DA>
    validFormats?: string[]
    onChangeFiles: (files: FileList) => void
    onErrorValidate?: (err: string) => void
    areaActiveClass?: string
    validate?: (files: FileList | undefined) => files is FileList
    dragRect?: React.RefObject<DR>
}

export const useDnd = <DR extends HTMLElement, DA extends HTMLElement>(options: DNDParams<DR, DA>, deps: any[]) => {
    const {
        dragRect,
        dragArea,
        validFormats = [],
        areaActiveClass = 'area-active',
        onChangeFiles,
        onErrorValidate,
        validate,
    } = options

    const validateFiles = (files: FileList | undefined): files is FileList => {
        let isValid = true
        if (!files || !files.length) return false

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (!(file instanceof File)) {
                if (onErrorValidate) onErrorValidate('Объект не является файлом')
                isValid = false
                break;
            }
            const shouldValidate = validFormats.length
            const isValidFormat = validFormats.some(vType => file.type.includes(vType))
            if (shouldValidate && !isValidFormat) {
                if (onErrorValidate) onErrorValidate(`Неверный формат файла, разрешенные форматы: ${validFormats.join(', ')}`)
                isValid = false
                break;
            }
        }

        return isValid
    }

    const dropAreaHandler = (e: DragEvent) => {
        e.preventDefault()
        // e.stopPropagation()
        const files = e.dataTransfer?.files
        if (validate) {
            if (validate(files)) onChangeFiles(files)
        } else {
            if (validateFiles(files)) onChangeFiles(files)
        }
        dragArea.current?.classList.remove(areaActiveClass)
    }

    const dropRectHandler = (e: DragEvent) => {
        e.preventDefault()
        dragArea.current?.classList.remove(areaActiveClass)
    }

    const dragRectDragenterHandler = (e: DragEvent) => {
        e.preventDefault()
        const hasAreaActiveClass = dragArea.current?.classList.contains('area-active')
        const isOverDragRect = isDraggingOverElem(dragRect || dragArea, e)
        if (isOverDragRect && !hasAreaActiveClass) dragArea.current?.classList.add('area-active')
    }
    const dragRectDragleaveHandler = (e: DragEvent) => {
        e.preventDefault()
        const isOverDragRect = isDraggingOverElem(dragRect || dragArea, e)
        if (!isOverDragRect) dragArea.current?.classList.remove('area-active')
    }
    //

    // DND
    useEffect(() => {
        const _rect = dragRect || dragArea
        _rect.current?.addEventListener('dragover', e => e.preventDefault())
        _rect.current?.addEventListener('dragenter', dragRectDragenterHandler)
        _rect.current?.addEventListener('dragleave', dragRectDragleaveHandler)
        if (_rect === dragRect) _rect.current?.addEventListener('drop', dropRectHandler)
        dragArea.current?.addEventListener('drop', dropAreaHandler)

        return () => {
            _rect.current?.removeEventListener('dragover', e => e.preventDefault())
            _rect.current?.removeEventListener('dragenter', dragRectDragenterHandler)
            _rect.current?.removeEventListener('dragleave', dragRectDragleaveHandler)
            if (_rect === dragRect) _rect.current?.removeEventListener('drop', dropRectHandler)
            dragArea.current?.removeEventListener('drop', dropAreaHandler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragArea, ...deps])
    //
}
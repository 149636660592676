import React, {useEffect, useState} from "react";
import {fileBaseUrl, getRegulations, tokenType} from "../../config";
import {freshAccessToken} from "../../utils/cookie/cookie";
import axios from "axios";
import {ResponseRegAllData, xxRegulation} from "../../types/responseTypes";
import {Divider, List, Spin} from "antd";
import NavbarLayout from "../../Components/Layout/NavbarLayout/NavbarLayout";
import './style.css';

const RegulationPage = () => {

    const [regs, setRegs] = useState<xxRegulation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let isUnmount = false
        async function updateData() {
            try {
                setLoading(true);
                const accessToken = await freshAccessToken()
                const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
                const resp = await axios.get(getRegulations, options)
                const respData = resp.data as ResponseRegAllData
                if(respData.success){
                    setRegs(respData.data);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false)
                console.log(e)
            }
        }
        if(!isUnmount) {
            updateData()
        }
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <NavbarLayout>
            <div style={{maxWidth: 800, margin: '24px auto 0 auto'}}>
                <div className="bf-regulation__title">Список регламентов {loading && <Spin/>}</div>
                {regs.length > 0 && <Divider style={{margin: 0}}/>}
                <List itemLayout="horizontal">
                    {regs.map((reg) => {
                        return(<List.Item actions={[
                            <a href={`${fileBaseUrl}/${reg.file}`} target="_blank" rel="noreferrer">Скачать файл</a>
                        ]}>
                            <List.Item.Meta title={reg.name} description={reg.description}/>
                        </List.Item>);
                    })}
                </List>
                {regs.length > 0 && <Divider style={{margin: 0}}/>}
            </div>
        </NavbarLayout>)
}

export default RegulationPage
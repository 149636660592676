export function sortFilesByName (files: File[]) {
    return files.sort((a, b) => a.name.localeCompare(b.name))
}

// export function sortObjectsByProp<T extends object>(target: T[], sortProp: keyof T, mod: 1 | -1) {
//     target.sort((a, b) => {
//         if (typeof a[sortProp] === 'string' && typeof b[sortProp] === 'string') {
//             typeof a[sortProp] === 'string' 
//             const aProp = a[sortProp] as string
//             const bProp = b[sortProp]
//             return a[sortProp].localeCompare(b[sortProp])
//         }
//     })
// }


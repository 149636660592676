import React, { useState } from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './ZoomModal.scss'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomControls } from '../../../types/types';
import {
    ZoomInOutlined, ZoomOutOutlined, FullscreenExitOutlined,
    EyeInvisibleOutlined, CloseCircleOutlined, EyeOutlined
} from '@ant-design/icons';
import ImgWithLoader from '../../ImgWithLoader/ImgWithLoader';


type ZoomModalProps = {
    isShow: boolean
    imgSrc: string
    close: () => void
}

const ZoomModal: React.FC<ZoomModalProps> = (props) => {
    const {
        isShow, imgSrc,
        close,
    } = props

    const [showControls, setShowControls] = useState<boolean>(true)

    const toggleShowControls = () => {
        setShowControls(!showControls)
    }

    const controlsClasses = ['zoom-modal__controls']
    if (!showControls) controlsClasses.push('hide')

    return (
        <FadeModal
            isShow={isShow}
            classes={['zoom-modal']}
            closeHandler={close}
            unmountAfterClose
        >
            <div className="zoom-modal__body">
                <div className="zoom-modal__img">
                    {
                        !showControls &&
                        <button onClick={toggleShowControls} className="zoom-modal__show-controls-btn">
                            <EyeOutlined className="zoom-modal__control-icon" />
                        </button>
                    }
                    <TransformWrapper
                        wheel={{
                            step: 10
                        }}
                        options={{
                            maxScale: 20
                        }}
                    >
                        {({ zoomIn, zoomOut, resetTransform }: ZoomControls) => (
                            <>
                                <div className={controlsClasses.join(' ')}>
                                    <button className="zoom-modal__control-btn" onClick={toggleShowControls}>
                                        <EyeInvisibleOutlined className="zoom-modal__control-icon" />
                                    </button>

                                    <div className="zoom-modal__controls-zoom">
                                        <button className="zoom-modal__control-btn" onClick={zoomIn}>
                                            <ZoomInOutlined className="zoom-modal__control-icon" />
                                        </button>
                                        <button className="zoom-modal__control-btn" onClick={zoomOut}>
                                            <ZoomOutOutlined className="zoom-modal__control-icon" />
                                        </button>
                                        <button className="zoom-modal__control-btn" onClick={resetTransform}>
                                            <FullscreenExitOutlined className="zoom-modal__control-icon" />
                                        </button>
                                    </div>

                                    <button className="zoom-modal__control-btn" onClick={close}>
                                        <CloseCircleOutlined className="zoom-modal__control-icon" />
                                    </button>
                                </div>
                                <TransformComponent>
                                    <ImgWithLoader
                                        src={imgSrc}
                                        alt="zoom-picture"
                                    />
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                </div>
            </div>
        </FadeModal>
    )
}

export default ZoomModal
import React from 'react'
import MaskedInput from 'react-text-mask'
import './Input.scss'


type InputType = {
    value?: string | number
    name?: string
    id?: string
    title?: string
    placeholder?: string
    errorMessage?: string
    classes?: string[]
    mask?: (string | RegExp)[]
    wrapperClasses?: string[]
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const Input: React.FC<InputType> = (props) => {

    const {
        title, id, name,
        value, classes, wrapperClasses,
        placeholder, errorMessage, mask,
        onChange, onInput,
    } = props

    let inputClasses = ['bf-input-text']
    let inputWrapperClasses = ['bf-input-text-wrapper']

    if (classes && classes.length > 0) {
        inputClasses = inputClasses.concat(classes)
    }
    if (wrapperClasses) {
        inputWrapperClasses = inputWrapperClasses.concat(wrapperClasses)
    }
    if (!title) {
        inputClasses.push('fullSize')
    }
    if (errorMessage) {
        inputClasses.push('error')
    }

    return (
        <div className={inputWrapperClasses.join(' ')}>
            <div className={inputClasses.join(' ')}>
                {
                    title
                        ? <p className="bf-input-text__title">{title}</p>
                        : null
                }
                {
                    mask
                        ? <MaskedInput
                            mask={mask}
                            type="text"
                            placeholder={placeholder}
                            id={id}
                            name={name}
                            value={value}
                            className="bf-input-text__input"
                            onChange={onChange}
                            onInput={onInput}
                        />
                        : <input
                            type="text"
                            placeholder={placeholder}
                            id={id}
                            name={name}
                            value={value}
                            className="bf-input-text__input"
                            onChange={onChange}
                            onInput={onInput}
                        />
                }
            </div>
            {
                errorMessage
                    ? <p className="bf-input-error-message">{errorMessage}</p>
                    : null
            }
        </div>
    )
}

export default Input
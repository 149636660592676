import React from 'react'
import { CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import SwitchCheckbox from '../../../../Components/UI/SwitchCheckbox/SwitchCheckbox'
import { RouteComponentProps, withRouter } from 'react-router'
import { updateUrlQueries } from '../../../../utils/other'

interface OrientationFieldProps extends RouteComponentProps {
    values: CatalogFilterFields
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const OrientationField: React.FC<OrientationFieldProps> = React.memo((props) => {

    const {
        values, location, history,
        setFieldValue,
    } = props

    const handleChange = (value: boolean) => {
        updateUrlQueries(location, history, {
            'is_adaptive': value ? value + '' : null
        })
        setFieldValue('isAdaptation', value)
    }

    return (
        <SwitchCheckbox
            text="Нужна адаптация"
            checked={values.isAdaptation}
            classes={['bf-create-adaptive']}
            onChange={e => handleChange(e?.target.checked || false)}
        />
    )
})

export default withRouter(OrientationField)
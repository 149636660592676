import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import { Select } from 'antd'
import { updateUrlQueries } from '../../../../utils/other'
import { RouteComponentProps, withRouter } from 'react-router'
import { deleteCookie, setCookie } from '../../../../utils/cookie/cookie'
import { cityCookieName, clubCookieName } from '../../../../config'
const { Option } = Select

interface CityFieldProps extends RouteComponentProps {
    catalogData: CatalogFieldsData
    setCatalogData: React.Dispatch<React.SetStateAction<CatalogFieldsData>>
    values: CatalogFilterFields
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const CityField: React.FC<CityFieldProps> = React.memo((props) => {

    const {
        catalogData, values,
        location, history,
        setFieldValue, setCatalogData,
    } = props

    const handleChange = (cityId: string | number | undefined) => {
        if (cityId) {
            const city = catalogData.cities.find(c => c.id === +cityId)
            const isClear = +cityId === -1
            if (isClear || city) {
                if (isClear) {
                    updateUrlQueries(location, history, {
                        'city_id': null,
                        'club_id': null
                    })
                    setFieldValue('city', null)
                    setFieldValue('club', null)
                    deleteCookie(cityCookieName)
                    deleteCookie(clubCookieName)
                } else if (city) {
                    updateUrlQueries(location, history, {
                        'city_id': cityId + '',
                        'club_id': null
                    })
                    setFieldValue('city', city)
                    if (values.club) setFieldValue('club', null)
                    setCookie(cityCookieName, cityId + '', 2592000)
                }

                setCatalogData(data => ({
                    ...data,
                    clubs: []
                }))
            }
        }
    }

    return (
        <InputSelect
            title="Город"
            placeholder="Все города"
            value={values.city?.name || null}
            wrapClasses={['catalog-location-select']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.cities.map(c => {
                        return (
                            <Option value={c.id + ''} key={c.id + ''}>{c.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default withRouter(CityField)
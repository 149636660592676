import React from 'react'
import InputSelect from '../../../../../Components/UI/Select/Select'
import { Select } from 'antd'
import { ModalEditLayoutFields, FormikSetFieldValue } from '../../../../../types/types'
const { Option } = Select

type ZonesFieldProps = {
    value: ModalEditLayoutFields['zones']
    valueSource: ModalEditLayoutFields['source']
    setFieldValue: FormikSetFieldValue<ModalEditLayoutFields>
}

const ZonesField: React.FC<ZonesFieldProps> = React.memo(({ value, valueSource, setFieldValue }) => {

    const handleChange = (zoneIds: string | number | undefined) => {
        if (typeof zoneIds === 'string') {
            const arrayIds = zoneIds.split(',')
            if (arrayIds.includes('-1')) return setFieldValue('zones', [])
            const zones = valueSource?.children?.filter(z => arrayIds.includes(z.id + ''))
            if (zones) setFieldValue('zones', zones)
        }
    }

    return (
        <InputSelect
            title="Зоны"
            placeholder="Выберите зону"
            value={value.map(z => z.id + '')}
            disabled={!valueSource}
            wrapClasses={['create-select', 'right-offset']}
            multiple={true}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    valueSource?.children?.map(z => {
                        return (
                            <Option value={z.id + ''} key={z.id}>{z.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default ZonesField
import React from 'react'
import { CreateLayoutFields, FormikSetFieldValue } from '../../../../types/types'
import RadioGroup from '../../../../Components/UI/RadioGroup/RadioGroup'
import Radio from '../../../../Components/UI/Radio/Radio'

type LayoutTypeFieldProps = {
    value: CreateLayoutFields['layoutType']
    valuePlacement: CreateLayoutFields['placement']
    valueDecoration: CreateLayoutFields['_isDecoration']
    setFieldValue: FormikSetFieldValue<CreateLayoutFields>
}

const LayoutTypeField: React.FC<LayoutTypeFieldProps> = React.memo(({ value, valuePlacement, valueDecoration, setFieldValue }) => {

    const layoutTypesByPlacement = () => {
        const layout_temp = valuePlacement?.layout_temp
        if (layout_temp) {
            const items = layout_temp.items
            if (items && items.length > 0) {
                return items[0].children || []
            }
        }
        return []
    }

    const handleChange = (layoutTypeId: string | undefined) => {
        const newLayoutType = layoutTypesByPlacement().find(t => t.id + '' === layoutTypeId)
        if (newLayoutType) {
            setFieldValue('layoutType', newLayoutType)
        }
    }

    if (!valueDecoration) return null

    return (
        <RadioGroup
            title="Тип сервисных макетов"
            classes={['bf-create__type']}
        >
            {
                layoutTypesByPlacement().map(type => (
                    <li key={type.id}>
                        <Radio
                            labelText={type.name}
                            value={type.id + ''}
                            checked={value?.id === type.id}
                            classes={['designs-types']}
                            name="designs-types"
                            onChange={e => handleChange(e?.target.value)}
                        />
                    </li>
                ))
            }
        </RadioGroup>
    )
})

export default LayoutTypeField
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { lockScrollBody, unlockScrollBody } from '../../../utils/styleFunctions/styleFunctions'
import './FadeModal.scss'

type FadeModalProps = {
    isShow: boolean
    classes?: string[]
    timeout?: number
    unmountAfterClose?: boolean
    closeHandler: () => void
}

const FadeModal: React.FC<FadeModalProps> = (props) => {
    const {
        isShow, classes, timeout = 400,
        unmountAfterClose = true, children,
        closeHandler
    } = props

    let modalClasses = ['fademodal']

    if (classes) {
        modalClasses = classes.concat(modalClasses)
    }

    useEffect(() => {
        if (isShow) {
            lockScrollBody()
        } else {
            unlockScrollBody()
        }
    }, [isShow])

    return (
        <CSSTransition
            in={isShow}
            timeout={timeout}
            classNames={{
                enter: 'fademodal-enter',
                enterDone: 'fademodal-done-enter',
                exit: 'fademodal-exit',
                exitDone: 'fademodal-done-exit',
            }}
            unmountOnExit={unmountAfterClose}
        >
            <section className={modalClasses.join(' ')} id="bf-fade-modal">
                <div className="fademodal__overlay" onClick={closeHandler}></div>
                {children}
            </section>
        </CSSTransition>
    )
}

export default FadeModal
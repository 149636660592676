import React from 'react'
import InputSelect from '../../../../Components/UI/Select/Select'
import { CatalogFieldsData, CatalogFilterFields, FormikSetFieldValue } from '../../../../types/types'
import { Select } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import { updateUrlQueries } from '../../../../utils/other'
import { deleteCookie, setCookie } from '../../../../utils/cookie/cookie'
import { cityCookieName, clubCookieName } from '../../../../config'
const { Option } = Select

interface ClubFieldProps extends RouteComponentProps {
    catalogData: CatalogFieldsData
    values: CatalogFilterFields
    setFieldValue: FormikSetFieldValue<CatalogFilterFields>
}

const ClubField: React.FC<ClubFieldProps> = React.memo((props) => {

    const {
        catalogData, values,
        location, history,
        setFieldValue,
    } = props

    const handleChange = (clubId: string | number | undefined) => {
        if (clubId) {
            const club = catalogData.clubs.find(c => c.id === +clubId)
            const isClear = +clubId === -1
            if (isClear) {
                updateUrlQueries(location, history, {
                    'club_id': null,
                })
                setFieldValue('club', null)
                deleteCookie(clubCookieName)
            } else if (club) {
                updateUrlQueries(location, history, {
                    'club_id': clubId + '',
                })
                setFieldValue('club', club)
                setCookie(clubCookieName, clubId + '', 2592000)
                if (values.city) setCookie(cityCookieName, values.city.id + '', 2592000)
            }
        }
    }

    return (
        <InputSelect
            title="Клуб"
            placeholder="Все клубы"
            value={values.club?.name || null}
            wrapClasses={['catalog-location-select']}
            onChange={handleChange}
        >
            <>
                <Option value="-1" key="-1" className="clear-option">Очистить</Option>
                {
                    catalogData.clubs.map(club => {
                        return (
                            <Option value={club.id} key={club.id}>{club.name}</Option>
                        )
                    })
                }
            </>
        </InputSelect>
    )
})

export default withRouter(ClubField)
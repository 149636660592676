import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import DesignCard from '../../Components/DesignCard/DesignCard'
import DesignCardFull from '../../Components/DesignCardFull/DesignCardFull'
import NavbarLayout from '../../Components/Layout/NavbarLayout/NavbarLayout'
import EditAdaptiveModal from '../../Components/Modals/EditAdaptiveModal/EditAdaptiveModal'
import EditLayoutModal from '../../Components/Modals/EditLayoutModal/EditLayoutModal'
import FilesModal from '../../Components/Modals/FilesModal/FilesModal'
import ModalLoadLayout from '../../Components/Modals/ModalLoadLayout/ModalLoadLayout'
import ZoomModal from '../../Components/Modals/ZoomModal/ZoomModal'
import SlotProvider from '../../Components/Slot/SlotProvider/SlotProvider'
import Specifications from '../../Components/Specifications/Specifications'
import {
    getLayoutByIdUrl,
    tokenType,
    insertAdaptiveUrl,
    deleteAdaptiveUrl,
    deleteLayoutUrl,
    getEditLayoutDataUrl
} from '../../config'
import { IRootState } from '../../store/reducers/rootReducer'
import {
    LayoutPageData,
    SpecData,
    LayoutPageDataInfo,
    LastAdaptive,
    LayoutAdaptivesData, AdaptivesVersionData, ChipLayout, ResponseSuccess
} from '../../types/responseTypes'
import { SpecificationDesign, UserRole } from '../../types/stateTypes'
import { freshAccessToken } from '../../utils/cookie/cookie'
import useScrollToTop from '../../utils/hooks/useScrollToTop'
import { useZoomModal } from '../../utils/hooks/useZoomModal'
import {buildSearchParams, openNotification, parseSpecParam} from '../../utils/other'
import { scrollToTop, unlockScrollBody } from '../../utils/styleFunctions/styleFunctions'
import './DesignPage.scss'
import {Breadcrumb, Popconfirm} from 'antd';
import useUploadModal from '../../utils/hooks/useUploadModal'
import DeleteAdaptiveModal from '../../Components/Modals/DeleteAdaptiveModal/DeleteAdaptiveModal'

type ParamsType = {
    id: number
    versionId: number,
    adaptationId: number
}

interface DesignPageProps extends RouteComponentProps { }

const DesignPage: React.FC<DesignPageProps> = ({ history, match , location}) => {
    const params = match.params as ParamsType
    console.log(params.versionId)
    const userRole = useSelector<IRootState>(state => state.app.user.roles) as UserRole[]
    const zoom = useZoomModal()
    const upload = useUploadModal()

    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string | null>('')
    const [chips, setChips] = useState<ChipLayout>();
    const [visible, setVisible] = useState<boolean | undefined>(undefined);
    const [layoutSpec, setLayoutSpec] = useState<SpecificationDesign[]>([])
    const [adaptiveSpec, setAdaptiveSpec] = useState<SpecificationDesign[]>([])
    const [lastVersionId, setLastVersionId] = useState<number>(0)

    const [adaptations, setAdaptations] = useState<LastAdaptive[]>([])
    const [currentAdaptation, setCurrentAdaptation] = useState<LastAdaptive | null>(null)

    const [placementId, setPlacementId] = useState<number>(0)

    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [showOriginalFilesModal, setShowOriginalFilesModal] = useState<boolean>(false)
    const [showPrintFilesModal, setShowPrintFilesModal] = useState<boolean>(false)
    const [showEditLayoutModal, setShowEditLayoutModal] = useState<boolean>(false)
    const [showEditAdaptiveModal, setShowEditAdaptiveModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const hasAccess = () => userRole.includes('Admin') || userRole.includes('Designer')
    const isAdmin = () => userRole.includes('Admin')
    const onlyCustomer = () => userRole.includes('Customer') && !hasAccess()

    useEffect(() => {
        if(!hasAccess() && visible === false){
            history.replace("/prototype/notfound");
        }
    }, [visible, history, userRole]);

    const updatePageFromData = (data: LayoutPageDataInfo) => {
        // если указан id адаптации, то кидаем эту адаптацию в state
        let adaptationFromParams = data.last_adaptives.find(a => +a.id === +params.adaptationId)

        if (!adaptationFromParams) {
            // если не указан id адаптации, то закидываем первую адаптацию в state и редиректимся на ее id
            adaptationFromParams = data.last_adaptives[0]
            if (adaptationFromParams) history.replace(`/prototype/catalog/${params.id}/${adaptationFromParams.id}`)
            console.log('SET! from replace')
            return
        }
        updateSpecifications(adaptationFromParams)
        setCurrentAdaptation(adaptationFromParams)
        setTitle(data.name)
        setDescription(adaptationFromParams.desc)
        console.log(data.chips);
        setChips(data.chips);
        setVisible(data.is_visible);
        setAdaptations(data.last_adaptives)
        setLastVersionId(data.last_version_id)
        setPlacementId(data.placement_id)
    }

    const updatePageFromDataVersion = (layout: LayoutPageDataInfo, data: AdaptivesVersionData) => {
        let adaptationFromParams = data.adaptives.find(a => +a.id === +params.adaptationId)

        if (!adaptationFromParams) {
            // если не указан id адаптации, то закидываем первую адаптацию в state и редиректимся на ее id
            adaptationFromParams = data.adaptives[0]
            if (adaptationFromParams) history.replace(`/prototype/catalog/${params.id}/version/${params.versionId}/${adaptationFromParams.id}`)
            console.log('SET! from replace')
            return
        }
        updateSpecifications(adaptationFromParams)
        setCurrentAdaptation(adaptationFromParams)
        setTitle(layout.name)
        setDescription(adaptationFromParams.desc)
        setAdaptations(data.adaptives)
        setLastVersionId(layout.last_version_id)
        setPlacementId(layout.placement_id)
    }

    const updateSpecifications = (adaptation: LastAdaptive) => {
        const { sizes, orientation } = adaptation
        const parsedUnit = parseSpecParam(sizes.unit)
        const newSpecifications: SpecificationDesign[] = [
            {
                title: 'Ориентация',
                value: parseSpecParam(orientation)
            }
        ]
        if (sizes.width) newSpecifications.push({
            title: 'Ширина',
            value: `${sizes.width} ${parsedUnit}`
        })
        if (sizes.height) newSpecifications.push({
            title: 'Высота',
            value: `${sizes.height} ${parsedUnit}`
        })

        setAdaptiveSpec(newSpecifications)
    }

    const redirectAfterDeleteAdaptive = (deleteId: number) => {
        const withoutDeleted = adaptations.filter(adapt => adapt.id !== deleteId)
        history.replace(`/prototype/catalog/${params.id}/${withoutDeleted[0].id}`)
    }

    const handleDeleteAdaptive = () => {
        if (isAdmin() && adaptations.length > 1) {
            return fetchDeleteAdaptive()
        }
        if (adaptations.length <= 1) openNotification('Ошибка', 'error', 'Невозможно удалить единственную адаптацию')
    }

    const handleDeleteLayout = () => {
        if (!isAdmin()) return openNotification('Ошибка', 'error', 'Недостаточно прав!')
        fetchDeleteLayout()
    }

    const closeModalAdaptation = useCallback(() => {
        setShowAddModal(false)
        unlockScrollBody()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setShowAddModal, unlockScrollBody])

    const redirectToLayuotId = useCallback(() => {
        return history.replace(`/prototype/catalog/${params.id}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])
    const closeEditModal = useCallback(() => setShowEditLayoutModal(false), [setShowEditLayoutModal])
    const closeEditAdaptiveModal = useCallback(() => setShowEditAdaptiveModal(false), [setShowEditAdaptiveModal])

    const fetchDeleteAdaptive = async () => {
        if (!currentAdaptation?.id) return false
        if (!isAdmin()) return openNotification('Ошибка', 'error', 'Недостаточно прав!')
        try {
            setShowDeleteModal(true)
            const deleteId = currentAdaptation.id
            upload.loading('Подождите', 'Выполняется удаление адаптации')
            const token = await freshAccessToken()
            const option = { headers: { 'Authorization': `${tokenType} ${token}` } }
            const res = await axios.post(`${deleteAdaptiveUrl}/${deleteId}`, option)
            console.log('DELETED', res);
            if (res.data.success) {
                upload.success('Готово', 'Адаптация успешно удалена')
                redirectAfterDeleteAdaptive(deleteId)
                return
            }
            upload.error('Ошибка', 'Не удалось удалить адаптацию')
        } catch (e) {
            console.error('Ошибка при удалении адаптации', e.message)
            upload.error('Ошибка', e.message)
        } finally {
            setTimeout(() => {
                scrollToTop()
                setShowDeleteModal(false)
            }, 2000);
            setTimeout(upload.reset, 2600)
        }
    }

    const fetchDeleteLayout = async () => {
        try {
            setShowDeleteModal(true)
            upload.loading('Подождите', 'Выполняется удаление макета')
            const token = await freshAccessToken()
            const option = { headers: { 'Authorization': `${tokenType} ${token}` } }
            const res = await axios.post(`${deleteLayoutUrl}/${params.id}`, option)
            console.log('DELETED LAYOUT', res);
            if (res.data.success) {
                upload.success('Готово', 'Макет успешно удален')
                return
            }
            upload.error('Ошибка', 'Не удалось удалить макет')
        } catch (e) {
            console.error('Ошибка при удалении макета', e.message)
            upload.error('Ошибка', e.message)
        } finally {
            setTimeout(() => {
                scrollToTop()
                setShowDeleteModal(false)
                history.push('/')
            }, 2000)
        }
    }

    useEffect(() => {
        let isUnmount = false
        async function updateData() {
            try {
                let url = `${getLayoutByIdUrl}/${+params.id}`
                if(params.versionId){
                    url+= '/version/'+params.versionId;
                }
                const accessToken = await freshAccessToken()
                const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
                const resp = await axios.get(url, options)
                if(params.versionId){
                    const respData = resp.data as LayoutAdaptivesData
                    console.log('RES!', respData)
                    if (respData && !isUnmount) {
                        updatePageFromDataVersion(respData.layout, respData.data);
                    }
                }else {
                    const respData = resp.data as LayoutPageData
                    console.log('RES!', respData)
                    if (respData && !isUnmount) {
                        updatePageFromData(respData.data)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        updateData()
        return () => { isUnmount = true }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params])

    const handleVisible = useCallback(async () => {
        const vis = !visible || false;
        setVisible(vis)
        try {
            const accessToken = await freshAccessToken()
            const options = { headers: { 'Authorization': `${tokenType} ${accessToken}` } }
            const resp = await axios.post(`${getEditLayoutDataUrl}/${+params.id}/visible`,{visible: +vis}, options)
            const respData = resp.data as ResponseSuccess;
            if(!respData.success){
                setVisible(!vis)
            }
        } catch (e) {
            console.log(e)
        }
    }, [visible]);

    useScrollToTop()

    return (
        <NavbarLayout>
            <section className="bf-design">
                <div className="bf-container">
                    <div className="bf-design__content">
                        <div style={{marginBottom: 12}}>
                            <Breadcrumb>
                                {chips?.placement && <Breadcrumb.Item>
                                    <a href={window.location.origin+'/prototype/catalog?'+buildSearchParams(chips, 0)}>{chips?.placement.name}</a>
                                </Breadcrumb.Item>}
                                {chips?.placement && chips.source && <Breadcrumb.Item>
                                    <a href={window.location.origin+'/prototype/catalog?'+buildSearchParams(chips, 1)}>{chips?.source.name}</a>
                                </Breadcrumb.Item>}
                                {chips?.placement && chips.source && chips.zone && <Breadcrumb.Item>
                                    <a href={window.location.origin+'/prototype/catalog?'+buildSearchParams(chips, 2)}>{chips?.zone.map((zn) => zn.name).join(', ')}</a>
                                </Breadcrumb.Item>}
                            </Breadcrumb>
                        </div>
                        <DesignCardFull
                            otherPreviews={currentAdaptation?.other_previews || []}
                            title={title}
                            description={description || ''}
                            showTitleSpec={false}
                            imgSrc={currentAdaptation?.preview || ''}
                            onZoom={zoom.open}
                        >
                            {/* <SlotProvider slot="beforeSpecifications">
                                <SwitchCheckbox
                                    text="Актуален"
                                    checked={true}
                                    disabled={true}
                                    classes={['bf-actual']}
                                />
                            </SlotProvider> */}

                            <SlotProvider slot="specifications">
                                <Specifications
                                    data={[...adaptiveSpec, ...layoutSpec]}
                                    isShow={true}
                                    showTitle={false}
                                />
                            </SlotProvider>

                            {
                                onlyCustomer()
                                    ? <SlotProvider slot="info">
                                        <button
                                            className="bf-design__info-btn bf-btn bf-btn-primary"
                                            onClick={() => setShowPrintFilesModal(true)}
                                        >
                                            Итоги на печать
                                        </button>
                                    </SlotProvider>
                                    : <></>
                            }

                            {
                                hasAccess()
                                    ? <SlotProvider slot="belowImg">
                                        <div className="bf-design__func-btn-group">
                                            {!params.versionId && <button
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                                onClick={() => setShowEditLayoutModal(true)}
                                            >
                                                Редактировать макет
                                            </button>}
                                            {!params.versionId &&<button
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                                onClick={() => setShowEditAdaptiveModal(true)}
                                            >
                                                Редактировать адаптацию
                                            </button>}
                                            <button
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                                onClick={() => setShowOriginalFilesModal(true)}
                                            >
                                                Дизайнерские исходники
                                            </button>
                                            <button
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                                onClick={() => setShowPrintFilesModal(true)}
                                            >
                                                Итоги на печать
                                            </button>
                                            <Link
                                                to={`/prototype/history/${params.id}`}
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                            >
                                                История версий
                                            </Link>
                                            {!params.versionId && <button
                                                className="bf-design__func-btn bf-btn bf-btn-gray"
                                                onClick={() => setShowAddModal(true)}
                                            >
                                                Добавить адаптацию
                                            </button>}
                                            {visible !== undefined && <button
                                                className={"bf-design__func-btn bf-btn "+(visible ? "bf-btn-primary" : "bf-btn-danger")}
                                                onClick={handleVisible}>
                                                {visible ? 'Включен' : 'Выключен'}
                                            </button>}
                                            {
                                                isAdmin() && adaptations.length > 1 && !params.versionId &&
                                                <Popconfirm
                                                    title="Вы уверены?"
                                                    okText="Да"
                                                    cancelText="Отмена"
                                                    onConfirm={handleDeleteAdaptive}
                                                >
                                                    <button className="bf-design__func-btn bf-btn bf-btn-danger">
                                                        Удалить текущую адаптацию
                                                    </button>
                                                </Popconfirm>
                                            }
                                            {
                                                isAdmin() && !params.versionId &&
                                                <Popconfirm
                                                    title="Вы уверены?"
                                                    okText="Да"
                                                    cancelText="Отмена"
                                                    onConfirm={handleDeleteLayout}
                                                >
                                                    <button className="bf-design__func-btn bf-btn bf-btn-danger">
                                                        Удалить макет
                                                    </button>
                                                </Popconfirm>
                                            }
                                        </div>
                                    </SlotProvider>
                                    : <></>
                            }
                        </DesignCardFull>

                        <div className="bf-design__alternative">
                            <p className="bf-design__alternative-title">Адаптации</p>
                            <div className="bf-design__alternative-items">
                                {
                                    adaptations.map(a => (
                                        <DesignCard
                                            key={a.id}
                                            title={title}
                                            description={a.desc || ''}
                                            href={params.versionId ? `/prototype/catalog/${params.id}/version/${params.versionId}/${a.id}` : `/prototype/catalog/${params.id}/${a.id}`}
                                            imgSrc={a.preview}
                                            activeClassName="active"
                                            onClick={scrollToTop}
                                            onZoom={zoom.open}
                                        />
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <FilesModal
                isShow={showOriginalFilesModal}
                files={currentAdaptation?.original || []}
                title="Дизайнерские исходники"
                description="Не забудьте переименовать файлы после скачивания"
                closeHandler={() => { setShowOriginalFilesModal(false) }}
            />
            <FilesModal
                isShow={showPrintFilesModal}
                files={currentAdaptation?.prints || []}
                title="Файлы на печать"
                description="Не забудьте переименовать файлы после скачивания"
                closeHandler={() => { setShowPrintFilesModal(false) }}
            />
            <ModalLoadLayout
                isShow={showAddModal}
                title='Добавить адаптацию'
                uploadUrl={insertAdaptiveUrl}
                version_id={lastVersionId}
                placement_id={placementId}
                closeHandler={closeModalAdaptation}
                afterSuccessUpload={redirectToLayuotId}
            />
            <EditLayoutModal
                isShow={showEditLayoutModal}
                titleModal="Редактирование макета"
                layout_id={params.id}
                specifications={layoutSpec}
                setSpecifications={setLayoutSpec}
                closeHandler={closeEditModal}
                afterSuccessUpload={redirectToLayuotId}
            />
            <EditAdaptiveModal
                isShow={showEditAdaptiveModal}
                titleModal="Редактирование адаптации"
                adaptation_id={params.adaptationId}
                placement_id={placementId}
                closeHandler={closeEditAdaptiveModal}
                afterSuccessUpload={redirectToLayuotId}
            />
            <ZoomModal {...zoom} />
            <DeleteAdaptiveModal
                isShow={showDeleteModal}
                upload={upload}
            />
        </NavbarLayout>
    )
}

export default DesignPage
import React from 'react'
import { Link } from 'react-router-dom'

type ImageBlockMediumProps = {
    title: string
    href: string
    imgSrc: string
    altImg?: string
}
const ImageBlockMedium: React.FC<ImageBlockMediumProps> = ({ title, href, imgSrc, altImg }) => {

    return (
        <div className="main-categories__half main-categories__half_club-outside">
            <div className="main-categories__bg-img bg-cover">
                <img src={imgSrc} alt={altImg || title}/>
            </div>
            <div className="main-categories__half-footer">
                <Link to={href}>
                    <p className="main-categories__half-title">{title}</p>
                </Link>

                <Link to={href}>
                    <div className="circle-arrow-lg"></div>
                </Link>
            </div>
        </div>
    )
}

export default ImageBlockMedium
import { CustomSize, Orientation } from './../types/responseTypes';
import { xxCatalogTemplate } from "../types/responseTypes"
import { clearReqDate, parseRequiredDate } from './parsers';

export const orientationsByPlacement = (placement: xxCatalogTemplate | null, orientations: Orientation[]) => {
    if (placement) {
        return orientations.filter(o => o.catalogId.includes(placement.id))
    }
    return []
}

export const orientationsByPlacementId = (id: number, orientations: Orientation[]) => {
    return orientations.filter(o => o.catalogId.includes(id))
}

export const customSizesByPlacement = (placement: xxCatalogTemplate | null, customSizes: CustomSize[]) => {
    if (placement) {
        return customSizes.filter(s => s.catalogId.includes(placement.id))
    }
    return []
}

export const customSizesByPlacementId = (id: number, customSizes: CustomSize[]) => {
    return customSizes.filter(s => s.catalogId.includes(id))
}

export const validateRequiredDates = (errors: {[key: string]: string}, isRequired: boolean, rowFrom: string | null, rowTo: string | null) => {
    if (isRequired) {
        const from = clearReqDate(rowFrom)
        const to = clearReqDate(rowTo)

        if (from === null || from.length !== 8) {
            errors.requiredFrom = 'Неверный формат даты: ГГГГ-ММ-ДД'
            return
        }
        if (to === null || to.length !== 8) {
            errors.requiredTo = 'Неверный формат даты: ГГГГ-ММ-ДД'
            return
        }

        const fromDate = parseRequiredDate(from || '')
        if (!fromDate.month) errors.requiredFrom = 'Значения месяца от 1 до 12'
        if (fromDate.month && (fromDate.month < 1 || fromDate.month > 12)) errors.requiredFrom = 'Значения месяца от 1 до 12'
        if (!fromDate.day) errors.requiredFrom = 'Значения дня от 1 до 31'
        if (fromDate.day && (fromDate.day < 1 || fromDate.day > 31)) errors.requiredFrom = 'Значения дня от 1 до 31'

        const toDate = parseRequiredDate(to || '')
        if (!toDate.month) errors.requiredTo = 'Значения месяца от 1 до 12'
        if (toDate.month && (toDate.month < 1 || toDate.month > 12)) errors.requiredTo = 'Значения месяца от 1 до 12'
        if (!toDate.day) errors.requiredTo = 'Значения дня от 1 до 31'
        if (toDate.day && (toDate.day < 1 || toDate.day > 31)) errors.requiredTo = 'Значения дня от 1 до 31'

        if (Number(from) > Number(to)) errors.requiredFrom = 'Дата начала больше даты окончания'
        if (!clearReqDate(from)) errors.requiredFrom = 'Дата должна содержать только цифры'
        if (!clearReqDate(to)) errors.requiredTo = 'Дата должна содержать только цифры'
    }
}
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import DesignCard from '../../../Components/DesignCard/DesignCard'
import { LayoutPreview } from '../../../types/responseTypes'
import './LastLayoutsBlock.scss'

type LastLayoutsBlockProps = {
    lastLayouts: LayoutPreview[]
    isLoadedLayouts: boolean
    onZoom?: (imgSrc: string) => void
}

const spin = <LoadingOutlined className='auth-waiting__spin' spin />

const LastLayoutsBlock: React.FC<LastLayoutsBlockProps> = ({ lastLayouts, isLoadedLayouts, onZoom }) => {

    const handleZoom = (imgSrc: string) => {
        if (onZoom) onZoom(imgSrc)
    }

    return (
        <>
            <p className="last-makets__title">Последние добавленые макеты</p>
            <div className="last-makets__items-continer">
                {
                    !isLoadedLayouts
                        ? <div className="last-makets__loader">
                            <Spin indicator={spin} />
                        </div>
                        : null
                }

                {
                    isLoadedLayouts
                        ? lastLayouts.length
                            ? lastLayouts.map(l => (
                                <DesignCard
                                    key={l.id}
                                    title={l.name}
                                    description={l.desc_to_preview}
                                    href={`/prototype/catalog/${l.id}`}
                                    imgSrc={l.preview}
                                    classModificator="main-page-item"
                                    onZoom={handleZoom}
                                />
                            ))
                            : <div className="last-makets__empty-text">
                                <p>Список макетов пуст</p>
                            </div>
                        : null
                }
            </div>
        </>
    )
}

export default LastLayoutsBlock
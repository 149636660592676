import React from 'react'
import './RadioBtnGroup.scss'

type RadioBtnGroupProps = {
    classes?: string[]
    errorClasses?: string[]
    errorMessage?: string
}

const RadioBtnGroup: React.FC<RadioBtnGroupProps> = (props) => {

    const {
        errorMessage,
        errorClasses,
        classes,
        children,
    } = props

    let wrapClasses = ['radio-btn-group']

    if (classes) wrapClasses = wrapClasses.concat(classes)
    if (errorClasses && errorMessage) wrapClasses = wrapClasses.concat(errorClasses)

    return (
        <div className={wrapClasses.join(' ')}>
            <div className={errorMessage ? "error" : ""}>
                {
                    children
                }
            </div>
            {
                errorMessage
                    ? <p className="bf-input-error-message">{errorMessage}</p>
                    : null
            }
        </div>
    )
}

export default RadioBtnGroup
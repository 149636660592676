import React from 'react'
import { CreateLayoutFields, FormikResetForm } from '../../../../types/types'

type ResetButtonProps = {
    resetForm: FormikResetForm<CreateLayoutFields>
}

const ResetButton: React.FC<ResetButtonProps> = React.memo(({ resetForm }) => {

    return (
        <button
            type="button"
            className="bf-btn bf-btn-gray bf-create__clear-btn"
            onClick={() => resetForm()}
        >
            Очистить
        </button>
    )
})

export default ResetButton
import React from 'react'
import './Radio.scss'

type RadioProps = {
    labelText: string
    value: string
    name: string
    id?: string
    defaultChecked?: boolean
    checked?: boolean
    classes?: string[]
    onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
}
const Radio: React.FC<RadioProps> = ({labelText, id, value, name, defaultChecked, checked, classes, onChange}) => {

    let radioClasses = ['custom-radio']

    if (classes && classes.length > 0) {
        radioClasses = radioClasses.concat(classes)
    }

    return (
        <label className={radioClasses.join(' ')}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                defaultChecked={defaultChecked}
                checked={checked}
                onChange={onChange}
            />
            <span className="custom-radio__input">{labelText}</span>
        </label>
    )
}

export default Radio
import React, { useEffect, useRef } from 'react'
import { createFormData, openNotification, scrollTopSmooth } from '../../../utils/other'
import FadeModal from '../FadeModal/FadeModal'
import UploadStatus from '../../UploadStatus/UploadStatus'
import './ModalLoadLayout.scss'
import { ModalLoadLayoutFields } from '../../../types/types'
import axios from 'axios'
import { freshAccessToken } from '../../../utils/cookie/cookie'
import { tokenType } from '../../../config'
import { InsertModalLoadLayout } from '../../../types/requestTypes'
import { orientationsCreate } from '../../../Data/createData'
import useUploadModal from '../../../utils/hooks/useUploadModal'
import { useFormik } from 'formik'
import { orientationsByPlacementId } from '../../../utils/formUtils'
import { ResetButton, SubmitButton } from './Controls'
import TextAreaFormik from '../../formikFields/TextAreaFormik/TextAreaFormik'
import SizePresetsFormik from '../../formikFields/SizePresetsFormik/SizePresetsFormik'
import SizesFormik from '../../formikFields/SizesFormik/SizesFormik'
import OrientationsFormik from '../../formikFields/OrientationsFormik/OrientationsFormik'
import PreviewInputFormik from '../../formikFields/PreviewInputFormik/PreviewInputFormik'
import MultipleFilesFormik from '../../formikFields/MultipleFilesFormik/MultipleFilesFormik'
import MultipleImgFilesFormik from '../../formikFields/MultipleImgFilesFormik/MultipleFilesFormik'

type ModalLoadLayoutProps = {
    title: string
    isShow: boolean
    uploadUrl: string
    placement_id: number
    layout_id?: number
    version_id?: number
    closeHandler: () => void
    afterSuccessUpload?: () => void
}

const orientations = orientationsCreate()

const ModalLoadLayout: React.FC<ModalLoadLayoutProps> = React.memo((props) => {

    const {
        title, isShow, uploadUrl,
        layout_id, version_id, placement_id,
        closeHandler, afterSuccessUpload,
    } = props

    const upload = useUploadModal()

    const dragRect = useRef<HTMLFormElement>(null)

    const validate = (values: ModalLoadLayoutFields) => {
        const errors = {} as { [key: string]: string }
        const isValidOrientation = ['horizontal', 'vertical', 'square'].includes(values.orientation)

        if (!values.original.length) errors.original = 'Загрузите один или несколько файлов'
        if (!values.preview) errors.preview = 'Загрузите превью'
        if (!isValidOrientation) errors.orientation = 'Укажите ориентацию'

        if (Object.keys(errors).length) {
            scrollTopSmooth('#bf-load-modal')
            openNotification('Ошибка', 'error', 'Заполните обязательные поля')
        }
        return errors
    }

    const submitHandler = (values: ModalLoadLayoutFields) => {
        const data = {
            description: values.description,
            preview: values.preview,
            original: values.original,
            prints: values.prints,
            orientation: values.orientation,
            other_previews: values.otherPreviews,
        } as InsertModalLoadLayout
        if (values.width) data.width = +values.width
        if (values.height) data.height = +values.height
        if (values.unit) data.unit = values.unit
        if (layout_id) data.layout_id = layout_id
        if (version_id) data.version_id = version_id

        console.log('created', data)
        const dataForm = createFormData(data)
        sendData(dataForm)
    }

    const {
        values, errors,
        setFieldError, setFieldValue, setValues,
        setErrors, resetForm, submitForm,

    } = useFormik<ModalLoadLayoutFields>({
        initialValues: {
            description: '',
            width: '',
            height: '',
            unit: '',
            orientation: '',
            preview: null,
            original: [],
            prints: [],
            otherPreviews: [],
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate,
        onSubmit: submitHandler
    })

    const initialSizes = () => {
        const orientation = orientationsByPlacementId(placement_id, orientations)
        setValues({
            ...values,
            description: '',
            width: '',
            height: '',
            unit: '',
            orientation: orientation[0]?.value || '',
            preview: null,
            original: [],
            prints: [],
            otherPreviews: [],
        })
        setErrors({
            ...errors,
            width: '',
            height: '',
            unit: '',
            orientation: '',
            original: '',
            otherPreviews: '',
        })
    }

    const closeModalHandler = () => {
        closeHandler()
        setTimeout(initialSizes, 300)
    }

    const resetUploadModal = () => {
        closeModalHandler()
        setTimeout(() => upload.reset(), 500)
    }

    const sendData = async (dataForm: FormData) => {
        try {
            upload.loading('Загрузка', 'Выполняется отправка файлов')
            const accessToken = await freshAccessToken()
            const option = {
                headers: { 'Authorization': `${tokenType} ${accessToken}` },
                onUploadProgress(e: any) {
                    upload.set.totalSize(e.total)
                    upload.set.uploadedSize(e.loaded)
                }
            }
            const res = await axios.post(uploadUrl, dataForm, option)
            console.log('CREATE MODAL RES', res)
            if (res.data.success) {
                upload.success('Готово', 'Файлы успешно отправлены')
                if (afterSuccessUpload) afterSuccessUpload()
            } else {
                upload.error('Ошибка', 'Что-то пошло не так')
            }
        } catch (e) {
            console.log(e)
            upload.error('Ошибка', e.message)
        }
        setTimeout(() => resetUploadModal(), 2000)
    }

    useEffect(() => {
        if (placement_id) initialSizes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placement_id])

    return (
        <FadeModal
            isShow={isShow}
            closeHandler={closeModalHandler}
            classes={['bf-load-modal']}
            timeout={400}
        >
            {
                upload.status === null
                    ? <div className="bf-container bf-load-modal__container">
                        <form
                            ref={dragRect}
                            className="bf-load-modal__body"
                            id="bf-load-modal"
                            onSubmit={e => e.preventDefault()}
                        >
                            <p className="bf-load-modal__title">{title}</p>
                            <div className="bf-load-modal__layout">
                                <div className="bf-load-modal__inputs-field">
                                    <TextAreaFormik
                                        title="Описание"
                                        value={values.description}
                                        placeholder="Введите описание макета"
                                        classes={['bf-edit-adaptive__description']}
                                        onChangeValue={val => setFieldValue('description', val)}
                                    />
                                    <SizePresetsFormik
                                        valueWidth={values.width}
                                        valueHeight={values.height}
                                        valueUnit={values.unit}
                                        placementId={placement_id}
                                        setFieldValue={setFieldValue}
                                    />
                                    <SizesFormik
                                        valueWidth={values.width}
                                        valueHeight={values.height}
                                        valueUnit={values.unit}
                                        setFieldValue={setFieldValue}
                                    />
                                    <OrientationsFormik
                                        value={values.orientation}
                                        placementId={placement_id}
                                        error={errors.orientation}
                                        wrapperClasses={['orientation-top-offset']}
                                        classes={['bf-size-radio']}
                                        onChangeValue={val => setFieldValue('orientation', val)}
                                        onChangeError={err => setFieldError('orientation', err)}
                                    />
                                </div>

                                <div className="bf-load-modal__files-field">
                                    <PreviewInputFormik
                                        dragRect={dragRect}
                                        value={values.preview}
                                        error={errors.preview}
                                        onChangeValue={val => setFieldValue('preview', val)}
                                        onChangeError={err => setFieldError('preview', err)}
                                    />
                                    <MultipleImgFilesFormik
                                        title="Дополнительные превью"
                                        value={values.otherPreviews}
                                        error={errors.otherPreviews}
                                        onChangeFiles={val => setFieldValue('otherPreviews', val)}
                                        onChangeError={err => setFieldError('otherPreviews', err)}
                                    />

                                    <MultipleFilesFormik
                                        title="Исходники"
                                        value={values.original}
                                        error={errors.original}
                                        onChangeFiles={val => setFieldValue('original', val)}
                                        onChangeError={err => setFieldError('original', err)}
                                    />
                                    <MultipleFilesFormik
                                        title="Итог на печать"
                                        value={values.prints}
                                        onChangeFiles={val => setFieldValue('prints', val)}
                                    />
                                </div>
                            </div>

                            <div className="bf-load-modal__controls">
                                <ResetButton resetForm={resetForm} />
                                <SubmitButton submitForm={submitForm} />
                            </div>
                        </form>
                    </div>
                    : <UploadStatus upload={upload} />
            }
        </FadeModal>
    )
})

export default ModalLoadLayout
import React from "react";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../store/reducers/rootReducer";
import {contentManage} from "./constants";

const ContentManage = () => {

    const menuKey = useSelector<IRootState>(state => state.app.menuKey) as string

    return (<>
        {React.createElement(contentManage[menuKey] || null)}
    </>);
}

export default ContentManage;